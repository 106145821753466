enum CLASS {
    page = "page",
    styleTheme = "styleTheme",
    component = "component",
    mcq = "mcq",
    category = "category",
    pageChallenge = "pageChallenge",
    stoppingPointStatus = "stoppingPointStatus",
    pageChallengeStatus = "pageChallengeStatus",
    codeChallenge = "codeChallenge",
    codeChallengeStatus = "codeChallengeStatus",
    codeExercise = "codeExercise",
    codeExerciseSubmission = "codeExerciseSubmission",
}

export {CLASS};