//React
import { useState, useEffect } from 'react';

//UI
import { Button, Spinner } from "flowbite-react";

//Services

//Logics

//Components

//Store
import { useAtom } from 'jotai';
import { mcqsInMemory } from 'atom';

//Classes
import {Mcq} from 'classes/mcq/Mcq';
import {StoppingPointStatus} from 'classes/course/StoppingPointStatus';
import {McqStoppingPoint} from 'classes/course/McqStoppingPoint';
import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import { CategoryStatus } from 'classes/general/CategoryStatus';

type Props = {
    //categoryId: string;
    //categoryStatus: CategoryStatus;
    level0CategoryId: string;
    level1CategoryId: string;
    level2CategoryId: string;
    questionsSubmittable: boolean;
    mcqStoppingPoint: McqStoppingPoint;
    stoppingPointStatus: StoppingPointStatus | undefined;
    submitStoppingPointStatus: Function;
}
class SubmittedAnswer {
    mcqId: string = "";
    answerGuid: string = "";
    isGraded: boolean = false;
    isCorrect: boolean = false;
}

const McqViewer = (props: Props) => {

    const { level0CategoryId, level1CategoryId, level2CategoryId, questionsSubmittable, stoppingPointStatus, submitStoppingPointStatus, mcqStoppingPoint } = props;
    const [mcqs, setMcqs] = useAtom(mcqsInMemory);
    const [filteredMcqsByCategory, setFilteredMcqsByCategory] = useState<Mcq[]>([]);
    const [filteredMcqs, setFilteredMcqs] = useState<Mcq[]>([]);
    const alphabet = "abcdefghijklmno";
    const [selectedMcqIndex, setSelectedMcqIndex] = useState(-1);

    const [challengeStatus, setChallengeStatus] = useState(questionsSubmittable?"waiting":"viewing");

    const [submittedAnswers, setSubmittedAnswers] = useState<SubmittedAnswer[]>([]);

    const [overViewCardSelected, setOverViewCardSelected] = useState(true);

    const [challengeCompletedSuccessfully, setChallengeCompletedSuccessfully] = useState(false);

    const [totalMcqsQuestionsInChallenge, setTotalMcqsQuestionsInChallenge] = useState(0);

    //let numberOfMcqsToDisplay = 3;
    //let passRate = 65;

    useEffect(() => {
        if (mcqs) {
            //let categoryId = categoryStatusLogic.findHighestCategoryId(categoryStatus);
            let filtered;
            if (level2CategoryId !== "None") {
                filtered = mcqs.filter(mcq => mcq.level2CategoryId === level2CategoryId);
            } else if (level1CategoryId !== "None") {
                filtered = mcqs.filter(mcq => mcq.level1CategoryId === level1CategoryId);
            }
            else if (level0CategoryId !== "None") {
                filtered = mcqs.filter(mcq => mcq.level0CategoryId === level0CategoryId);
            } else {
                filtered = mcqs;
            }
            // let level = categoryStatusLogic.findLevelFromCategoryStatus(categoryStatus);
            // if (level === 0) {
            //     filtered = mcqs.filter(mcq => mcq.level0CategoryId === categoryStatus.level0CategoryId);
            // } else if (level === 1) {
            //     filtered = mcqs.filter(mcq => mcq.level1CategoryId === categoryStatus.level1CategoryId);
            // } else if (level === 2) {
            //     filtered = mcqs.filter(mcq => mcq.level2CategoryId === categoryStatus.level2CategoryId);
            // } else {
            //     filtered = mcqs;
            // }
            
            //let filtered = mcqs.filter(mcq => mcq.categoryId === categoryId);
            //remove duplicates - this should not be necessary when loading system changed
            //filtered = filtered.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
            
            setFilteredMcqsByCategory(filtered);
            selectChallengeMcqs(filtered);
        }
    }, [mcqs]);

    useEffect(() => {
        if (stoppingPointStatus && stoppingPointStatus.dateCompleted) {
            setChallengeCompletedSuccessfully(true);
        }
    }, [stoppingPointStatus]);

    const selectChallengeMcqs = (filteredMcqsByCategory: Mcq[]) => {
        //randomly select mcqs according to numberOfMcqsToDisplay
        let tempMcqs = [...filteredMcqsByCategory];
        let numberOfMcqs = (tempMcqs.length > mcqStoppingPoint.numberOfQuestionsPerChallenge) ? mcqStoppingPoint.numberOfQuestionsPerChallenge : tempMcqs.length;
        setTotalMcqsQuestionsInChallenge(numberOfMcqs);
        let selectedMcqs: Mcq[] = [];
        for (let i = 0; i < numberOfMcqs; i++) {
            
            let randomIndex = Math.floor(Math.random() * tempMcqs.length);
            // console.log(tempMcqs);
            // console.log(randomIndex);
            selectedMcqs.push(tempMcqs[randomIndex]);
            tempMcqs.splice(randomIndex, 1);
        }
        setFilteredMcqs(selectedMcqs);
    }

    const selectAnswer = (mcqId: string, answerGuid: string) => {
        if (challengeStatus !== "running" || isAlreadyAnswered(mcqId)) {
            return;
        }

        let currentMcqId = filteredMcqs[selectedMcqIndex].id;
        let submittedAnswer = new SubmittedAnswer();
        submittedAnswer.mcqId = currentMcqId;
        submittedAnswer.answerGuid = answerGuid;

        let tempAnswers = [...submittedAnswers];
        //if answer already exists, replace it, otherwise add it
        let index = tempAnswers.findIndex(x => x.mcqId === currentMcqId);
        if (index === -1) {
            tempAnswers.push(submittedAnswer);
        } else {
            tempAnswers[index] = submittedAnswer;
        }
        setSubmittedAnswers(tempAnswers);
    }

    const gradeAnswers = () => {
        let tempSubmittedAnswers = [...submittedAnswers];
        filteredMcqs.forEach(mcq => {
            let correctAnswer = mcq.answers.find(x => x.isCorrect);
            let index = tempSubmittedAnswers.findIndex(x => x.mcqId === mcq.id);
            if (index === -1 || !correctAnswer) {
                console.error("No answer selected or found for this question. Cannot grade!")
                return;
            }
            if (tempSubmittedAnswers[index].answerGuid === correctAnswer.guidRef) {
                tempSubmittedAnswers[index].isCorrect = true;
                tempSubmittedAnswers[index].isGraded = true;
            } else {
                tempSubmittedAnswers[index].isCorrect = false;
                tempSubmittedAnswers[index].isGraded = true;
            }
        });
        setSubmittedAnswers(tempSubmittedAnswers);
        //checkForChallengeCompletion(tempSubmittedAnswers);
        completeChallenge(tempSubmittedAnswers);
    }

    const completeChallenge = (submittedAnswers: SubmittedAnswer[]) => {
        //Check if pass rate is met
        let numberOfCorrectAnswers = submittedAnswers.filter(x => x.isCorrect).length;
        let percentageCorrect = (numberOfCorrectAnswers / totalMcqsQuestionsInChallenge) * 100;
        if (percentageCorrect >= mcqStoppingPoint.passGrade) {
            console.log("Challenge complete!");
            if (!challengeCompletedSuccessfully) {
                setChallengeCompletedSuccessfully(true);
                submitStoppingPointStatus();
            }

        } else {
            console.log("Challenge failed!");
        }
        setChallengeStatus("completed");
    }

    const isAlreadyAnswered = (mcqId: string) => {
        let index = submittedAnswers.findIndex(x => x.mcqId === mcqId);
        if (index !== -1 && submittedAnswers[index].isGraded) {
            return true;
        } else {
            return false;
        }
    }

    const resetMcqChallenge = () => {
        setSubmittedAnswers([]);
        selectChallengeMcqs(filteredMcqsByCategory);
        setChallengeStatus("running");
    }

    //======================================================== Render and Render helper functions

    const renderChosenAnswerTick = (guidRef: string) => {
        let currentMcqId = filteredMcqs[selectedMcqIndex].id;
        let index = submittedAnswers.findIndex(x => x.mcqId === currentMcqId);
        //If user has chosen an answer for this mcq
        if (index !== -1 && guidRef === submittedAnswers[index].answerGuid) {
            //return yellow if ungraded, green if correct, red if incorrect
            if (submittedAnswers[index].isGraded) {
                if (submittedAnswers[index].isCorrect) {
                    return "bg-green-500";
                } else {
                    return "bg-red-500";
                }
            } else {
                return "bg-yellow-500";
            }

        } else {
            return "";
        }
    }

    const allAnswersSubmitted = () => {
        if (challengeStatus !== "running") {
            return true;
        }
        let allAnswered = true;
        filteredMcqs.forEach(mcq => {
            let index = submittedAnswers.findIndex(x => x.mcqId === mcq.id);
            if (index === -1) {
                allAnswered = false;
            }
        });
        return allAnswered;
    }

    const renderOverviewCard = () => {

        if (challengeStatus === "waiting") {
            return (
                <div>
                    <p>Overview</p>
                    <p>Pass rate: {mcqStoppingPoint.passGrade}%</p>
                    <p>Number of questions: {totalMcqsQuestionsInChallenge}</p>
                    {/* <p>Status: {challengeStatus} </p> */}
                    <Button onClick={() => { setChallengeStatus("running") }}>Start!</Button>
                </div>
            )
        }
        else if (challengeStatus === "completed") {
            let correctAnswers = submittedAnswers.filter(x => x.isCorrect).length;
            let incorrectAnswers = submittedAnswers.filter(x => !x.isCorrect).length;
            let totalAnswers = correctAnswers + incorrectAnswers;

            return (
                <div>
                    <p>Challenge complete!</p>
                    <p>{correctAnswers + " / " + totalAnswers + " = " + (Math.floor((correctAnswers / totalAnswers) * 100)) + "%"}</p>
                    {challengeCompletedSuccessfully && <p>Challenge completed successfully!</p>}
                    {!challengeCompletedSuccessfully && <p>Challenge failed!</p>}
                    <Button onClick={() => resetMcqChallenge()}>Retry</Button>
                </div>
            )
        }
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedMcqIndex === index) ? " border-black" : " border-gray-300";
        let thisAnswer = submittedAnswers.find(x => x.mcqId === filteredMcqs[index].id);
        if (!thisAnswer) {
            classString += " bg-gray-100";
        }
        else if (thisAnswer.isGraded) {
            if (thisAnswer.isCorrect) {
                classString += " bg-green-200";
            } else {
                classString += " bg-red-200";
            }
        } else {
            classString += " bg-yellow-200";
        }
        return classString;
    }

    //======================================================== Render

    return (
        <div className="grid grid-cols-1 place-items-center">

            <div className="flex gap-2 flex-wrap items-center">
                {challengeStatus !== "running" && questionsSubmittable && <div className={"border-2 select-none text-xs p-3 m-3 w-20 h-16 " + (overViewCardSelected ? " bg-red-200" : " border-gray-300")}
                    onClick={() => { setSelectedMcqIndex(-1); setOverViewCardSelected(true) }}
                >Overview
                </div>}
                {challengeStatus !== "waiting" && filteredMcqs && filteredMcqs.map((mcq, index) => {
                    return (
                        <div key={"mcq-" + index} className={getSelectionCardClassString(index)}
                            onClick={() => { setSelectedMcqIndex(index); setOverViewCardSelected(false) }}
                        >
                            {"# " + (index + 1)}
                        </div>
                    )
                }
                )}
                {challengeStatus === "running" && questionsSubmittable &&
                    <Button
                        disabled={!allAnswersSubmitted()}
                        className="h-12"
                        onClick={() => gradeAnswers()}
                    >Submit Answers</Button>}
            </div>

            {challengeStatus !== "waiting" && filteredMcqs && filteredMcqs[selectedMcqIndex] &&
                <div className="grid grid-cols-1 place-items-left">
                    <p className="text-sm md:text-2xl mb-4">{filteredMcqs[selectedMcqIndex].questionText}</p>
                    {filteredMcqs[selectedMcqIndex].answers.map((answer, index) => {
                        return (
                            <div key={answer.guidRef} className="flex">
                                <div
                                    className={"cursor-pointer mr-4 mb-4 size-8 border-2 border-black " + renderChosenAnswerTick(answer.guidRef)}
                                    onClick={() => selectAnswer(filteredMcqs[selectedMcqIndex].id, answer.guidRef)}
                                ></div>
                                <div className="text-xs md:text-xl">{answer.answerText}</div>
                            </div>
                        )
                    })}
                </div>
            }

            {overViewCardSelected && questionsSubmittable &&
                renderOverviewCard()
            }

        </div>
    );
}

export default McqViewer;