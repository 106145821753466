import createGUID from "utility/helper-functions/createGUID";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

class CodeExercise {
    id: string = "";
    guidRef: string = createGUID(8);
    name: string = "";
    difficulty: number = 1;
    instructionText: string = "";
    sourceCode: string = "";
    possibleAnswer: string = "";
    explanation: string = "";
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";

    status: string = "new"; //client side only
}

const getCodeExerciseProperties = (thisObject: CodeExercise) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Code challenge name", value: thisObject? thisObject.name : "new"});
    return editableProperties;
}

export {CodeExercise, getCodeExerciseProperties};