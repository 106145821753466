//React
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

//UI
import { Container, Row } from 'ui_components/helper/HelperComponents';
import { Navbar, Dropdown, Button } from "flowbite-react";

//Services
import { toast } from 'react-toastify';
import config from "config";
import apiService from "services/apiService";
import useS3Fetch from "services/useS3Fetch";

//Logics
import S3RouteLogic from 'logic/io/S3RouteLogic';
import crudLogic from 'logic/general/crudLogic';

//Components

//Classes
import { Page } from "classes/gridslate/Page";
import { NavbarClass } from "classes/navbar/Navbar";
import { NavbarElement } from "classes/navbar/NavbarElement";
import { NavbarChild } from "classes/navbar/NavbarChild";

import websiteData from "assets/db/website.json";

// type Props = {
//     pages: Page[];
// }

class SelectedNavbarItem {
    topLevelIndex: number;
    childIndex: number;

    constructor(topLevelIndex: number, childIndex: number) {
        this.topLevelIndex = topLevelIndex;
        this.childIndex = childIndex;
    }
}

const NavbarConstructor = () => {
    
    const [selectedMenuItem, setSelectedMenuItem] = useState<SelectedNavbarItem | null>(null);
    const [pages, setPages] = useState<Page[]>([]);
    
    const { payload: navbarS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch(S3RouteLogic.GetNavbarRoute());

    useEffect(() => {

        const fetchPages = async () => {
            let response = await apiService.get(config.gridslateUrl + '/cms/GetPages/' + websiteData.id);
            if (response.success) {
                setPages(response.payload);
                //setIsFetchingPages(false);
            } else {
                //alert('Error loading pages');
                //setIsFetchingPages(false);
            }
        }

        fetchPages();

    }, [websiteData]);

    useEffect(() => {
        if (navbarS3 !== null) {
            let tempNavbar = navbarS3 as unknown as NavbarClass;
            setNavbar(tempNavbar);
            setNavbarGrid(tempNavbar.elements as NavbarElement[]);
        } else { setNavbarGrid([]) }

    }, [isPendingNavbar]);

    const [navbar, setNavbar] = useState<NavbarClass>(new NavbarClass('', []));
    const [navbarGrid, setNavbarGrid] = useState<NavbarElement[]>([]);


    //Add a new link as per the example in the JSON in the variabled navbarGrid

    const editNavBar = (target: string, value: string = "", childIndex: number = 0) => {
        let tempNavBarGrid = [...navbarGrid];

        if (target === "addNew") {
            let newElement = new NavbarElement('none', '', 'New Link', 'NewLink', []);
            tempNavBarGrid.push(newElement);
            setNavbarGrid(tempNavBarGrid);
        }

        if (selectedMenuItem === null) {
            console.log("No menu item selected");
            return;
        }

        if (target === "setpageIdTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].pageId = value;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].pageRef = pages.find((page) => page.id === value)?.pageRef || '';
        }
        if (target === "setpageIdChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].pageId = value;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].pageRef = pages.find((page) => page.id === value)?.pageRef || '';
        }


        if (target === "titleTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].title = value;
        }
        if (target === "routeTop") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].route = value;
        }

        if (target === "titleChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].title = value;
        }
        if (target === "routeChild") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children[childIndex].route = value;
        }

        if (target === "addDropDownLink") {
            let newChild = new NavbarChild('none', '', 'New Link', 'NewLink');
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.push(newChild);
        }
        if (target === "moveLinkUp") {
            //move the link up in the array using childIndex
            //only if the childIndex is not 0
            if (childIndex === 0) return;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex - 1, 0, tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex, 1)[0]);

        }
        if (target === "moveLinkDown") {
            //move the link down in the array using childIndex, only if the childIndex is not the last index
            if (childIndex === tempNavBarGrid[selectedMenuItem.topLevelIndex].children.length - 1) return;
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex + 1, 0, tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(childIndex, 1)[0]);
        }
        if (target === "deleteDropdownLink") {
            tempNavBarGrid[selectedMenuItem.topLevelIndex].children.splice(selectedMenuItem.childIndex, 1);
        }
        if (target === "deleteLink") {
            tempNavBarGrid.splice(selectedMenuItem.topLevelIndex, 1);
            setSelectedMenuItem(null);
        }
        setNavbarGrid(tempNavBarGrid);
    }

    const saveNavbar = async () => {
        //Send the navbarGrid to the server
        let response;
        let tempNavbar = {navbar: navbar, elements: navbarGrid}
        if (navbar.id === "") {
            response = await crudLogic.create("navbar", tempNavbar);
        } else {
            response = await crudLogic.update("navbar", tempNavbar);
        }
        if (response.success) {
            toast.success("Navbar saved successfully!");
        } else {
            toast.error("Navbar saving error!");
        }
        // apiService.post(config.gridslateUrl + '/cms/CreateUpdateNavbar', navbarGrid)
        //     .then((data) => {
        //         if (data.success) {         
        //             toast.success("Navbar saved successfully!");
        //         } else {
        //             toast.error("Error saving navbar");    
        //         }

        //     })
    }

    return (
        <Container>
            <Row>
                <Button onClick={() => saveNavbar()}>Save</Button>
            </Row>
            <Row>
                <div className='container mx-auto'>
                    <Navbar fluid rounded className='bg-col-t1 border-b-4 border-col-s2'>
                        <Navbar.Brand >
                            {/* <img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /> */}
                            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                                {config.siteTitle}
                            </span>
                        </Navbar.Brand>

                        <Navbar.Collapse>

                            <Link to={""}><span className='text-col-p2 md:text-xl'>Home</span></Link>

                            {navbarGrid && navbarGrid.map((item, index1) => (
                                <React.Fragment key={"navdiv" + index1}>
                                    {item.pageRef !== "dropdown" &&
                                        <Navbar.Link
                                            className='text-col-p2 text-xl'
                                            onClick={() => setSelectedMenuItem(new SelectedNavbarItem(index1, 0))}
                                        >{item.title}</Navbar.Link>}
                                    {item.pageRef === "dropdown" &&
                                        <Dropdown
                                            label={<span className='text-col-p2 md:text-xl'>{item.title}</span>}
                                            arrowIcon={true}
                                            inline
                                            onClick={() => setSelectedMenuItem(new SelectedNavbarItem(index1, 0))}
                                        >
                                            {item.children.map((child, index2) => (
                                                <Dropdown.Item key={"navbar" + index1 + "-" + index2}>{child.title}</Dropdown.Item>
                                            ))
                                            }
                                        </Dropdown>
                                    }

                                </React.Fragment>
                            ))}

                        </Navbar.Collapse>
                        <div>
                            <Link to={""} >Log in</Link>
                        </div>
                    </Navbar>
                </div>

            </Row>
            <Button onClick={() => editNavBar("addNew")}>Add New</Button>
            <Row>
                {selectedMenuItem && navbarGrid[selectedMenuItem.topLevelIndex] &&
                    <div>
                        <div>Title</div>
                        <input type="text" value={navbarGrid[selectedMenuItem.topLevelIndex].title} onChange={(e) => editNavBar("titleTop", e.target.value)}></input>
                        <input type="text" value={navbarGrid[selectedMenuItem.topLevelIndex].route} onChange={(e) => editNavBar("routeTop", e.target.value)}></input>

                        <select
                            value={navbarGrid[selectedMenuItem.topLevelIndex].pageId}
                            onChange={(e) => editNavBar("setpageIdTop", e.target.value)}
                        >
                            <option value='none'>Select link or set as dropdown</option>
                            <option value='dropdown'>Is dropdown</option>
                            {pages && pages.map((page, index) => (
                                <option key={"loadpagedropdown" + index} value={page.id}>{page.title}</option>
                            ))}
                        </select>
                        {navbarGrid[selectedMenuItem.topLevelIndex].pageId === "dropdown" && <div>

                            <Button onClick={() => editNavBar("addDropDownLink")}>Add drop down link</Button>

                            {navbarGrid[selectedMenuItem.topLevelIndex].children.map((child, index) => (
                                <div key={"navbardropdowns" + index}>
                                    <input type="text" value={child.title} onChange={(e) => editNavBar("titleChild", e.target.value, index)}></input>
                                    <input type="text" value={child.route} onChange={(e) => editNavBar("routeChild", e.target.value, index)}></input>
                                    <select
                                        value={navbarGrid[selectedMenuItem.topLevelIndex].children[index].pageId}
                                        onChange={(e) => editNavBar("setpageIdChild", e.target.value, index)}
                                    >
                                        <option value='none'>None</option>
                                        {pages && pages.map((page, index) => (
                                            <option key={"childpagedropdown" + index} value={page.id}>{page.title}</option>
                                        ))}
                                    </select>
                                    <button onClick={() => editNavBar("moveLinkUp", "", index)}>Move Up</button>
                                    <button onClick={() => editNavBar("moveLinkDown", "", index)}>Move Down</button>
                                    <button onClick={() => editNavBar("deleteDropdownLink", "", index)}>Delete</button>
                                </div>
                            ))}
                        </div>}

                        <Row>
                            <Button onClick={() => editNavBar("deleteLink", "", selectedMenuItem.topLevelIndex)}>Delete Toplevel Link</Button>
                        </Row>

                    </div>}
            </Row>

            {JSON.stringify(navbarGrid)}
        </Container>
    )


}

export default NavbarConstructor