//React

//UI

//Services

//Logics
import crudLogic from "logic/general/crudLogic";
import parseLogic from "logic/parse/parseLogic";

//Components

//Store

//Classes
import {FilterModel} from "classes/crud/FilterModel";
import {STATUS } from "classes/enums/status";
import { Component } from "classes/components/Component";
import { Page } from "classes/gridslate/Page";

//======================================================================== Classes
class CreateUpdatePageRequest {
    Page: Page;
    NewComponents: Component[];
    UpdatedComponents: Component[];
    DeletedComponentIds: string[];

    constructor(Page: Page, NewComponents: Component[], UpdatedComponents: Component[], DeletedComponentIds: string[]) {
        this.Page = Page;
        this.NewComponents = NewComponents;
        this.UpdatedComponents = UpdatedComponents;
        this.DeletedComponentIds = DeletedComponentIds;
    }
}

//======================================================================== Input/Output Functions

const getAll = async (websiteId: string) => {
    let response = await crudLogic.get("page", new FilterModel([["ParentId", websiteId]]));
    return response;
}

//======================================================================== Helper/parse Functions

const parseUpdateResponseModel = (components: Component[], newComponents : Component[], updatedComponents : Component[], deletedComponentIds: string[]) => {
    let allComponents = [...components];
    //For each component in responseModel.NewComponents, find component by componentRef and set id and status
    newComponents.forEach((component: Component) => {
        let thisComponent = allComponents.find((c: Component) => c.componentRef === component.componentRef);
        if (thisComponent) {
            component = parseLogic.unstringifyDataProperty(component);
            component.id = component.id;
            component.status = STATUS.unchanged;
        }
    });
    //For each component in responseModel.UpdateComponents, find component by componentRef and set status
    updatedComponents.forEach((component: Component) => {
        let thisComponent = allComponents.find((c: Component) => c.componentRef === component.componentRef);
        if (thisComponent) {
            component = parseLogic.unstringifyDataProperty(component);
            component.status = STATUS.unchanged;
        }
    });
    //For each component in responseModel.DeleteComponents, find component by componentRef and remove it
    deletedComponentIds.forEach((id: string) => {
        let index = allComponents.findIndex((c: Component) => c.id === id);
        if (index > -1) {
            newComponents.splice(index, 1);
        }
    });
    return allComponents;
}

const createUpdatePageRequest = (page: Page, components: Component[]) => {
    let newComponents = [] as Component[];
    let updatedComponents = [] as Component[];
    let deletedComponentIds = [] as string[];
    //Create new componentDTOs
    components.forEach((component: Component) => {
        if (component.status === STATUS.new) {
            newComponents.push(parseLogic.stringifyDataProperty(component));
        } else if (component.status === STATUS.updated) {
            updatedComponents.push(parseLogic.stringifyDataProperty(component));
        } else if (component.status === STATUS.deleted) {
            deletedComponentIds.push(component.id);
        }
    });
    return new CreateUpdatePageRequest(page, newComponents, updatedComponents, deletedComponentIds);
}

export default { 
    getAll,
    parseUpdateResponseModel,
    createUpdatePageRequest
};