import {atom} from 'jotai';
import { User } from 'classes/accounts/User';
import {StyleTheme} from 'classes/style/StyleTheme';
//import {CategoryStatus} from 'classes/general/CategoryStatus';
import {Mcq} from 'classes/mcq/Mcq';

export const userAtom = atom(new User());

export const themesAtom = atom([] as StyleTheme[]); 

//export const categoryStatusAtom = atom(new CategoryStatus());

export const mcqsInMemory = atom([] as Mcq[]); 