//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Tabs, TabsRef } from 'flowbite-react';
import { Row, CheckboxAndLabel } from 'ui_components/helper/HelperComponents';

//Services
import errorService from 'services/errorService';
import { toast } from 'react-toastify';

//Logics
import pageLogic from 'logic/parse/pageLogic';
import pageSaveLoadLogic from 'logic/io/pageSaveLoadLogic';
import parseLogic from 'logic/parse/parseLogic';

//Components
import NavbarConstructor from "app_gridslate/constructor/NavbarConstructor";
import ThemeConstructor from './ThemeConstructor';
import GenericSaveLoadPanel from './panels/GenericSaveLoadPanel';
import GridConstructor from './GridConstructor';

//Store
import { themesAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { Component } from 'classes/components/Component';
import { Page } from 'classes/gridslate/Page';
import pageProperties from "classes/gridslate/pageProperties";
import { GridConstructorInterface } from 'classes/interfaces/GridConstructorInterface';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';
import { CRUD_ACTION } from 'classes/enums/crud-action';

import websiteData from "assets/db/website.json";

//shift + Mousewheel increase fontsize and image size
const PageConstructor: React.FC = () => {

    const [pages, setPages] = useState<Page[]>([]);
    const [loadedPageIndex, setLoadedPageIndex] = useState<number>(-1);
    const [gridArray, setGridArray] = useState<string[][]>([]);
    const [components, setComponents] = useState<Component[]>([]);

    const [website, setWebsite] = useState(websiteData);
    const [activeTab, setActiveTab] = useState(0);

    const [currentThemeIndex, setCurrentThemeIndex] = useState<number>(0);

    const [allThemes, setAllThemes] = useAtom(themesAtom);

    const mainTabsRef = useRef<TabsRef>(null);

    const gridConstructorRef = useRef<GridConstructorInterface>(null);

    useEffect(() => {
        const fetchPages = async () => {
            let response = await pageLogic.getAll(websiteData.id);
            if (response.success) {
                setPages(response.payload);
            } else {
                errorService.handleError(response);
            }
        }

        fetchPages();
    }, [websiteData])

    //TOOLPANE RENDERERS ========================================

    //TEST HANDLERS ========================================

    const crudActions = (action: CRUD_ACTION, value: any, target?: string) => {
        if (action === CRUD_ACTION.getUpdatedObject) {
            //get current page and components
            if (gridConstructorRef.current) {
                let updatedComponents = gridConstructorRef.current.getComponents();
                let updatedGridArray = gridConstructorRef.current.getGridArray();
                let updatedPage = pages[loadedPageIndex];
                updatedPage.gridArray = updatedGridArray;
                return { "page": updatedPage, "components": updatedComponents };
            }
        }
        else if (action === CRUD_ACTION.returnNewObject) {
            let newPage = new Page(website.id, 'New Page');
            newPage.styleThemeId = getStyleThemeId();
            console.log(newPage.styleThemeId);
            return newPage;
        }
        else if (action === CRUD_ACTION.appendNewObject) {
            //Expect value as a page object
            let createdPage = value as Page;
            let newtempPages = [...pages];
            newtempPages.push(createdPage);
            setPages(newtempPages);
            setLoadedPageIndex(newtempPages.length - 1);

            //Reset grid and components
            setGridArray(createdPage.gridArray);
            setComponents([]);
        }
        else if (action === CRUD_ACTION.loadObject) {
            //expecting page and components

            let loadedIndex = pages.findIndex((page) => page.id === value.page.id);
            //If id not found because it is a new page
            if (loadedIndex === -1) {
                loadedIndex = pages.findIndex((page) => page.id === "");
                if (loadedIndex === -1) {
                    toast.error("ERROR: Page not found");
                    return;
                }
            }

            setGridArray(value.page.gridArray);
            setComponents(value.components);
            setLoadedPageIndex(loadedIndex);
            let tempPages = [...pages];
            tempPages[loadedIndex] = value.page;
            setPages(tempPages);
        }
        else if (action === CRUD_ACTION.updateObject) {
            //Expect value as a page object
            //Update page in pages
            let tempPages = [...pages];
            let index = tempPages.findIndex((page) => page.id === value.id);
            if (index !== -1) {
                tempPages[index] = value;
            }
            setPages(tempPages);
        }
        else if (action === CRUD_ACTION.updateObjectProperties) {
            //Expects value as EditablePropertyMetadata[]
            let editableProperties = value as EditablePropertyMetadata[];
            let tempPages = [...pages];
            let updatedPage = parseLogic.parseObjectUpdate(tempPages[loadedPageIndex], editableProperties);
            tempPages[loadedPageIndex] = updatedPage;
            setPages(tempPages);
        }
        else if (action === CRUD_ACTION.deleteObject) {
            let tempPages = [...pages];
            let index = tempPages.findIndex((page) => page.id === value);
            if (index !== -1) {
                tempPages.splice(index, 1);
            }
            setPages(tempPages);
        }
    }

    //RENDER ========================================

    const getStyleThemeId = () => {
        let themeId = "default";
        if (currentThemeIndex !== -1 && allThemes.length > 1 && allThemes[currentThemeIndex]) {
            themeId = allThemes[currentThemeIndex].id;
        }
        return themeId;
    }

    const getThemeFromThemeId = (themeId: string) => {
        let theme = allThemes.find((theme) => theme.id === themeId);
        if (theme) {
            return theme;
        } else {
            //return default theme
            return allThemes[0];
        }
    }

    const editPage = (target: string, value: any) => {

        let tempPages = [...pages];
        if (target === 'title') {
            tempPages[loadedPageIndex].title = value;
        }
        if (target === 'styleThemeId') {
            tempPages[loadedPageIndex].styleThemeId = value;
        }
        if (target === 'isPublic') {
            tempPages[loadedPageIndex].isPublic = value;
        }

        setPages(tempPages);
    }

    return (
        <div className='container mx-auto'>
            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="Create">
                    <Row>
                        <GenericSaveLoadPanel
                            objectType='page'
                            loadedObjectIndex={loadedPageIndex}
                            objects={pages}
                            crudActions={crudActions}
                            saveLoadData={{ "page": pages[loadedPageIndex], "components": components }}
                            saveLoadLogic={pageSaveLoadLogic}
                            getEditableProperties={() => { return pageProperties(pages[loadedPageIndex] ? pages[loadedPageIndex] : new Page("", ""), allThemes) }}
                        />
                    </Row>
                    <Row>
                        <div>{JSON.stringify(pages[loadedPageIndex])}</div>
                        {pages[loadedPageIndex] && <div>
                            <select value={pages[loadedPageIndex].styleThemeId} onChange={(e) =>
                                editPage('styleThemeId', e.target.value)}>
                                {allThemes.map((theme, index) => {
                                    return (
                                        <option key={theme.id} value={theme.id}>{theme.name}</option>
                                    )
                                })}
                            </select>
                            <CheckboxAndLabel label='Is Public?' checked={pages[loadedPageIndex].isPublic} onChange={() => editPage('isPublic', !pages[loadedPageIndex].isPublic)} />
                        </div>}
                    </Row>
                    <Row>
                        <GridConstructor
                            ref={gridConstructorRef}
                            parentComponents={components}
                            parentGridArray={gridArray}
                            theme={getThemeFromThemeId(pages[loadedPageIndex] ? pages[loadedPageIndex].styleThemeId : "default")}
                        />
                    </Row>
                </Tabs.Item>
                <Tabs.Item title="Navbar">
                    <NavbarConstructor />
                </Tabs.Item>
                <Tabs.Item title="Components">
                    <div>{JSON.stringify(gridArray)}</div>
                </Tabs.Item>

                {/* <Tabs.Item title="Import">

                    <Dropzone onDrop={(acceptedFiles) => setFileToUpload(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} className="flex w-full items-center justify-center h-64 cursor-pointer">
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    {fileToUpload && <p>{fileToUpload[0].name}</p>}

                    <Button onClick={() => handleDocXUpload(fileToUpload)}>Upload</Button>
                </Tabs.Item> */}
                <Tabs.Item title="Theme Constructor">
                    <ThemeConstructor websiteId={websiteData.id} />
                </Tabs.Item>
                <Tabs.Item title="View Mode">

                </Tabs.Item>
            </Tabs>

            {/* ===================================================================== Constructor ============================== */}



        </div>
    )

}

export default PageConstructor;