//React
import React from 'react';
import { useState, useEffect } from 'react';

//UI
import { Container, Row } from 'ui_components/helper/HelperComponents';

//Services
import { useWindowDimensions } from "utility/hooks/useWindowDimensions";
import errorService from 'services/errorService';

//Logics
import styleLogic from "logic/style/styleLogic";
import crudLogic from "logic/general/crudLogic";
import parseLogic from "logic/parse/parseLogic";
import parseCodeEditor from 'logic/parse/parseCodeEditor';
import genericSaveLoadLogic from 'logic/io/genericSaveLoadLogic';

//Components
import StyleOverviewPanel from './panels/StyleOverviewPanel';
import GenericSaveLoadPanel from './panels/GenericSaveLoadPanel';
import Editor from 'react-simple-code-editor';

//Store
import { themesAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import { SelectedComponentData } from 'classes/components/SelectedComponentData';
import {ThemeColor} from 'classes/style/ThemeColor';
import {ComponentStylePair} from 'classes/style/ComponentStylePair';
import {FilterModel} from 'classes/crud/FilterModel';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';
import styleThemeProperties from 'classes/style/styleThemeProperties';
import {StyleTheme} from 'classes/style/StyleTheme';
import {CRUD_ACTION} from 'classes/enums/crud-action';
import {EditablePropertyMetadata} from 'classes/gridslate/EditablePropertyMetadata';

import websiteData from "assets/db/website.json";

type Props = {
    websiteId: string;
}

const ThemeConstructor = (props: Props) => {
    const websiteId = props.websiteId;

    const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();
    const [selectedComponentPair, setSelectedComponentPair] = useState(-1);

    const [loadedThemeIndex, setLoadedThemeIndex] = useState(-1);
    const [allThemes, setAllThemes] = useAtom(themesAtom);
    const [isFetching, setIsFetching] = useState<boolean>(true);


    useEffect(() => {
        //Create default theme
        let defaultTheme = styleLogic.returnDefaultStyleTheme('Default', 'page', websiteData.id);
        defaultTheme.id = 'default';

        const fetchObjects = async () => {

            let response = await crudLogic.get("styletheme", new FilterModel([["WebsiteId", websiteData.id]]));
            if (response.success) {
                let parsedObjects = parseLogic.parseGenericFetchedPayload(response.payload);

                if (parsedObjects && parsedObjects.length > 0) {
                    //add default theme as the first theme
                    parsedObjects.unshift(defaultTheme);
                } else {
                    parsedObjects = [defaultTheme];
                }
                setAllThemes(parsedObjects);
            } else {
                errorService.handleError(response);
            }
        }

        if (isFetching) {
            console.log('Fetching...');
            fetchObjects();
        }

    }, [websiteData]);

    const returnDefaultContentForComponentType = (type: COMPONENT_TYPE, subtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) => {
        switch (type) {
            case COMPONENT_TYPE.title:
                return 'Title Text';
            case COMPONENT_TYPE.heading:
                if (subtype === COMPONENT_SUBTYPE.type1) return 'Heading Level 1';
                if (subtype === COMPONENT_SUBTYPE.type2) return 'Heading Level 2';
                if (subtype === COMPONENT_SUBTYPE.type3) return 'Heading Level 3';
                if (subtype === COMPONENT_SUBTYPE.type4) return 'Heading Level 4';
                if (subtype === COMPONENT_SUBTYPE.type5) return 'Heading Level 5';
                return 'Heading Level 1';
            case COMPONENT_TYPE.text:
                return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
            case COMPONENT_TYPE.image:
                return 'https://via.placeholder.com/150';
            case COMPONENT_TYPE.list:
                return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit \nSed do eiusmod tempor incididunt ut labore et dolore magna aliqua. \nUt enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. \nDuis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
            case COMPONENT_TYPE.codeblock:
                return 'const x = 5; \nconst y = 10; \nconst z = x + y; \nconsole.log(z);';
            default:
                return 'Default Content';
        }
    }

    const setComponentStyle = (componentRef: string, style: {}) => {
      
        if (selectedComponentPair === -1) {
            return;
        }

        let allThemesCopy = [...allThemes];
        allThemesCopy[loadedThemeIndex].data.componentPairs[selectedComponentPair].styleSetting = { ...style };
        setAllThemes(allThemesCopy);
    }

    const renderComponent = (pair: ComponentStylePair) => {

        let style = styleLogic.parseStyle(pair.styleSetting, viewBreakpoint, allThemes[loadedThemeIndex].data.themeColors);
        switch (pair.type) {
            case COMPONENT_TYPE.title:
                return <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type)}</div>
            case COMPONENT_TYPE.heading:
                return <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type, pair.subtype)}</div>
            case COMPONENT_TYPE.text:
                return <React.Fragment>
                    <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type)}</div>
                    <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type)}</div>
                    <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type)}</div>
                </React.Fragment>

            case COMPONENT_TYPE.image:
                return <div className='flex justify-center items-center'>
                    <img id={pair.type} src={returnDefaultContentForComponentType(pair.type)} style={style} alt='placeholder' />
                </div>;
            case COMPONENT_TYPE.list:
                if (pair.subtype === COMPONENT_SUBTYPE.ordered) {
                    return <div><ol id={pair.type} className="list-decimal list-inside" style={style}>{returnDefaultContentForComponentType(pair.type).split('\n').map((item, index) => (
                        <li key={'list_' + index}>{item}</li>
                    ))}</ol></div>;
                } else {
                    return <div><ul id={pair.type} className="list-disc list-inside" style={style}>{returnDefaultContentForComponentType(pair.type).split('\n').map((item, index) => (
                        <li key={'list_' + index}>{item}</li>
                    ))}</ul></div>;
                }
            case COMPONENT_TYPE.codeblock:
                return <Editor
                    value={returnDefaultContentForComponentType(pair.type)}
                    padding={10}
                    readOnly
                    onValueChange={() => { }}
                    className="code-editor"
                    highlight={(sourceCode) => parseCodeEditor.parseCodeLines(sourceCode)}
                    textareaId="codeArea"
                    style={style}
                />
            default:
                return <div id={pair.type} style={style}>{returnDefaultContentForComponentType(pair.type)}</div>;
        }
    }

    const crudActions = (action: CRUD_ACTION, value: any, target?: string) => {
        if (action === CRUD_ACTION.appendNewObject) {
            //expect value as new style theme object
            var newThemes = [...allThemes, value];
            setLoadedThemeIndex(newThemes.length - 1);
            setAllThemes(newThemes);
        }
        if (action === CRUD_ACTION.getUpdatedObject) {
            return allThemes[loadedThemeIndex];
        }
        else if (action === CRUD_ACTION.returnNewObject) {
            let newTheme = styleLogic.returnDefaultStyleTheme('New Theme', 'page', websiteData.id);
            return newTheme;
        }
        else if (action === CRUD_ACTION.loadObject) {
            //value is actually id of object here, maybe implement isSimpleLoading again, because new theme won't have an id
            console.log(value);
            let index = allThemes.findIndex((theme) => theme.id === value);
            setLoadedThemeIndex(index);
        }
        else if (action === CRUD_ACTION.updateObject) {
            //value as StyleTheme object
            //let newTheme = { ...allThemes[loadedThemeIndex] };
            let newTheme = value as StyleTheme;
            var tempAllThemes = [...allThemes];
            tempAllThemes[loadedThemeIndex] = newTheme;
            setAllThemes(tempAllThemes);
        }
        else if (action === CRUD_ACTION.updateObjectProperties) {
            let editableProperties = value as EditablePropertyMetadata[];
            let newThemes = [...allThemes];
            let newTheme = { ...newThemes[loadedThemeIndex] };
            newTheme = parseLogic.parseObjectUpdate(newTheme, editableProperties);
            newThemes[loadedThemeIndex] = newTheme;
            setAllThemes(newThemes);
        }

        else if (action === CRUD_ACTION.deleteObject) {
            //value is actually the object here
            //But SHOULD always be currently selected object, especially if disabling selection while pending
            var tempAllThemes = [...allThemes];
            //find index of object
            let index = tempAllThemes.findIndex((theme) => theme.id === value.id);
            tempAllThemes.splice(index, 1);
            setAllThemes(tempAllThemes);
            setLoadedThemeIndex(-1);
            setSelectedComponentPair(-1);
        }

    }

    return (
        <Container>
            <Row>
                <h1>Theme Constructor</h1>
            </Row>
            <Row>
                <GenericSaveLoadPanel
                    objectType='styletheme'
                    loadedObjectIndex={loadedThemeIndex}
                    objects={allThemes}
                    crudActions={crudActions}
                    saveLoadLogic={genericSaveLoadLogic}
                    saveLoadData={allThemes[loadedThemeIndex]}
                    getEditableProperties={() => { return styleThemeProperties(allThemes[loadedThemeIndex]) }}
                />
            </Row>

            <div className="grid grid-cols-6 gap-2">
                {allThemes[loadedThemeIndex] && allThemes[loadedThemeIndex].data.themeColors.map((color, index) => (
                    <div key={'theme_colors_' + index} >
                        <div className="w-32 h-32" style={{ background: color.value, border: 'black solid 2 px' }} >{color.title}</div>
                        <input type="color" value={color.value} onChange={(e) => {
                            let newColors = allThemes[loadedThemeIndex].data.themeColors.map((c, i) => {
                                if (i === index) {
                                    return new ThemeColor(color.title, color.className, e.target.value);
                                } else {
                                    return c;
                                }
                            });
                            let allThemesCopy = [...allThemes];
                            allThemesCopy[loadedThemeIndex].data.themeColors = newColors;
                            setAllThemes(allThemesCopy);
                        }} />
                    </div>
                ))}

            </div>

            <div className='sticky top-0'>

                {selectedComponentPair != -1 && <StyleOverviewPanel
                    selectedComponent={new SelectedComponentData('empty', null, 0, 0)}
                    componentType={allThemes[loadedThemeIndex].data.componentPairs[selectedComponentPair].type as COMPONENT_TYPE}
                    componentStyle={allThemes[loadedThemeIndex].data.componentPairs[selectedComponentPair].styleSetting}
                    setComponentStyle={setComponentStyle}
                    setMasterStyleSetting={() => { }}
                    currentTheme={allThemes[loadedThemeIndex]}
                    masterStyleSetting="constructor"
                />}
            </div>

            <div >
                {allThemes[loadedThemeIndex] && allThemes[loadedThemeIndex].data.componentPairs.map((pair, index) => (
                    <div key={'component_pairs_' + index}
                        style={{ border: allThemes[loadedThemeIndex].data.componentPairs[selectedComponentPair]?.name === pair.name ? 'red solid 2px' : 'white solid 2px' }}
                        onClick={() => setSelectedComponentPair(index)}>
                        {renderComponent(pair)}
                    </div>
                ))}
            </div>

        </Container>
    )


}

export default ThemeConstructor