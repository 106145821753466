//React

//UI
import 'prismjs/themes/prism.css'; //Example style, you can use another
import 'css/codeeditor.css';
import { highlight, languages } from "prismjs";

//Services

//Logics

//Components

//Store

//Classes
import { CodeError } from 'classes/course/CodeError';

const hightlightWithLineNumbersAndErrors = (input: string, language: any, codeExerciseId: string, codeError: CodeError) => {
    //let errorLine = codeError.errorLine;
    return highlight(input, languages.js, 'js')
        .split("\n")
        .map((line, i) => {
            if (codeError.lineNumber === "" || (codeError.lineNumber !== "" && codeError.codeExerciseId === codeExerciseId && i + 1 !== parseInt(codeError.lineNumber))) {
                return `<span class='editorLineNumber'>${i + 1}</span>${line}`
            }
            if (codeError.lineNumber !== null && codeError.codeExerciseId === codeExerciseId && i + 1 === parseInt(codeError.lineNumber)) {
                return `<span class='editorLineNumber red-line'>${i + 1}</span>${line}`
            }
            return '';
        })
        .join("\n");
}

const hightlightWithLineNumbers = (input: string, language: any) =>
    highlight(input, languages.js, 'js')
        .split("\n")
        .map((line: string, i: number) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
        .join("\n");

const parseCodeLines = (code: string, codeExerciseId?: string, codeError?: CodeError) => {
    if (codeError && codeExerciseId) {
        return hightlightWithLineNumbersAndErrors(code, languages, codeExerciseId, codeError);
    } else {
        return hightlightWithLineNumbers(code, languages);
    }
}


export default { parseCodeLines };