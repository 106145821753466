let appDeployment = "development";
let appSite = "gridslate";
let configObject = {
    "apiUrl": "",
    "gridslateUrl": "",
    "slideshowWebsocket": "",
    "slideJoinUrl": "",
    "S3BucketURL": "",
    "S3Bucket": "",
    "S3Region": "",
    "site" : "",
    "deployment": appDeployment,
    "siteTitle" : ""
};

if (appSite === "synapp") {
    configObject = {
        "apiUrl": appDeployment==='development'?"https://localhost:65004":"https://3jcociw7wi.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "gridslateUrl": "https://localhost:55480",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": appDeployment==='development'?"http://localhost:3000/join/":"https://synapp.org/join/",
        "S3BucketURL": "https://s3.ap-northeast-2.amazonaws.com/synapp.slides.storage/development/",
        "S3Bucket": "synapp.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "synapp",
        "deployment": appDeployment,
        "siteTitle" : "Synapp"
    }
}

if (appSite === "gridslate") {
    configObject = {
        //"apiUrl": "https://localhost:55480",
        "apiUrl": "https://nny0tkrwa7.execute-api.ap-northeast-2.amazonaws.com/Prod",
        //"gridslateUrl": "https://localhost:55480",
        "gridslateUrl": "https://nny0tkrwa7.execute-api.ap-northeast-2.amazonaws.com/Prod",
        "slideshowWebsocket": "wss://eu30e67qx2.execute-api.ap-northeast-2.amazonaws.com/Prod/",
        "slideJoinUrl": "http://localhost:3000/join/",
        "S3BucketURL": "https://s3.ap-northeast-2.amazonaws.com/synapp.slides.storage/development/",
        "S3Bucket": "gridslate.frontend",
        "S3Region": "https://s3.ap-northeast-2",
        "site" : "jamesdanielsen",
        "deployment": appDeployment,
        "siteTitle" : "James Danielsen"
    }
}

export default configObject;


