//React
import React from 'react';
import { useEffect, useState } from 'react';

//UI
import { Label, RangeSlider } from 'flowbite-react';
import icons from 'ui_components/icons/textStyleIcons';
import CheckboxAndLabel from 'ui_components/helper/CheckboxAndLabel';

//Services

//Logics
import styleLogic from 'logic/style/styleLogic';

//Components

//Classes
import { SelectedComponentData } from 'classes/components/SelectedComponentData';
import {StyleSetting} from 'classes/style/StyleSetting';
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import {StyleTheme} from 'classes/style/StyleTheme';

type Props = {
    selectedComponent: SelectedComponentData;
    componentStyle: StyleSetting;
    componentType: COMPONENT_TYPE;
    setComponentStyle: Function;   
    currentTheme: StyleTheme;
    setMasterStyleSetting: Function;
    masterStyleSetting: string
}

const StyleOverviewPanelNew = (props: Props) => {

    let { selectedComponent, componentStyle, setComponentStyle, componentType, currentTheme, setMasterStyleSetting, masterStyleSetting } = props;

    const styleItemOptions = styleLogic.returnStyleOptionsForComponentType(componentType);
    const [selectedStyleIcon, setSelectedStyleIcon] = useState(-1);
    const basicColorRanges = styleLogic.returnBasicColorRanges();

    const [sliderSyncY, setSliderSyncY] = useState(false);
    const [sliderSyncX, setSliderSyncX] = useState(false);

    const listOfFontFamilies = ["Arial", "Cortana", "Courier New", "Georgia", "Times New Roman", "Verdana", "Tahoma", "Trebuchet MS", "Impact", "Comic Sans MS", "Lucida Console", "Lucida Sans Unicode", "Palatino Linotype", "Book Antiqua", "Garamond", "Arial Black", "Arial Narrow", "Franklin Gothic Medium", "Century Gothic", "Lucida Sans", "Lucida Grande", "Geneva", "Monaco", "Courier", "monospace"];

    const [colorPickerType, setColorPickerType] = useState("theme"); //basic, theme, or custom

    const returnColorPicker = (targetAttribute: string) => {
        return <React.Fragment>
            {colorPickerType === 'basic' && <div>
                {/* //Create dropdown select based on basic color ranges */}
                <select value={componentStyle[targetAttribute]}
                    style={{ background: componentStyle[targetAttribute] }}
                    onChange={(e) => {
                        let tempComponentStyle = { ...componentStyle };
                        tempComponentStyle[targetAttribute] = e.target.value;
                        setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                    }}>
                    {basicColorRanges.map((colorRange) => {
                        return <option key={colorRange.name} value={colorRange.value} style={{ background: colorRange.value }}>{colorRange.name}</option>
                    })}
                </select>

            </div>}
            {colorPickerType === 'theme' && <div>
                {/* //Create dropdown select based on basic color ranges */}
                <select value={componentStyle[targetAttribute]}
                    style={{ background: styleLogic.parseThemeColor(componentStyle[targetAttribute], currentTheme.data.themeColors) }}
                    onChange={(e) => {
                        let tempComponentStyle = { ...componentStyle };
                        tempComponentStyle[targetAttribute] = e.target.value;
                        setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                    }}>
                    {currentTheme.data.themeColors.map((colorRange) => {
                        return <option
                            key={colorRange.title}
                            value={colorRange.className}
                            style={{ background: colorRange.value }}
                        >{colorRange.title}</option>
                    })}
                </select>

            </div>}
            {colorPickerType === 'custom' && <div>
                <input type="color" value={componentStyle[targetAttribute]} style={{ width: '100%' }} onChange={(e) => {
                    let tempComponentStyle = { ...componentStyle };
                    tempComponentStyle[targetAttribute] = e.target.value;
                    setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                }} />
            </div>}

            <div className='text-xs rounded border-2' onClick={() => {
                if (colorPickerType === 'basic') {
                    setColorPickerType('theme');
                } else if (colorPickerType === 'theme') {
                    setColorPickerType('custom');
                } else {
                    setColorPickerType('basic');
                }
            }}>
                Color Type: {colorPickerType}
            </div>

        </React.Fragment>
    }

    const returnIcon = (styleItem: string) => {
        switch (styleItem) {
            case "fontFamily":
                //return a dropdown select with font families
                return <select value={componentStyle["fontFamily"]} onChange={(e) => {
                    let tempComponentStyle = { ...componentStyle };
                    tempComponentStyle["fontFamily"] = e.target.value;
                    setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                }}>
                    {listOfFontFamilies.map((fontFamily) => {
                        return <option key={fontFamily} value={fontFamily} style={{ fontFamily: fontFamily }}>{fontFamily}</option>
                    })}
                </select>

            case "fontSize":
                return <div><svg className="w-[32px] h-[32px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3" />
                </svg>
                    {returnNumberInputBox("fontSize")}
                </div>
            case "color":
                return (returnColorPicker("color"))

            case "background":
                return (returnColorPicker("background"))
            case "bold":
                return icons.boldIcon();
            case "italics":
                return icons.italicIcon();

            case "underline":
                return icons.underlineIcon();

            case "alignment":
                if (componentStyle["alignment"] === "center") {
                    return icons.alignmentCenterIcon();
                } else if (componentStyle["alignment"] === "right") {
                    return icons.alignmentRightIcon();
                } else if (componentStyle["alignment"] === "justify") {
                    return icons.alignmentJustifyIcon();
                }
                else {
                    return icons.alignmentLeftIcon();
                }

            case "margin":
                return <p>Margin</p>
            case "padding":
                return <p>Padding</p>
            case "border":
                return <p>Border</p>
            case "width":
                return <p>Width</p>

            case "height":
                return <p>Height</p>
            default:
                <React.Fragment></React.Fragment>
                //<></>
        }
    }

    //Actually return background of clicked items?
    const returnBorder = (styleItem: string) => {
        let thisItem = styleItemOptions.find((item) => item.name === styleItem);
        if (thisItem === undefined) {
            return ' border-2 border-white';
        }

        if (thisItem.type && thisItem.type === "click" && componentStyle[styleItem] !== thisItem.values[0]) {
            return " border-2 border-slate-50 bg-red-500";
        }
    }

    const returnNumberInputBox = (targetAttribute: string) => {
        return (
            <div className="relative flex items-center max-w-[8rem]">
                <button type="button"
                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-s-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                    onClick={() => {
                        if (parseInt(componentStyle[targetAttribute]) <= 8) {
                            return;
                        }
                        let tempComponentStyle = { ...componentStyle };
                        tempComponentStyle[targetAttribute] = "" + (parseInt(tempComponentStyle[targetAttribute]) - 3);
                        setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                    }}
                >
                    {icons.decrementIcon()}
                </button>
                <input type="text" data-input-counter aria-describedby="helper-text-explanation"
                    id="quantity-input"
                    className="bg-gray-50 border-x-0 border-gray-300 h-11 text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={componentStyle[targetAttribute]}
                    onChange={(e) => {

                        let tempComponentStyle = { ...componentStyle };
                        tempComponentStyle[targetAttribute] = "" + e.target.value;
                        setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                    }}
                    required />
                <button type="button"
                    className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-e-lg p-3 h-11 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none"
                    onClick={() => {
                        if (parseInt(componentStyle[targetAttribute]) >= 120) {
                            return;
                        }
                        let tempComponentStyle = { ...componentStyle };
                        tempComponentStyle[targetAttribute] = "" + (parseInt(tempComponentStyle[targetAttribute]) + 3);
                        setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                    }}
                >
                    {icons.incrementIcon()}
                </button>
            </div>
        )
    }

    const handleStyleIconClick = (index: number) => {
        //If no component is selected, return

        if (!selectedComponent || !selectedComponent.componentRef) {
            console.log('No component selected');
            return;
        }

        let componentRef = selectedComponent.componentRef;

        setSelectedStyleIcon(index);
        //console.log(index);

        let styleItemClickedUpon = styleItemOptions[index].name;

        let thisItem = styleItemOptions.find((item) => item.name === styleItemClickedUpon);
        //console.log(styleItemClickedUpon, thisItem);
        //let thisItem = styleItemOptions[index];
        //console.log(thisItem);
        if (thisItem === undefined) {
            console.log("thisItem is undefined:", styleItemClickedUpon);
            return;
        }

        if (thisItem.type && thisItem.type === "click") {
            //console.log(componentStyle[styleItemClickedUpon], thisItem.defaultValue);
            if (componentStyle[styleItemClickedUpon] === thisItem.values[0]) {
                let tempComponentStyle = { ...componentStyle };
                tempComponentStyle[styleItemClickedUpon] = thisItem.values[1];
                setComponentStyle(componentRef, tempComponentStyle);
            } else {
                let tempComponentStyle = { ...componentStyle };
                tempComponentStyle[styleItemClickedUpon] = thisItem.values[0];
                setComponentStyle(componentRef, tempComponentStyle);
            }
        }

        if (thisItem.type && thisItem.type === "clickthrough") {
            let currentSetting = componentStyle[styleItemClickedUpon];
            //find index of current setting in styleItemOptions object
            let thisStyleItemOption = styleItemOptions.find((item) => item.name === styleItemClickedUpon);
            if (thisStyleItemOption === undefined) {
                return;
            }
            let currentIndex = thisStyleItemOption.values.indexOf(currentSetting);
            //if index is last in array, set to first value, otherwise set value to next value
            let newIndex = (currentIndex === thisStyleItemOption.values.length - 1) ? 0 : currentIndex + 1;
            let tempComponentStyle = { ...componentStyle };
            tempComponentStyle[styleItemClickedUpon] = thisStyleItemOption.values[newIndex];
            setComponentStyle(componentRef, tempComponentStyle);

        }

        //Update the style of the selected component
        //let newStyle = selectedComponent.style;
    }

    const renderSlider = (styleItemName: string, allValues: string, direction: string) => {

        const parseSliderValue = (value: string) => {
            let tempValue = parseInt(value);
            if (tempValue < 10) {
                return "0" + tempValue;
            } else {
                return "" + tempValue;
            }
        }

        let topPos1 = 0, topPos2 = 2, rightPos1 = 2, rightPos2 = 4, bottomPos1 = 4, bottomPos2 = 6, leftPos1 = 6, leftPos2 = 8;

        const getValue = () => {
            if (direction === 'top') {
                return allValues.substring(topPos1, topPos2);
            } else if (direction === 'right') {
                return allValues.substring(rightPos1, rightPos2);
            } else if (direction === 'bottom') {
                return allValues.substring(bottomPos1, bottomPos2);
            } else if (direction === 'left') {
                return allValues.substring(leftPos1, leftPos2);
            }
        }

        const setValue = (value: string) => {
            let allValuesTemp = allValues;
            //if ysynchronized, set all values to the same value
            //if xsynchronized, set all values to the same value
            //if neither, set only the value of the direction
            if (sliderSyncY && sliderSyncX) {
                allValuesTemp = value + value + value + value;
            } else if (sliderSyncY && (direction === 'top' || direction === 'bottom')) {
                allValuesTemp = value + allValuesTemp.substring(2, 4) + value + allValuesTemp.substring(6, 8);
            } else if (sliderSyncX && (direction === 'left' || direction === 'right')) {
                allValuesTemp = allValuesTemp.substring(0, 2) + value + allValuesTemp.substring(4, 6) + value;
            } else {
                if (direction === 'top') {
                    allValuesTemp = value + allValuesTemp.substring(2, 4) + allValuesTemp.substring(4, 6) + allValuesTemp.substring(6, 8);
                } else if (direction === 'right') {
                    allValuesTemp = allValuesTemp.substring(0, 2) + value + allValuesTemp.substring(4, 6) + allValuesTemp.substring(6, 8);
                } else if (direction === 'bottom') {
                    allValuesTemp = allValuesTemp.substring(0, 2) + allValuesTemp.substring(2, 4) + value + allValuesTemp.substring(6, 8);
                } else if (direction === 'left') {
                    allValuesTemp = allValuesTemp.substring(0, 2) + allValuesTemp.substring(2, 4) + allValuesTemp.substring(4, 6) + value;
                }
            }
            let tempComponentStyle = { ...componentStyle };
            tempComponentStyle[styleItemName] = allValuesTemp;
            setComponentStyle(selectedComponent.componentRef, tempComponentStyle);

        }

        return (
            <RangeSlider
                min="0"
                max={"" + (styleItemOptions[selectedStyleIcon].values[1])}
                //value={allValues.substring(sPos1, sPos2)}
                value={getValue()}
                onChange={(e) => {
                    setValue(parseSliderValue(e.target.value));
                    // let tempComponentStyle = { ...componentStyle };
                    // tempComponentStyle[styleItemName] = styleItemOptions[selectedStyleIcon].values[parseInt(e.target.value)];
                    // let tempValue = allValues.substring(0, sPos1) + parseSliderValue(e.target.value) + allValues.substring(sPos2);
                    // tempComponentStyle[styleItemName] = tempValue;
                    // setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                }}
                id="default-range" />
        )
    }

    return (
        <div className='rounded border-2 bg-slate-300'>
            {/* //If themeOrCustom is theme, create an opaque layer over the div that prevents access to the elements below
            //Display a message that the style is set by the theme
            //Allow user to switch to custom style */}
            
            {masterStyleSetting === "theme" && <div className="absolute w-full h-auto bg-black bg-opacity-25 flex items-center justify-center z-50">
                <div className="bg-white p-4 rounded-lg">
                    <p className="text-lg">This component's style is set by the theme</p>
                    <button onClick={() => setMasterStyleSetting(selectedComponent.componentRef, 'custom')}>Set to custom style</button>
                </div>
            </div>}

            {styleItemOptions && styleItemOptions.map((styleItemOption, index) => {

                return (
                    <div key={'icon_' + index}
                        className={'inline-block m-2 align-middle ' + returnBorder(styleItemOption.name)}
                        onClick={() => handleStyleIconClick(index)}
                    >
                        {returnIcon(styleItemOption.name)}
                    </div>
                )

            })}

            {selectedStyleIcon !== -1 && styleItemOptions[selectedStyleIcon] && styleItemOptions[selectedStyleIcon].type === "slider" &&
                <div className="mb-1 block">
                    <Label className='text-4xl' htmlFor="default-range" value={styleItemOptions[selectedStyleIcon].name + " : " + componentStyle[styleItemOptions[selectedStyleIcon].name]} />
                    <RangeSlider
                        min="0"
                        max={"" + (styleItemOptions[selectedStyleIcon].values.length - 1)}
                        value={styleItemOptions[selectedStyleIcon].values.indexOf(componentStyle[styleItemOptions[selectedStyleIcon].name])}
                        onChange={(e) => {
                            let tempComponentStyle = { ...componentStyle };
                            tempComponentStyle[styleItemOptions[selectedStyleIcon].name] = styleItemOptions[selectedStyleIcon].values[parseInt(e.target.value)];
                            setComponentStyle(selectedComponent.componentRef, tempComponentStyle);
                        }}
                        id="default-range" />
                </div>
            }
            {selectedStyleIcon !== -1 && styleItemOptions[selectedStyleIcon] && styleItemOptions[selectedStyleIcon].type === "4dslider" &&
                <div className="mb-1 block">
                    <Label className='text-2xl' value={styleItemOptions[selectedStyleIcon].name + " : " + componentStyle[styleItemOptions[selectedStyleIcon].name]} />
                    <div className="grid grid-cols-3 w-96 h-32">

                        <div className="w-28 h-16">
                            <CheckboxAndLabel label="Sync Y axis'" checked={sliderSyncY} onChange={() => setSliderSyncY(!sliderSyncY)}></CheckboxAndLabel>
                            <CheckboxAndLabel label="Sync X axis'" checked={sliderSyncX} onChange={() => setSliderSyncX(!sliderSyncX)}></CheckboxAndLabel>
                        </div>
                        <div className="w-28 h-16">
                            {renderSlider(styleItemOptions[selectedStyleIcon].name, componentStyle[styleItemOptions[selectedStyleIcon].name], 'top')}
                            Top: {componentStyle[styleItemOptions[selectedStyleIcon].name].substring(0, 2)}
                        </div>
                        <div className="w-28 h-16"></div>

                        <div className="w-28 h-16">
                            {renderSlider(styleItemOptions[selectedStyleIcon].name, componentStyle[styleItemOptions[selectedStyleIcon].name], 'left')}
                            Left: {componentStyle[styleItemOptions[selectedStyleIcon].name].substring(6, 8)}
                        </div>
                        <div className="w-28 h-16">
                            {renderSlider(styleItemOptions[selectedStyleIcon].name, componentStyle[styleItemOptions[selectedStyleIcon].name], 'bottom')}
                            Bottom: {componentStyle[styleItemOptions[selectedStyleIcon].name].substring(4, 6)}
                        </div>
                        <div className="w-28 h-16">
                            {renderSlider(styleItemOptions[selectedStyleIcon].name, componentStyle[styleItemOptions[selectedStyleIcon].name], 'right')}
                            Right: {componentStyle[styleItemOptions[selectedStyleIcon].name].substring(2, 4)}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default StyleOverviewPanelNew;