//React
import { useEffect, useState, useRef } from 'react';

//UI
import { Button, Tabs, Textarea, RangeSlider, Spinner, TabsRef, Accordion } from 'flowbite-react';
import { Row } from 'ui_components/helper/HelperComponents';

//Services
import errorService from 'services/errorService';
import crudService from 'services/crudService';

//Logics
import styleLogic from 'logic/style/styleLogic';
import parseLogic from 'logic/parse/parseLogic';
import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
import genericSaveLoadLogic from 'logic/io/genericSaveLoadLogic';

//Components
import GridConstructorDynamic from 'app_gridslate/constructor/GridConstructorDynamic';
import CategoryConstructor from 'app_gridslate/constructor/dynamic/CategoryConstructor';
import GenericSaveLoadPanel from './panels/GenericSaveLoadPanel';
import CategorySelector from './dynamic/CategorySelector';

//Store
import { useAtom } from 'jotai';
import { mcqsInMemory } from 'atom';

//Classes
import { FilterModel } from 'classes/crud/FilterModel';
import { CLASS } from 'classes/enums/classes';
import { Page } from "classes/gridslate/Page";
import { Component } from "classes/components/Component";
import { GridConstructorDynamicInterface } from 'classes/interfaces/GridConstructorDynamicInterface';
import { CategoryStatus } from 'classes/general/CategoryStatus';
import { PageChallenge, getPageChallengeProperties } from "classes/course/PageChallenge";
import { CRUD_ACTION } from 'classes/enums/crud-action';
import { EditablePropertyMetadata } from 'classes/gridslate/EditablePropertyMetadata';
import { IsBusy } from 'classes/general/IsBusy';
import { EditablePropertySelectItem } from 'classes/gridslate/EditablePropertySelectItem';
import { toast } from 'react-toastify';

//shift + Mousewheel increase fontsize and image size
const PageChallengeConstructor = () => {

    const [mcqs, setMcqs] = useAtom(mcqsInMemory);

    const [pages, setPages] = useState<Page[]>([]);
    const [pageChallengePage, setPageChallengePage] = useState<Page | null>(null); // The page that is the basis for the challenge 

    const [components, setComponents] = useState<Component[]>([]); // The components of the page

    const [pageChallenges, setPageChallenges] = useState<PageChallenge[]>([]);
    const [loadedPageChallengeIndex, setLoadedPageChallengeIndex] = useState(-1);

    const [categoryStatus, setCategoryStatus] = useState(new CategoryStatus());
    const [activeTab, setActiveTab] = useState(0);

    const [selectedContent, setSelectedContent] = useState("");

    let defaultTheme = styleLogic.returnDefaultStyleTheme('Default', 'page', '');
    const gridConstructorRef = useRef<GridConstructorDynamicInterface>(null);

    //============================================================================ - USE EFFECTS
    useEffect(() => {
        getPages();
        loadPageChallenges();
    }, []);

    useEffect(() => {

        const loadCategories = async () => {
            //Load all mcq categories for the user
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            let response = await crudService.get(CLASS.category, filterModel);
            if (response.success) {
                let tempStatus = { ...categoryStatus };
                tempStatus.categories = response.payload;
                setCategoryStatus(tempStatus);
            }
            else {
                errorService.handleError(response.payload);
            }
        }

        loadCategories();
    }, []);

    //============================================================================ - CRUD FUNCTIONS

    const getPages = async () => {
        const filterModel = new FilterModel();
        filterModel.OnlyOwner = true;
        let response = await crudService.get("Page", filterModel);
        if (response.success) {
            setPages(response.payload);
        }
        else {
            errorService.handleError(response);
        }
    }

    const loadPage = async (pageId: string) => {
        let filterModel = new FilterModel();
        filterModel.PropertyFilters.push(["Id", pageId]);
        let response = await crudService.load("Page", filterModel);
        if (response.success) {
            setPageChallengePage(response.payload.page);
            setComponents(parseLogic.parseComponentsFromLoad(response.payload.components));
            //Get associated text challenges
            //loadPageChallenges(pageId);
        }
        else {
            errorService.handleError(response);
        }
    }

    const loadPageChallenges = async () => {
        let filterModel = new FilterModel();
        //filterModel.PropertyFilters.push(["PageId", pageId]);
        filterModel.OnlyOwner = true;
        let response = await crudService.get("PageChallenge", filterModel);
        if (response.success) {
            setPageChallenges(response.payload);
        }
        else {
            errorService.handleError(response);
        }
    }

    const loadMcqs = async (categoryId: string) => {
        let filterModel = new FilterModel();
        //filterModel.PropertyFilters.push(["CategoryId", categoryId]);
        //filterModel.PropertyFilters.push([]);
        let response = await crudService.get("Mcq", filterModel);
        if (response.success) {
            //Getting all mcqs, at the moment
            setMcqs(response.payload);
        }
        else {
            errorService.handleError(response);
        }
    }

    //============================================================================ - FUNCTIONS

    const parseSelectedContent = (seperator: string) => {
        let content = "";
        if (gridConstructorRef.current) {
            content = gridConstructorRef.current.getSelectedContent(seperator);
        }
        return content;
    }

    // const updateCategoryStatus = (action: string, value: any) => {
    //     setCategoryStatus(categoryStatusLogic.updateStatus(categoryStatus, action, value));
    // }

    const pageChallengeCrudFunctions = (action: CRUD_ACTION, value: any, target?: string) => {
        if (action === CRUD_ACTION.appendNewObject) {
            //expect value as new style theme object
            var newPageChallenges = [...pageChallenges, value];
            setLoadedPageChallengeIndex(newPageChallenges.length - 1);
            setPageChallenges(newPageChallenges);
        }
        if (action === CRUD_ACTION.getUpdatedObject) {
            let updatedPageChallenge = { ...pageChallenges[loadedPageChallengeIndex] };
            if (gridConstructorRef.current) {
                //TODO: This shouldn't be necessary, but it is  
                let requestedPageChallenge = gridConstructorRef.current.getPageChallenge();
                updatedPageChallenge.stoppingPoints = requestedPageChallenge.stoppingPoints;
                updatedPageChallenge.mcqStoppingPoints = requestedPageChallenge.mcqStoppingPoints;
                updatedPageChallenge.codeExerciseStoppingPoints = requestedPageChallenge.codeExerciseStoppingPoints;
                //Are categories updated?
             
            }
            
            return updatedPageChallenge;
        }
        else if (action === CRUD_ACTION.returnNewObject) {
            let newPageChallenge = new PageChallenge();
            newPageChallenge.pageId = pageChallengePage?.id as string;
            return newPageChallenge;
        }
        else if (action === CRUD_ACTION.loadObject) {
            //value is actually id of object here, maybe implement isSimpleLoading again, because new theme won't have an id
            let index = pageChallenges.findIndex((pageChallenge) => pageChallenge.id === value);
            setLoadedPageChallengeIndex(index);
            //update CategoryStatus to reflect the loaded object
            let loadedObject = pageChallenges[index];
            //setCategoryStatus(categoryStatusLogic.setCategoryStatusFromId(categoryStatus, loadedObject.categoryId));
            setCategoryStatus(categoryStatusLogic.setCategoryStatusFromObject(categoryStatus, loadedObject));

            //Load page
            loadPage(loadedObject.pageId);

            //Get associated mcqs
            //loadMcqs(loadedObject.categoryId);
            loadMcqs(categoryStatusLogic.findHighestCategoryId(loadedObject));

        }
        else if (action === CRUD_ACTION.updateObject) {
            //value as StyleTheme object
            //let newPageChallenge = { ...pageChallenges[loadedPageChallengeIndex] };
            let newPageChallenge = value as PageChallenge;
            var tempPageChallenges = [...pageChallenges];
            tempPageChallenges[loadedPageChallengeIndex] = newPageChallenge;
            setPageChallenges(tempPageChallenges);
            toast.success("Page Challenge updated");
        }
        else if (action === CRUD_ACTION.updateObjectProperties) {
            let editableProperties = value as EditablePropertyMetadata[];
            let newPageChallenges = [...pageChallenges];
            let newPageChallenge = { ...newPageChallenges[loadedPageChallengeIndex] };
            newPageChallenge = parseLogic.parseObjectUpdate(newPageChallenge, editableProperties);
            newPageChallenges[loadedPageChallengeIndex] = newPageChallenge;
            setPageChallenges(newPageChallenges);
        }

        else if (action === CRUD_ACTION.deleteObject) {
            //value is actually the object here
            //But SHOULD always be currently selected object, especially if disabling selection while pending
            var tempPageChallenges = [...pageChallenges];
            //find index of object
            let index = tempPageChallenges.findIndex((pageChallenge) => pageChallenge.id === value.id);
            tempPageChallenges.splice(index, 1);
            setPageChallenges(tempPageChallenges);
            setPageChallengePage(null);
            setLoadedPageChallengeIndex(-1);
            toast.success("Page Challenge deleted");
            //setSelectedComponentPair(-1);
        }

    }

    const getNewPageChallengeEditableProperties = () => {
        let thisPageChallenge = pageChallenges[loadedPageChallengeIndex];
        //If no loaded text challenge, create a new one
        if (!thisPageChallenge) {
            thisPageChallenge = new PageChallenge();
        }
        let editableProperties = getPageChallengeProperties(thisPageChallenge);
        //create pageIdList
        let pageIdList = [] as EditablePropertySelectItem[];
        for (let i = 0; i < pages.length; i++) {
            pageIdList.push(new EditablePropertySelectItem(pages[i].title, pages[i].id));
        }
        //add pageIdList
        editableProperties.push({ propertyName: 'pageId', propertyType: 'select', displayName: "Page to base challenge on", value: thisPageChallenge.pageId, dataSource: pageIdList });
        return editableProperties;
    }

    const setPageChallenge = (pageChallenge: PageChallenge) => {
        let tempPageChallenges = [...pageChallenges];
        tempPageChallenges[loadedPageChallengeIndex] = pageChallenge;
        setPageChallenges(tempPageChallenges);
    }

    //============================================================================ - RENDER

    return (
        <div className='container mx-auto'>
            <Tabs onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="Step 1: Create/Load Text Challenge">
                    {/* <Row>
                        <select onChange={(e) => loadPage(e.target.value)}>
                            <option value="">Select Page</option>
                            {pages && pages.map((page) => {
                                return <option key={page.id} value={page.id}>{page.title}</option>
                            })}
                        </select>
                    </Row> */}
                    <GenericSaveLoadPanel
                        objectType="PageChallenge"
                        objects={pageChallenges}
                        loadedObjectIndex={loadedPageChallengeIndex}
                        crudActions={pageChallengeCrudFunctions}
                        saveLoadLogic={genericSaveLoadLogic}
                        //TODO: fix this saveLoadData thing
                        saveLoadData={pageChallenges[loadedPageChallengeIndex]}
                        getEditableProperties={getNewPageChallengeEditableProperties}
                    />

                    <Row>
                        {/* <CategorySelector categoryStatus={categoryStatus} setCategoryStatus={updateCategoryStatus} isBusy={new IsBusy()} /> */}
                        {pageChallengePage && <CategorySelector
                            categories={categoryStatus.categories}
                            //categoryObject={categoryStatus}
                            //setCategoryObject={setCategoryStatus}
                            categoryObject={pageChallenges[loadedPageChallengeIndex]}
                            setCategoryObject={setPageChallenge}
                            //categoryStatus={categoryStatus} 
                            //setCategoryStatus={setCategoryStatus}
                            isBusy={new IsBusy()} />
                        }
                    </Row>
                    <Row>
                        {pageChallengePage &&
                            <GridConstructorDynamic
                                ref={gridConstructorRef}
                                parentComponents={components}
                                parentGridArray={pageChallengePage?.gridArray as string[][]}
                                theme={defaultTheme}
                                categoryStatus={categoryStatus}
                                parentCategoryObject={pageChallenges[loadedPageChallengeIndex]}
                                //loadedMcqStoppingPoints={pageChallenges[loadedPageChallengeIndex] ? pageChallenges[loadedPageChallengeIndex].mcqStoppingPoints : []}
                                pageChallenge={pageChallenges[loadedPageChallengeIndex]}
                            />}
                        {!pageChallengePage && <div>No page loaded</div>}
                    </Row>
                </Tabs.Item>
                <Tabs.Item title="Step 2: Create categories">
                    <Button onClick={() => setSelectedContent(parseSelectedContent(";"))}>Get Content</Button>
                    {/* <CategoryConstructor categoryStatus={categoryStatus} setCategoryStatus={updateCategoryStatus} content={selectedContent} /> */}
                    <CategoryConstructor categoryStatus={categoryStatus} setCategoryStatus={setCategoryStatus} content={selectedContent} />
                </Tabs.Item>
                {/* <Tabs.Item title="Step 3: Generate MCQ from content">
                    <Button onClick={() => setSelectedContent(parseSelectedContent("\n"))}>Get Content</Button>
                    <MCQConstructor content={selectedContent} mcqs={mcqs} setMcqs={setMcqs} />
                </Tabs.Item> */}
                {/* <Tabs.Item title="Step 4: Create Mcq stopping points">

                </Tabs.Item>
                <Tabs.Item title="View Mode">
                   
                </Tabs.Item> */}
            </Tabs>

        </div>
    )

}

export default PageChallengeConstructor;