//React
import { useEffect } from 'react';

//UI
//import CheckboxAndLabel from 'ui_components/helper/CheckboxAndLabel';

//Services

//Logics

//Components

//Classes
import { SelectedComponentData } from 'classes/components/SelectedComponentData';

type Props = {
    selectedComponent: SelectedComponentData;
    setComponentSettings: Function;
}

const ComponentSettingsPanel = (props: Props) => {

    let { selectedComponent, setComponentSettings } = props;
    let type = selectedComponent.component?.type;
    let settings = selectedComponent.component?.data;

    useEffect(() => {
        // switch (type) {
        //     case COMPONENT_TYPE.list:
        //         if (!settings.listType) { settings.listType = "unordered"; }
        //         break;
        //     case COMPONENT_TYPE.heading:
        //         if (!settings.headingLevel) { settings.headingLevel = 1; }
        //         if (!settings.automaticNumbering) { settings.automaticNumbering = false; }
        //         if (!settings.collapsable) { settings.collapsable = false; }
        //         break;

        //     default:
        //         break;
        // }
        // if (!settings.hidden) { settings.hidden = false; }
        // if (!settings.transitionType) { settings.transitionType = "none"; }
    }, [selectedComponent]);
    //let componentTypeText = ["Title", "Heading", "Text", "Image", "List", "Code"];
    //let componentTypes = [COMPONENT_TYPE.title, COMPONENT_TYPE.heading, COMPONENT_TYPE.text, COMPONENT_TYPE.image, COMPONENT_TYPE.list, COMPONENT_TYPE.codeblock];

    // listType: string = ""; /ordered, unordered
    // headingLevel: number = 0;
    // automaticNumbering: boolean = false;
    // collapsable: boolean = false;
    // hidden: boolean = false;
    // transitionType: string = "none";

    return (
        <div className='rounded border-2 bg-slate-300 m-2'>
            {/* {type == COMPONENT_TYPE.list && <select value={settings.listType} onChange={(e) => {
                settings.listType = e.target.value;
                setComponentSettings(selectedComponent.componentRef, settings);
            }}>
                <option value="unordered">bullet points</option>
                <option value="ordered">numbers</option>
            </select>}
            {type == COMPONENT_TYPE.heading && <select value={settings.headingLevel} onChange={(e) => {
                settings.headingLevel = parseInt(e.target.value);
                setComponentSettings(selectedComponent.componentRef, settings);
            }}>
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Heading 3</option>
                <option value="4">Heading 4</option>
                <option value="5">Heading 5</option>

            </select>}
            {type == COMPONENT_TYPE.heading &&
                <CheckboxAndLabel label="Automatic numbering?"
                    checked={settings.automaticNumbering}
                    onChange={() => {
                        settings.automaticNumbering = !settings.automaticNumbering;
                        setComponentSettings(selectedComponent.componentRef, settings);
                    }}></CheckboxAndLabel>}
            {type == COMPONENT_TYPE.heading && <CheckboxAndLabel label="Collapsable?"
                checked={settings.collapsable}
                onChange={() => {
                    settings.collapsable = !settings.collapsable;
                    setComponentSettings(selectedComponent.componentRef, settings);
                }}></CheckboxAndLabel>}
            <CheckboxAndLabel label="Hidden?"
                checked={settings.hidden}
                onChange={() => {
                    settings.hidden = !settings.hidden;
                    setComponentSettings(selectedComponent.componentRef, settings);
                }}></CheckboxAndLabel>
            <select value={settings.transitionType} onChange={(e) => {
                settings.transitionType = e.target.value;
                setComponentSettings(selectedComponent.componentRef, settings);
            }}>
                <option value="none">No transition</option>
                <option value="fade">Fade</option>
                <option value="slide">Slide</option>
                <option value="slidefade">Slide and fade</option>
            </select> */}

                
        </div>
    )
}

export default ComponentSettingsPanel;