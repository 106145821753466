//React
import { useState, useEffect } from 'react';

//UI
import { Button } from "flowbite-react";

//Services
import crudService from 'services/crudService';

//Logics

//Components

//Store
// import { useAtom } from 'jotai';
// import { mcqsInMemory } from 'atom';

//Classes
import { CodeExercise } from "classes/course/CodeExercise";
import { CodeExerciseSubmission } from 'classes/course/CodeExerciseSubmission';
import { StoppingPointStatus } from 'classes/course/StoppingPointStatus';
import { CodeExerciseStoppingPoint } from "classes/course/CodeExerciseStoppingPoint";
import { STATUS } from "classes/enums/status";
import { FilterModel } from "classes/crud/FilterModel";
import { CLASS } from "classes/enums/classes"; //TODO: chnage this to enums/class
import errorService from 'services/errorService';
import SingleCodeExercise from './SingleCodeExercise';
//import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';
//import { CategoryStatus } from 'classes/general/CategoryStatus';

type Props = {
    // level0CategoryId: string;
    // level1CategoryId: string;
    // level2CategoryId: string;
    questionsSubmittable: boolean;
    codeExerciseStoppingPoint: CodeExerciseStoppingPoint;
    stoppingPointStatus: StoppingPointStatus | undefined;
    submitStoppingPointStatus: Function;
    challengeGuidRef: string;
    //codeExerciseSubmissions: CodeExerciseSubmission[];
    //submitCodeExerciseSubmissions: Function;

}

// class SubmittedAnswer {
//     mcqId: string = "";
//     answerGuid: string = "";
//     isGraded: boolean = false;
//     isCorrect: boolean = false;
// }

const CodeExerciseViewer = (props: Props) => {

    const { codeExerciseStoppingPoint, questionsSubmittable, challengeGuidRef } = props;
    //const [mcqs, setMcqs] = useAtom(mcqsInMemory);
    //const [filteredMcqsByCategory, setFilteredMcqsByCategory] = useState<Mcq[]>([]);
    //const [filteredMcqs, setFilteredMcqs] = useState<Mcq[]>([]);

    const [selectedCodeExerciseIndex, setSelectedCodeExerciseIndex] = useState(-1);

    const [challengeStatus, setChallengeStatus] = useState(questionsSubmittable ? "waiting" : "viewing");

    const [codeExercises, setCodeExercises] = useState<CodeExercise[]>([]);

    //const [submittedAnswers, setSubmittedAnswers] = useState<SubmittedAnswer[]>([]);
    //const [codeExerciseSubmissionsState, setCodeExerciseSubmissionsState] = useState<CodeExerciseSubmission[]>(codeExerciseSubmissions);
    const [codeExerciseSubmissions, setCodeExerciseSubmissions] = useState<CodeExerciseSubmission[]>([]);

    const [overViewCardSelected, setOverViewCardSelected] = useState(true);

    const [challengeCompletedSuccessfully, setChallengeCompletedSuccessfully] = useState(false);

    //const [totalMcqsQuestionsInChallenge, setTotalMcqsQuestionsInChallenge] = useState(0);



    useEffect(() => {

        const getCodeExercises = async () => {
            let filterModel = new FilterModel();
            if (codeExerciseStoppingPoint.level2CategoryId !== "None") {
                filterModel.PropertyFilters.push(["Level2CategoryId", codeExerciseStoppingPoint.level2CategoryId]);
            } else if (codeExerciseStoppingPoint.level1CategoryId !== "None") {
                filterModel.PropertyFilters.push(["Level1CategoryId", codeExerciseStoppingPoint.level1CategoryId]);
            } else if (codeExerciseStoppingPoint.level0CategoryId !== "None") {
                filterModel.PropertyFilters.push(["Level0CategoryId", codeExerciseStoppingPoint.level0CategoryId]);
            }

            let response = await crudService.get(CLASS.codeExercise, filterModel);
            if (response.success) {
                //Sort code exercises by difficulty
                response.payload.sort((a: CodeExercise, b: CodeExercise) => a.difficulty - b.difficulty);
                setCodeExercises(response.payload);
            } else {
                errorService.handleError(response);
            }
        }

        const getCodeExerciseSubmissions = async () => {
            let filterModel = new FilterModel();
            filterModel.PropertyFilters.push(["ChallengeGuidRef", challengeGuidRef]);
            let response = await crudService.get(CLASS.codeExerciseSubmission, filterModel);
            if (response.success) {
                setCodeExerciseSubmissions(response.payload);
            } else {
                errorService.handleError(response);
            }
        }

        getCodeExercises();
        getCodeExerciseSubmissions();

    }, [codeExerciseStoppingPoint]);

    // useEffect(() => {
    //     if (stoppingPointStatus && stoppingPointStatus.dateCompleted) {
    //         setChallengeCompletedSuccessfully(true);
    //     }
    // }, [stoppingPointStatus]);

    // const selectChallengeMcqs = (filteredMcqsByCategory: Mcq[]) => {
    //     //randomly select mcqs according to numberOfMcqsToDisplay
    //     let tempMcqs = [...filteredMcqsByCategory];
    //     let numberOfMcqs = (tempMcqs.length > mcqStoppingPoint.numberOfQuestionsPerChallenge) ? mcqStoppingPoint.numberOfQuestionsPerChallenge : tempMcqs.length;
    //     setTotalMcqsQuestionsInChallenge(numberOfMcqs);
    //     let selectedMcqs: Mcq[] = [];
    //     for (let i = 0; i < numberOfMcqs; i++) {

    //         let randomIndex = Math.floor(Math.random() * tempMcqs.length);
    //         // console.log(tempMcqs);
    //         // console.log(randomIndex);
    //         selectedMcqs.push(tempMcqs[randomIndex]);
    //         tempMcqs.splice(randomIndex, 1);
    //     }
    //     setFilteredMcqs(selectedMcqs);
    // }

    // const completeChallenge = (submittedAnswers: SubmittedAnswer[]) => {
    //     //Check if pass rate is met
    //     let numberOfCorrectAnswers = submittedAnswers.filter(x => x.isCorrect).length;
    //     let percentageCorrect = (numberOfCorrectAnswers / totalMcqsQuestionsInChallenge) * 100;
    //     if (percentageCorrect >= mcqStoppingPoint.passGrade) {
    //         console.log("Challenge complete!");
    //         if (!challengeCompletedSuccessfully) {
    //             setChallengeCompletedSuccessfully(true);
    //             submitStoppingPointStatus();
    //         }

    //     } else {
    //         console.log("Challenge failed!");
    //     }
    //     setChallengeStatus("completed");
    // }


    //======================================================== Render and Render helper functions

    const renderOverviewCard = () => {

        if (challengeStatus === "waiting") {
            return (
                <div>
                    <p>Overview</p>
                    <p>Point submission threshold: {codeExerciseStoppingPoint.minimumPointThreshold}%</p>
                    {/* <p>Number of questions: {totalMcqsQuestionsInChallenge}</p> */}
                    {/* <Button onClick={() => { setChallengeStatus("running") }}>Start!</Button> */}
                </div>
            )
        }
        // else if (challengeStatus === "completed") {
        //     let correctAnswers = submittedAnswers.filter(x => x.isCorrect).length;
        //     let incorrectAnswers = submittedAnswers.filter(x => !x.isCorrect).length;
        //     let totalAnswers = correctAnswers + incorrectAnswers;

        //     return (
        //         <div>
        //             <p>Challenge complete!</p>
        //             <p>{correctAnswers + " / " + totalAnswers + " = " + (Math.floor((correctAnswers / totalAnswers) * 100)) + "%"}</p>
        //             {challengeCompletedSuccessfully && <p>Challenge completed successfully!</p>}
        //             {!challengeCompletedSuccessfully && <p>Challenge failed!</p>}
        //             <Button onClick={() => resetMcqChallenge()}>Retry</Button>
        //         </div>
        //     )
        // }
    }

    const getSelectionCardClassString = (index: number) => {
        let classString = "border-2 select-none text-xs p-3 m-3 w-20 h-16 ";
        classString += (selectedCodeExerciseIndex === index) ? "border-4 border-black" : " border-gray-300";
        let thisAnswer = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[index].id);
        if (!thisAnswer) {
            classString += " bg-gray-100";
        }
        else if (thisAnswer.status === STATUS.updated) {
            classString += " bg-yellow-200 border-yellow-500 border-2";
        }
        else if (thisAnswer.status === STATUS.submitted || thisAnswer.isSubmitted) {
            classString += " bg-green-200 border-green-500 border-2";
        }
        else if (thisAnswer.status === STATUS.unchanged) {
            classString += " bg-green-200 ";
        }

        return classString;
    }

    //======================================================== Render

    const editCodeExerciseSubmissionSourceCode = (value: string) => {

        let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
        if (!thisCodeExerciseSubmission) {
            console.error("Code exercise submission not found");
            return;
        }
        thisCodeExerciseSubmission.sourceCode = value;
        thisCodeExerciseSubmission.status = STATUS.updated;

        let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        let index = tempCodeExerciseSubmissions.findIndex(x => x.guidRef === thisCodeExerciseSubmission?.guidRef);
        tempCodeExerciseSubmissions[index] = thisCodeExerciseSubmission;
        setCodeExerciseSubmissions(tempCodeExerciseSubmissions);


        // if (thisCodeExerciseSubmission) {
        //     thisCodeExerciseSubmission.sourceCode = value;
        //     thisCodeExerciseSubmission.status = STATUS.updated;

        //     let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        //     let index = tempCodeExerciseSubmissions.findIndex(x => x.guidRef === thisCodeExerciseSubmission?.guidRef);
        //     tempCodeExerciseSubmissions[index] = thisCodeExerciseSubmission;
        //     setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
        // } else {
        //     thisCodeExerciseSubmission = new CodeExerciseSubmission();
        //     thisCodeExerciseSubmission.codeExerciseId = codeExercises[selectedCodeExerciseIndex].id;
        //     thisCodeExerciseSubmission.challengeGuidRef = codeExerciseStoppingPoint.challengeGuidRef;
        //     thisCodeExerciseSubmission.sourceCode = value;
        //     thisCodeExerciseSubmission.status = STATUS.updated;

        //     let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        //     tempCodeExerciseSubmissions.push(thisCodeExerciseSubmission);
        //     setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
        // }

        // if (action === "sourceCode") {
        //     let tempCodeExercises = [...codeExercises];
        //     tempCodeExercises[selectedCodeExerciseIndex].status = STATUS.updated;
        //     tempCodeExercises[selectedCodeExerciseIndex].sourceCode = value;
        //     setCodeExercises(tempCodeExercises);
        // }
    }

    const updateCodeExerciseSubmissions = (updatedCodeExerciseSubmissions: CodeExerciseSubmission[]) => {
        let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
        updatedCodeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            let index = tempCodeExerciseSubmissions.findIndex(x => x.guidRef === codeExerciseSubmission.guidRef);
            tempCodeExerciseSubmissions[index] = codeExerciseSubmission;
        });
        setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
    }

    const getUpdatedCodeExerciseSubmissions = () => {
        let updatedCodeExerciseSubmissions = [] as CodeExerciseSubmission[];
        codeExerciseSubmissions.forEach((codeExerciseSubmission) => {
            if (codeExerciseSubmission.status !== STATUS.unchanged && codeExerciseSubmission.sourceCode !== "") {
                updatedCodeExerciseSubmissions.push(codeExerciseSubmission);
            }
        });
        return updatedCodeExerciseSubmissions;
    }

    // const getUpdatedCodeExerciseSubmissions = () => {
    //     let updatedCodeExerciseSubmissions = [...codeExerciseSubmissions];
    //     let codeExerciseSubmission = updatedCodeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
    //     if (codeExerciseSubmission) {
    //         codeExerciseSubmission.sourceCode = codeExercises[selectedCodeExerciseIndex].sourceCode;
    //         codeExerciseSubmission.status = STATUS.updated;
    //     }
    //     return updatedCodeExerciseSubmissions
    // }

    const saveCodeExerciseSubmission = async () => {
        let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);

        let response = await crudService.update(CLASS.codeExerciseSubmission, [thisCodeExerciseSubmission]);
        if (response.success) {
            updateCodeExerciseSubmissions(response.payload);
        } else {
            errorService.handleError(response);
        }
    }

    const saveAllCodeExerciseSubmissions = async () => {
        let updatedCodeExerciseSubmissions = getUpdatedCodeExerciseSubmissions();
        let response = await crudService.update(CLASS.codeExerciseSubmission, updatedCodeExerciseSubmissions);
        if (response.success) {
            updateCodeExerciseSubmissions(response.payload);
        } else {
            errorService.handleError(response);
        }
    }

    const checkSaveButtonDisabled = () => {
        let disabled = false;
        if (selectedCodeExerciseIndex === -1) {
            disabled = true;
        } else {
            let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
            if (!thisCodeExerciseSubmission) {
                disabled = true;
            } else if (thisCodeExerciseSubmission.status === STATUS.unchanged) {
                disabled = true;
            } else if (thisCodeExerciseSubmission.sourceCode === "") {
                disabled = true;
            }
        }
        return disabled;
    }

    const checkSaveAllButtonDisabled = () => {
        let disabled = true;
        codeExercises.forEach((codeExercise) => {
            let thisCodeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercise.id);
            if (thisCodeExerciseSubmission && thisCodeExerciseSubmission.status !== STATUS.unchanged) {
                disabled = false;
            }
        });
        return disabled;
    }

    const getCodeExerciseSubmission = () => {
        let codeExerciseSubmission = codeExerciseSubmissions.find(x => x.codeExerciseId === codeExercises[selectedCodeExerciseIndex].id);
        if (!codeExerciseSubmission) {

            codeExerciseSubmission = new CodeExerciseSubmission();
            codeExerciseSubmission.codeExerciseId = codeExercises[selectedCodeExerciseIndex].id;
            codeExerciseSubmission.challengeGuidRef = challengeGuidRef;
            codeExerciseSubmission.status = STATUS.new;

            let tempCodeExerciseSubmissions = [...codeExerciseSubmissions];
            tempCodeExerciseSubmissions.push(codeExerciseSubmission);
            setCodeExerciseSubmissions(tempCodeExerciseSubmissions);
        }

        return codeExerciseSubmission
    }

    return (
        <div className="grid grid-cols-1 place-items-center">
            {/* {JSON.stringify(codeExerciseSubmissions)} */}
            <div className="flex gap-2 flex-wrap items-center">
                {codeExercises && codeExercises.map((codeExercise, index) => {
                    return (
                        <div key={index} className={getSelectionCardClassString(index)} onClick={() => setSelectedCodeExerciseIndex(index)}>
                            <div>{index + 1}</div>
                            Diff: {codeExercise.difficulty}
                        </div>
                    )
                })}
            </div>
            <div className="flex gap-4 items-center">
                <Button disabled={checkSaveButtonDisabled()} onClick={() => { saveCodeExerciseSubmission() }}>Save</Button>
                <Button disabled={checkSaveAllButtonDisabled()} onClick={() => { saveAllCodeExerciseSubmissions() }}>Save All</Button>
            </div>
            {selectedCodeExerciseIndex !== -1 && <div>
                <SingleCodeExercise
                    codeExercise={codeExercises[selectedCodeExerciseIndex]}
                    codeExerciseSubmission={getCodeExerciseSubmission()}
                    editCodeExercise={editCodeExerciseSubmissionSourceCode}
                    dueDatePassed={false}
                />
            </div>}

        </div>
    );
}

export default CodeExerciseViewer;