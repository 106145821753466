import {EditablePropertyMetadata} from "./EditablePropertyMetadata";
import {EditablePropertySelectItem} from "./EditablePropertySelectItem";
import { Page } from "./Page";
import {StyleTheme} from "classes/style/StyleTheme";

const generateThemeSelectionList = (allThemes: StyleTheme[]) => {
    let list = [] as EditablePropertySelectItem[];
    for (let i = 0; i < allThemes.length; i++) {
        list.push(new EditablePropertySelectItem(allThemes[i].name, allThemes[i].id));
    }
    return list;
}

const pageProperties = (thisObject: Page, allThemes: StyleTheme[]) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'title', propertyType: 'string', displayName: "Title", value: thisObject? thisObject.title : "New page" });
    editableProperties.push({ propertyName: 'description', propertyType: 'string', displayName: "Description", value: thisObject? thisObject.description : "" });
    editableProperties.push({ propertyName: 'styleThemeId', propertyType: 'select', displayName: "Theme", value: thisObject? thisObject.styleThemeId : "default", dataSource: generateThemeSelectionList(allThemes) });
    editableProperties.push({ propertyName: 'isPublic', propertyType: 'boolean', displayName: "Publicly accessable?", value: thisObject? thisObject.isPublic: true });
    return editableProperties;
}

export default pageProperties;