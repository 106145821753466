
//React

//UI

//Services
import { toast } from "react-toastify";

//Logics

//Components

//Store

//Classes
import { ResponseObject } from "classes/services/ResponseObject";


const handleError = (response: ResponseObject) => {

    if (!response) {
        toast("The server seems to be offline. Please try again later.");
    }

    else if (response.statusCode === 401) {
        console.log("Unauthorized!");
        toast.error("Unauthorized! Please login again.");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        let currentUrl = window.location.href;
        window.location.href = "/accounts/login/?redirect=" + currentUrl;
    }

    else if (response.statusCode === 400) {
        console.log("Bad request!");
        toast("Oops. An error occured. Some data was not configured properly.");
    }

    else if (response.statusCode === 404) {
        console.log("Resource not found! 404 Error");
        toast("Oops. An error occured. What you are looking for does not exist!");
    }

    else if (response.statusCode === 500) {
        console.log("Internal server error");
        toast("Oops. Something is wrong with the server.");
    }
    else {
        console.log("Unknown error");
        toast("Oops. An error occured. Please try again later.");
    }
}


export default {
    handleError
}