//React
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

//UI
import { Button, Tabs, TabsRef } from 'flowbite-react';
import { Row } from 'ui_components/helper/HelperComponents';

//Services
import docXService from 'services/docXService';
import requestImageMediaLog from 'services/mediaLogService';

//Logics
import componentLogic from 'logic/gridslate/genericComponentLogic';
import sanitizeLogic from 'utility/logics/sanitizeLogic';
import styleLogic from 'logic/style/styleLogic';

//Components
import ComponentRenderer from 'views/render/ComponentRendererStatic';
import ComponentOverviewPanel from './panels/ComponentOverviewPanel';
import StyleOverviewPanel from './panels/StyleOverviewPanel';
import ComponentSettingsPanel from './panels/ComponentSettingsPanel';
import Dropzone from 'react-dropzone';

//Store
import { themesAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';
import { STATUS } from 'classes/enums/status';
import { SelectedComponentData } from 'classes/components/SelectedComponentData';
import { Component } from 'classes/components/Component';
import { ImageData } from 'classes/components/ImageData';
import { GridLocation } from 'classes/components/GridLocation';
import { StyleTheme } from 'classes/style/StyleTheme';
import { GridConstructorInterface } from "classes/interfaces/GridConstructorInterface";

//shift + Mousewheel increase fontsize and image size

type Props = {
    parentGridArray: string[][],
    parentComponents: Component[],
    theme: StyleTheme,
}

interface ChildComponentProps {
    parentGridArray: string[][],
    parentComponents: Component[],
    theme: StyleTheme,
}

const GridConstructor = forwardRef<GridConstructorInterface, ChildComponentProps>((props: Props, ref) => {

    const { parentGridArray, parentComponents, theme } = props;

    const [components, setComponents] = useState<Component[]>(parentComponents);
    const [gridArray, setGridArray] = useState<string[][]>(parentGridArray);
    const [currentTheme, setCurrentTheme] = useState<StyleTheme>(theme);

    const [activeTab, setActiveTab] = useState(0);
    const [activeToolPaneTab, setActiveToolPaneTab] = useState(0);
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    //const [currentThemeIndex, setCurrentThemeIndex] = useState<number>(0);

    const [allThemes, setAllThemes] = useAtom(themesAtom);

    const [selectedComponent, setSelectedComponent] = useState<SelectedComponentData>(new SelectedComponentData("", null, 0, 0));

    const mainTabsRef = useRef<TabsRef>(null);

    useImperativeHandle(ref, () => ({
        getComponents: () => { return components },
        getGridArray: () => gridArray,
    }));

    useEffect(() => {
        setComponents(parentComponents);
        setGridArray(parentGridArray);
    }, [parentComponents, parentGridArray]);

    useEffect(() => {
        setCurrentTheme(theme);
    }, [theme]);

    //const { viewHeight, viewWidth, viewBreakpoint } = useWindowDimensions();

    // useEffect(() => {
    //     const handleKeyDown = (event: any) => {
    //         //event.preventDefault();
    //         const code = event.which || event.keyCode;

    //         let charCode = String.fromCharCode(code).toLowerCase();
    //         if ((event.ctrlKey || event.metaKey) && charCode === 's') {
    //             event.preventDefault();
    //             console.log('save');
    //             //setState('CTRL+S');
    //             //alert('CTRL+S Pressed');
    //         } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
    //             console.log('ctrl+c');
    //             //setState('CTRL+C');
    //             //alert('CTRL+C Pressed');
    //         } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
    //             console.log(selectedComponent);
    //             let component = components.find((component) => component.componentRef === selectedComponent.componentRef);
    //             console.log('ctrl+v ' + component?.type);
    //             if (component != undefined &&
    //                 component.type === COMPONENT_TYPE.image) {
    //                 pasteImg();
    //             }
    //             //setState('CTRL+V');
    //             //alert('CTRL+V Pressed');
    //         }
    //     };

    //     window.addEventListener('keydown', handleKeyDown);

    //     return () => window.removeEventListener('keydown', handleKeyDown);
    // }, [selectedComponent]);

    const pasteImg = async () => {
        try {
            const clipboardContents = await navigator.clipboard.read();
            //only get first item
            let clipboardItem = clipboardContents[0];

            for (const mimeType of clipboardItem.types) {

                if (mimeType === "image/png") {
                    const blob = await clipboardItem.getType("image/png");
                    const data = URL.createObjectURL(blob);
                    setImageAttribute(new GridLocation(0, selectedComponent.rowIndex, selectedComponent.colIndex), 'localBlobRef', data)
                } else if (mimeType === "text/html") {
                    const blob = await clipboardItem.getType("text/html");
                    const blobText = await blob.text();
                    console.log(blobText);

                } else if (mimeType === "text/plain") {
                    const blob = await clipboardItem.getType("text/plain");
                    const blobText = await blob.text();
                    console.log(blobText);

                } else {
                    throw new Error(`${mimeType} not supported.`);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const setComponentText = (componentRef: string, value: string) => {
        //update component
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            return;
        }
        let targetComponent = newComponents[componentIndex];
        //could sanitize here
        targetComponent.text = value;
        targetComponent.status = (targetComponent.status === STATUS.new) ? targetComponent.status : STATUS.updated;
        setComponents(newComponents);

    }

    const setComponentRichText = (componentType: COMPONENT_TYPE, componentRef: string, rowIndex: number, colIndex: number) => {
        let element = document.getElementById(componentRef);
        if (element === null) {
            return;
        }
        let innerText = element.innerHTML;

        if (componentType === COMPONENT_TYPE.text) {
            innerText = sanitizeLogic.cleanTextInnerHtml(innerText);

        }
        if (componentType === COMPONENT_TYPE.list) {
            innerText = sanitizeLogic.cleanListInnerHtml(innerText);
        }

        //update component
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        //If component not found or if content unchanged, return
        if (componentIndex === -1 || newComponents[componentIndex].text === innerText) {
            return;
        }
        let targetComponent = newComponents[componentIndex];
        targetComponent.text = innerText;
        targetComponent.status = (targetComponent.status === STATUS.new) ? targetComponent.status : STATUS.updated;
        setComponents(newComponents);
    }

    const setImageAttribute = async (location: GridLocation, targetAttribute: string, value: string, mediaLogId?: string) => {
        console.log("Reached setImageAttribute:", targetAttribute);
        let componentRef = gridArray[location.rowIndex][location.colIndex];
        let componentIndex = components.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            return;
        }
        let targetImage = { ...components[componentIndex] };
        targetImage.data.specialisedData = { ...targetImage.data.specialisedData } as ImageData;

        if (targetImage.type === COMPONENT_TYPE.image) {

            if (targetAttribute === 'src') {
                targetImage.data.specialisedData.src = value;
                targetImage.data.specialisedData.localBlobRef = '';
                targetImage.mediaLogId = mediaLogId ? mediaLogId : '';
                targetImage.subtype = COMPONENT_SUBTYPE.complete;
            }
            if (targetAttribute === 'localBlobRef') {

                //Get dimensions of image
                // let img = new Image();
                // img.src = value;
                // img.onload = () => {
                //     targetImage.width = img.width;
                //     targetImage.height = img.height;
                // }
                const imgBlob = await fetch(value).then(r => r.blob());
                const bmp = await createImageBitmap(imgBlob);
                const { width, height } = bmp;

                targetImage.data.specialisedData.width = width;
                targetImage.data.specialisedData.height = height;
                bmp.close(); // free memory
                targetImage.data.specialisedData.extension = value;

                targetImage.data.specialisedData.localBlobRef = value;
                //requestImageMediaLog(targetImage.data.specialisedData.fileName, targetImage.data.specialisedData.localBlobRef, location, setImageAttribute);
                requestImageMediaLog(targetImage, location, setImageAttribute);
            }
        }

        //update component
        let newComponents = [...components];
        targetImage.status = (targetImage.status === STATUS.new) ? targetImage.status : STATUS.updated;
        newComponents[componentIndex] = targetImage;
        setComponents(newComponents);
    }

    //COMPONENT HANDLERS ========================================

    const persistGridArrayAndComponents = (gridArray: string[][], components: Component[]) => {
        setGridArray(gridArray);
        setComponents(components);
    }

    //Adds Component to Slide gridArray
    const handleInsertColumn = () => {
        componentLogic.insertCol({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleInsertRow = () => {
        componentLogic.insertRow({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleCloneComponent = () => {
        componentLogic.cloneComponent({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleDeleteComponent = () => {
        componentLogic.deleteComponent({ selectedComponent, components, gridArray, persistGridArrayAndComponents });
    }

    const handleElementDrop = (componentType: COMPONENT_TYPE, rowIndex: number, colIndex: number) => {
        //Create element and add to gridArray, replacing the empty element
        let newComponents = [...components];
        let newGridArray = [...gridArray];
        let newComponent;
        newComponent = new Component(componentType);

        //delete the empty component
        let emptyComponentRef = newGridArray[rowIndex][colIndex];
        let emptyComponentIndex = newComponents.findIndex((component) => component.componentRef === emptyComponentRef);
        if (emptyComponentIndex !== -1) {
            newComponents.splice(emptyComponentIndex, 1);
        }
        gridArray[rowIndex][colIndex] = newComponent.componentRef;
        newComponents.push(newComponent);
        setComponents(newComponents);
        setGridArray(newGridArray);

    }

    const handleDoubleClickCreateComponent = (componentType: COMPONENT_TYPE) => {
        componentLogic.handleDoubleClickAddComponent({ selectedComponent, components, gridArray, persistGridArrayAndComponents, componentType });
    }

    //TOOLPANE RENDERERS ========================================

    const GridElementToolpane = () => {
        return (
            <div style={{ position: 'absolute' }}>
                <Button size='xs' className='m-1' onClick={() => handleInsertColumn()}>Add Col</Button>
                <Button size='xs' className='m-1' onClick={() => handleInsertRow()}>Insert Row</Button>
                <Button size='xs' className='m-1' onClick={() => handleCloneComponent()}>Clone</Button>
                <Button size='xs' className='m-1' onClick={() => handleDeleteComponent()}>Del</Button>
            </div>
        )
    }

    //TEST HANDLERS ========================================

    //Set theme or custom
    const setMasterStyleSetting = (componentRef: string, masterStyleSetting: string) => {
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            console.log("No component found");
            return;
        }

        //set component style to current theme style
        let thisTheme = allThemes.find((theme) => theme.id === theme.id);
        if (thisTheme != undefined) {
            newComponents[componentIndex].data.style = styleLogic.getComponentStyleFromTheme(thisTheme, newComponents[componentIndex].type, newComponents[componentIndex].subtype);
        }

        newComponents[componentIndex].data.masterStyleSetting = masterStyleSetting;
        newComponents[componentIndex].status = (newComponents[componentIndex].status === STATUS.new) ? newComponents[componentIndex].status : STATUS.updated;
        setComponents(newComponents);
    }

    //RENDER ========================================

    // const getStyleThemeId = () => {
    //     let themeId = "default";
    //     if (currentThemeIndex !== -1 && allThemes.length > 1 && allThemes[currentThemeIndex]) {
    //         themeId = allThemes[currentThemeIndex].id;
    //     }
    //     return themeId;
    // }

    const handleDocXUpload = async (files: any) => {
        let data = await docXService.handleDocXUpload(files);
        if (data && data.success) {
            setGridArray(data.gridArray);
            setComponents(data.components);
            mainTabsRef.current?.setActiveTab(0)
        }
    }

    const getComponentStyle = (componentRef: string) => {
        let component = components.find((component) => component.componentRef === componentRef);
        if (component) {
            return component.data.style;
        } else {
            return {};
        }
    }

    const setComponentStyle = (componentRef: string, style: {}) => {
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            console.log("No component found");
            return;
        }
        newComponents[componentIndex].data.style = { ...style };
        newComponents[componentIndex].status = (newComponents[componentIndex].status === STATUS.new) ? newComponents[componentIndex].status : STATUS.updated;
        setComponents(newComponents);
    }

    const loseFocusHandler = (componentRef: string, rowIndex: number, colIndex: number) => {
        let element = document.getElementById(componentRef) as HTMLElement;
        try {
            element.blur();
        }
        catch (e) {
        }
    }

    const setComponentSettings = (componentRef: string, settings: {}) => {
        let newComponents = [...components];
        let componentIndex = newComponents.findIndex((component) => component.componentRef === componentRef);
        if (componentIndex === -1) {
            console.log("No component found");
            return;
        }
        setComponents(newComponents);
    }

    const setComponentRendererData = (componentRef: string) => {
        let component = components.find((component) => component.componentRef === componentRef);
        //console.log(component);
        if (component && component.type === COMPONENT_TYPE.pageChallenge) {
            let testChallenges = [];
            testChallenges.push({ "id": "adwdwdawd", "name": "Test 01" });
            testChallenges.push({ "id": "sfesefsef", "name": "Test 02" });
            //console.log(testChallenges);
            return testChallenges;
        }
        return [];
    }

    return (
        <div className='container mx-auto'>
            <Tabs aria-label="Default tabs" ref={mainTabsRef} onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item active title="Constructor">
                </Tabs.Item>

                <Tabs.Item title="Import">
                    <Dropzone onDrop={(acceptedFiles) => setFileToUpload(acceptedFiles)}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div {...getRootProps()} className="flex w-full items-center justify-center h-64 cursor-pointer">
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    {fileToUpload && <p>{fileToUpload[0].name}</p>}

                    <Button onClick={() => handleDocXUpload(fileToUpload)}>Upload</Button>
                </Tabs.Item>
                <Tabs.Item title="View Mode">
                </Tabs.Item>
            </Tabs>

            {/* ===================================================================== Constructor ============================== */}
            {activeTab == 0 && <div>

                <div className='sticky top-0 z-50 bg-white rounded border-neutral-600 border-2'>
                    <Tabs aria-label="Default tabs" onActiveTabChange={(tab) => setActiveToolPaneTab(tab)}>
                        <Tabs.Item active title="Components">
                            <ComponentOverviewPanel
                                selectedType={selectedComponent.component ? selectedComponent.component.type : 'none'}
                                handleDoubleClickCreateComponent={(type: COMPONENT_TYPE) => handleDoubleClickCreateComponent(type)}
                            />
                        </Tabs.Item>
                        <Tabs.Item title="Styles">
                            {selectedComponent.componentRef != "" && <StyleOverviewPanel
                                selectedComponent={selectedComponent}
                                componentType={selectedComponent.component ? selectedComponent.component.type : COMPONENT_TYPE.empty}
                                componentStyle={getComponentStyle(selectedComponent.componentRef)}
                                setComponentStyle={setComponentStyle}
                                currentTheme={theme}
                                setMasterStyleSetting={setMasterStyleSetting}
                                masterStyleSetting={selectedComponent.component ? selectedComponent.component.data.masterStyleSetting : 'custom'}
                            />}
                        </Tabs.Item>
                        <Tabs.Item title="Settings">
                            {selectedComponent.componentRef != "" && <ComponentSettingsPanel
                                selectedComponent={selectedComponent}
                                setComponentSettings={setComponentSettings}
                            />}
                        </Tabs.Item>
                        <Tabs.Item title="Object">
                            GridArray:
                            {<div>{JSON.stringify(gridArray)}</div>}
                            Components:
                            {<div>{JSON.stringify(components)}</div>}
                            Selected Component:
                            {selectedComponent.component && <div>{JSON.stringify(selectedComponent.component)}</div>}
                        </Tabs.Item>
                    </Tabs>

                </div>

                <Row>
                    <div className='container mx-auto'>
                        {/* //Map current Slide gridArry and create a Row and Column for each Component  */}
                        {gridArray && gridArray.map((row, rowIndex) => {
                            return (
                                <div key={"row" + rowIndex} className='grid grid-cols-12'>
                                    <div className='col-span-11'>
                                        <div className='flex gap-2'>
                                            {row.map((componentRef, colIndex) => {
                                                return (
                                                    <div className='flex-1' key={"columns-" + colIndex}>
                                                        <div
                                                            onClick={() => setSelectedComponent(new SelectedComponentData(componentRef, components.find((component) => component.componentRef === componentRef), rowIndex, colIndex))}
                                                            className={selectedComponent.componentRef === componentRef ? 'border-4 border-red-400' : ''}
                                                            onMouseLeave={() => loseFocusHandler(componentRef, rowIndex, colIndex)}
                                                        >
                                                            <ComponentRenderer
                                                                component={components.find((component) => component.componentRef === componentRef) as Component}
                                                                rowIndex={rowIndex}
                                                                colIndex={colIndex}
                                                                setComponentRichText={(componentType: COMPONENT_TYPE) => setComponentRichText(componentType, componentRef, rowIndex, colIndex)}
                                                                setComponentText={setComponentText}
                                                                handleDrop={(componentType: COMPONENT_TYPE, rowIndex: number, colIndex: number) => handleElementDrop(componentType, rowIndex, colIndex)}
                                                                setImageAttribute={(targetAttribute: string, value: string) => setImageAttribute(new GridLocation(0, rowIndex, colIndex), targetAttribute, value)}
                                                                styleTheme={theme}
                                                                editingEnabled={true}
                                                                data={setComponentRendererData(componentRef)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className=''>
                                        {selectedComponent.rowIndex === rowIndex && <GridElementToolpane />}
                                    </div>
                                </div>
                            )
                        })}

                        <Row className='justify-center'>
                            <Button className='my-6' onClick={() => {
                                componentLogic.addRow({ selectedComponent, components, gridArray, persistGridArrayAndComponents })
                            }}>Add Row</Button>
                        </Row>
                    </div>

                </Row>
            </div>}

            {/* ===================================================================== View Mode ============================== */}

            {activeTab == 4 && <div className='container mx-auto'>

                <div>
                    {gridArray && gridArray.map((row, rowIndex) => {
                        return (
                            <div key={"row" + rowIndex}>
                                {row.map((col, colIndex) => {
                                    return (
                                        <div className={'flex-1'} key={"columns-" + colIndex}>
                                            <ComponentRenderer
                                                component={components.find((component) => component.componentRef === col) as Component}
                                                rowIndex={rowIndex}
                                                colIndex={colIndex}
                                                setComponentRichText={() => { }}
                                                setComponentText={() => { }}
                                                handleDrop={() => { }}
                                                setImageAttribute={() => { }}
                                                styleTheme={theme}
                                                editingEnabled={false}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}
)

export default GridConstructor;