import {Mcq} from "classes/mcq/Mcq";
import {McqAnswer} from "classes/mcq/McqAnswer";

const parseAIResponseToMcqs = (inputString: string) => {

    // Split the input string into lines
    let lines = inputString.trim().split('\n');
    
    //trim the lines
    lines.forEach((line, index) => {
        lines[index] = line.trim();
    });

    //let questions : string[];
    let currentQuestion: Mcq | null = null;

    let tempMcqs: Mcq[] = [];

    // Process each line
    lines.forEach(line => {
        // Check if the line starts with a question identifier (e.g., Q1:, Q2:, Q3:)
       
        //if (line.match(/^Q\d+: /)) {
        if(line[0] === 'Q') {
            // If we have a previous question, push it to the questions array
            if (currentQuestion !== null) {
                tempMcqs.push(currentQuestion);
            }

            // Create a new question object
            currentQuestion = new Mcq();
            //currentQuestion.questionText = line.replace(/^Q\d+: /, '').trim();
            currentQuestion.questionText = line.substring(line.indexOf(":") + 1).trim();
            currentQuestion.answers = [];
        } else if (currentQuestion !== null) {
            // If it's not a question identifier and we are inside a question
            // Check if it starts with A followed by a digit (A1:, A2:, A3:, A4:)
            // if (line.match(/^A\d+: /)) {
            if (line[0] === 'A') {
                let answer = new McqAnswer();
                //answer.answerText = line.replace(/^A\d+: /, '').trim();
                answer.answerText = line.substring(line.indexOf(":") + 1).trim();
                //Set first answer as correct
                if (currentQuestion.answers.length === 0) {
                    answer.isCorrect = true;
                }
                //console.log(answer);
                currentQuestion.answers.push(answer);
            }

        }
    });

    // Push the last question into the questions array
    if (currentQuestion !== null) {
        tempMcqs.push(currentQuestion);
    }

    //console.log(tempMcqs);
    return tempMcqs;
}

const returnPrompt = (numberOfQuestionsToGenerate: string, difficultySetting: string, mcqAIContentForPrompt: string) => {

    let diffFormatInstructionPrompt =
        "Generate a set of multiple choice questions. Each question should have 4 answers." +
        'Generate the questions in the following format: Q1:question text\n A1:answer1\n A2:answer2\n A3:answer3\n A4:answer4\n' +
        "The correct answer should always be the first answer in the list of answers.";


    let basicInstructionPrompt =
        "Generate a set of multiple choice questions. Each question should have 4 answers." +
        'Generate the questions in the following format: [{"q": "question text", "a": ["answer1", "answer2", "answer3", "answer4"]}]' +
        "The correct answer should always be the first answer in the list of answers. The response will be parsed to JSON, so make sure the response is in the correct format.";

    let contentPrompt = "The topic of the questions is javascript coding and all the questions should be based on the following content: ";

    let numberOfQuestionsPrompt = "Finally, make a set of " + numberOfQuestionsToGenerate + " questions.";

    let difficultyPrompt = "The questions should be of difficulty level " + difficultySetting + " on a scale of 1 to 3, with 1 being the easiest and 3 the most difficult.";

    let prompt = diffFormatInstructionPrompt + contentPrompt + mcqAIContentForPrompt + numberOfQuestionsPrompt + difficultyPrompt;
    return prompt;
}

export default { returnPrompt, parseAIResponseToMcqs };