//React
import { useEffect, useState } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//UI
import 'react-toastify/dist/ReactToastify.css';

//Services
import { ToastContainer } from 'react-toastify';
import useS3Fetch from 'services/useS3Fetch';

//Logics
import UserLogic from 'utility/UserLogic';
import routeLogic from 'logic/io/S3RouteLogic';

//Components
import ViewPage from 'views/page/ViewPage';
import Test from 'views/test/Test';
import GridslateNavbar from "app_gridslate/views/GridslateNavbar";
import Login from 'views/accounts/Login';
import Logout from 'views/accounts/Logout';
import GridslateConstructor from 'app_gridslate/constructor/PageConstructor';
import NavbarConstructor from 'app_gridslate/constructor/NavbarConstructor';
import Home from 'app_gridslate/views/Home';
import NotFound from 'views/routes/NotFound'
import PageChallengeConstructor from 'app_gridslate/constructor/PageChallengeConstructor';
import PageChallengeViewer2 from 'app_gridslate/constructor/PageChallengeViewer2';
import ChallengeConstructor from 'app_gridslate/constructor/dynamic/ChallengeConstructor';

//Classes
import { NavbarElement } from 'classes/navbar/NavbarElement';
import { NavbarChild } from 'classes/navbar/NavbarChild';
import { NavbarClass } from 'classes/navbar/Navbar';

function App() {

  const { payload: navbarS3, isPending: isPendingNavbar, error: errorNavbar } = useS3Fetch(routeLogic.GetNavbarRoute());
  const [navbar, setNavbar] = useState<NavbarClass>();

  useEffect(() => {
    setNavbar(navbarS3 as unknown as NavbarClass);
  }, [navbarS3]);

  return (
    <div>

      <Router>
        <UserLogic />
        <ToastContainer />
        <GridslateNavbar navbar={navbar} />
        <Routes>

          {/* ==================== DYNAMIC routes, based on navbar.json */}

          {navbar && navbar.elements.map((topLevelElement: NavbarElement, index: number) => (
            <React.Fragment key={'navroute_top_' + index}>
              {topLevelElement.children.length === 0 && <Route path={topLevelElement.route} element={<ViewPage pageRef={topLevelElement.pageRef} pageId={topLevelElement.pageId} />} />}
              {topLevelElement.children.length > 0 && topLevelElement.children.map((child: NavbarChild, index2: number) => (
                <Route key={'navroute_child_' + index2} path={child.route} element={<ViewPage pageRef={child.pageRef} pageId={child.pageId} />} />
              ))}
            </React.Fragment>
          )
          )}

          {/* ==================== CUSTOM routes */}

          <Route path="/" element={<Home />} />
          <Route path="/test" element={<Test />} />
          <Route path="/gridslate/constructor" element={<GridslateConstructor />} />
          <Route path="/gridslate/navbarconstructor" element={<NavbarConstructor />} />
          <Route path="/gridslate/challengeconstructor" element={<ChallengeConstructor />} />
          <Route path="/gridslate/textchallengeconstructor" element={<PageChallengeConstructor />} />

          {/* ==================== Textchallenge routes */}

          <Route path="/pagechallenge/:pageChallengeId" element={<PageChallengeViewer2 />} />

          {/* ==================== ACCOUNT routes */}

          {/* <Route path="/accounts/myaccount" element={<MyAccount />} /> */}
          <Route path="/accounts/login" element={<Login />} />
          <Route path="/accounts/logout" element={<Logout />} />

          <Route path="*" element={<NotFound />} />
          {/* <Route path="*" element={null} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;