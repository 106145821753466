//React
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//UI
import { Button, TextInput, Label, Checkbox, Alert } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";

//Services
import config from 'config';
import apiService from "services/apiService";
import { decodeToken } from "services/tokenService";
import errorService from "services/errorService";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { GoogleOAuthProvider } from '@react-oauth/google';

//Logics

//Components

//Store
import { userAtom } from 'atom';
import { useAtom } from 'jotai';

//Classes
import { User } from 'classes/accounts/User';
import { GoogleRegisterAccount } from "classes/accounts/GoogleRegisterAccount";

const Login = () => {

  const navigate = useNavigate();
  const [user, setUser] = useAtom(userAtom);

  //const thing = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImEzYjc2MmY4NzFjZGIzYmFlMDA0NGM2NDk2MjJmYzEzOTZlZGEzZTMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI5MDczNzg2NDI1MzUtdmQybDdkZGFzcGZsMml0dDBuZ2ttZDk5Y3VyODJscHUuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI5MDczNzg2NDI1MzUtdmQybDdkZGFzcGZsMml0dDBuZ2ttZDk5Y3VyODJscHUuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTY5NjYxNzE3NjI2MTM2NjM5MDIiLCJlbWFpbCI6ImphbWVzLmRhbmllbHNlbkBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE1NDEyODgxLCJuYW1lIjoiSmFtZXMgRGFuaWVsc2VuIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0xBOU1EcHlyTW8ySnJ4dThoMThJV1cyQWpDWE14REJ1R2xUT0RpcDJCZDRYa0d6Zz1zOTYtYyIsImdpdmVuX25hbWUiOiJKYW1lcyIsImZhbWlseV9uYW1lIjoiRGFuaWVsc2VuIiwiaWF0IjoxNzE1NDEzMTgxLCJleHAiOjE3MTU0MTY3ODEsImp0aSI6Ijc5NGRlY2ZjZjE2Mzc2MmRjMmY5ZmY5OGIwZGU2NDA1YTk1NGMxZDUifQ.nLIrWkfbjziN3qj_zmF5__bTto6-ZX40n9_f8yMdDhcILX3GliHg9Ao-xklCD4alGLjs24PeieV3gpfSw2y2djoKTgslVm4S-AXJMFZQWojhntMgO3RQs3MeQfKGVWlSJND-fzccKhDXuMECBJQXpjsZhItBM2y5mGmVCO6BeE4sFjbzpSzdomgy82LuE7yJ2DK-Xg0ZWjmYAaR9_9fY1h_P5U36LwHX5TXfqhRz8wjYud1gZVHWm_7yjwHS8GukdYYeGfbU77XgvU8K2aVPVT2P7iYKlrpVg3fySqsaIQolIkcOq17HOc2APv9Dp-NhLQW1J6UX5wReodYyBC0VlQ-W89vNtjg';

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [isGooglePending, setIsGooglePending] = useState(false);
  //const [showAccountCreationModal, setShowAccountCreationModal] = useState(false);
  const [googleUser, setGoogleUser] = useState<null | any>(null);
  const [adminLogin, setAdminLogin] = useState(false);
 
  //let { redirect } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get('redirect');

  const handleLogin = async (e: any, username: string, password: string) => {
    //e : React.MouseEvent<HTMLButtonElement>
    if (e !== null) e.preventDefault();

    setIsPending(true);

    await apiService.post(config.apiUrl + '/accounts/signin', { "Username": username, "Password": password })

      .then((response) => {
        console.log(response.payload);
        if (response.success) {

          console.log(response.payload.accessToken);

          localStorage.setItem("accessToken", response.payload.accessToken);
          localStorage.setItem("refreshToken", response.payload.refreshToken);
          localStorage.setItem("expirationTime", response.payload.expirationTime);

          let decodedToken = decodeToken();
          if (decodedToken !== null) {
            let newUser = new User();
            newUser = newUser.setUserFromToken(decodedToken);
            setUser(newUser);
          }

          setIsPending(false);
          if (redirect) {
            navigate(-1);
          } else {
            navigate("/");
          }

        } else {
          errorService.handleError(response);
          setPassword('');
          setIsPending(false);
        }
      });

  };

  const handleGoogleLoginSuccess = async (response: any) => {
    let googleCredentialString = response.credential;
    let googleUser = jwtDecode(response.credential) as any;
    setGoogleUser(googleUser);
    let email = "";
    let password = "";
    let fullname = "";
    try {
      email = googleUser.email as string;
      password = googleUser.sub as string;
      fullname = googleUser.name as string;
    } catch (error) {
      console.log(error);
      toast.error('Error logging in with Google');
      return;
    }

    if (!email.includes('@woosong.org') && !email.includes('@wsu.ac.kr')) {
      toast.error('Only @woosong.org or @wsu.ac.kr email addresses are allowed.');
      return;
    }

    setIsGooglePending(true);
    //let username = googleUser.email as string;
    //let password = googleUser.sub as string;

    //Try to login
    await apiService.post(config.apiUrl + '/accounts/signin', { "Username": email, "Password": password })
      .then((response) => {
        console.log(response.payload);
        if (response.success) {

          console.log(response.payload.accessToken);

          localStorage.setItem("accessToken", response.payload.accessToken);
          localStorage.setItem("refreshToken", response.payload.refreshToken);
          localStorage.setItem("expirationTime", response.payload.expirationTime);

          let decodedToken = decodeToken();
          if (decodedToken !== null) {
            let newUser = new User();
            newUser = newUser.setUserFromToken(decodedToken);
            newUser.initials = newUser.getInitials(newUser.fullname);
            setUser(newUser);
          }

          if (redirect) {
            navigate(-1);
          } else {
            navigate("/");
          }

        } else {
          //TODO: serverside check if the account is linked to google
          createAccountViaGoogle(email, password, fullname, googleCredentialString);
        }
      });

  };

  const createAccountViaGoogle = async (email: string, password: string, fullname: string, googleCredentialString: string) => {
    //let request = { "Username": username, "Password": password, "Email": username, "Code": googleCredentialString };
    let request = new GoogleRegisterAccount(email, password, fullname, googleCredentialString );
    await apiService.post(config.apiUrl + '/accounts/RegisterGoogleLinkedAccount', request)
      .then((response) => {
        console.log(response.payload);
        if (response.success) {
          toast.success('Account created successfully. Logging in...');
          handleLogin(null, email, password)
        } else {
          //errorService.handleError(response);
          toast.error("There was error logging in with Google. Please refresh and try again.");
          setIsGooglePending(false);
        }

      });
  }

  const errorMessage = (error: any) => {
    console.log(error);
  };

  // const renderAccountCreationModal = () => {
  //   return (
  //     <Modal size="7xl" show={showAccountCreationModal} onClose={() => setShowAccountCreationModal(false)}>
  //       <Modal.Header>Edit Word Flash Set</Modal.Header>
  //       <Modal.Body >

  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={() => setShowAccountCreationModal(false)}>Cancel</Button>
  //         <Button color='success' disabled={true} onClick={() => handleCreateAccount()}>Create Account</Button>
  //       </Modal.Footer>
  //     </Modal>
  //   )
  // }

  return (

    <div className='container mx-auto'>
      {/* <div style={{ width: "5px", height: "5px", backgroundColor: serverIsOnline ? "green" : "red" }}></div> */}
      <div className='float-right ml-6'>
        <Button className="text-3xl font-bold text-center" onClick={() => setAdminLogin(!adminLogin)}>Admin Login</Button>
      </div>
      {adminLogin && <div>
        <form className="flex max-w-md flex-col gap-4 my-6 m-auto">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="email1" value="Your email" />
            </div>
            <TextInput id="email1" type="email" placeholder="Your username" required value={username}
              onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="password1" value="Your password" />
            </div>
            <TextInput id="password1" type="password" required value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div className="flex items-center gap-2">
            <Checkbox id="remember" />
            <Label htmlFor="remember">Remember me</Label>
          </div>
          <Button type="submit" onClick={!isPending ? (e: any) => handleLogin(e, username, password) : () => { }} disabled={isPending}
          >
            {isPending ? 'Signing in…' : 'Sign in'}
          </Button>
        </form>
      </div>}

      {!adminLogin && <div className='mr-6'>
        
        <Alert color="success" icon={HiInformationCircle} className='my-6 text-xl'>
          <span className="font-large">Info alert!</span> Currently, it is only possible to sign in/sign up with Google. Please use the button below. Only an <b>@woosong.org</b> or <b>@wsu.ac.kr</b> address is allowed.
        </Alert>
        {isGooglePending && <div>
          <h1 className='text-3xl mt-8 text-center'>Logging in with Google...</h1>
          <img className='animate-spin m-auto w-[32] h-[32]' src={require('assets/images/synapp.png')}></img>
        </div>}
        <div>
        <GoogleOAuthProvider clientId="462651366064-ubnvq1oflp8l8ja12t7hpq6odclfamtl.apps.googleusercontent.com">
          {!isGooglePending && <div className='my-6 w-max m-auto'>
            <GoogleLogin onSuccess={handleGoogleLoginSuccess} onError={() => errorMessage('error')} />
          </div>
          }
        </GoogleOAuthProvider>
        </div>
      </div>}

      {/* <Button onClick={() => setShowAccountCreationModal(true)}>Create account</Button> */}

      {/* {renderAccountCreationModal()} */}
      {/* <Button onClick={() => login()}>Sign in with Google 🚀</Button>; */}

      {/* {JSON.stringify(jwtDecode(thing))} */}
    </div>

  );

}

export default Login;