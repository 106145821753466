//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import { COMPONENT_TYPE } from 'classes/enums/component-types';
import { StyleSetting } from 'classes/style/StyleSetting';
import { StyleItemOption } from 'classes/style/StyleItemOption';
import { StyleColorOption } from 'classes/style/StyleColorOption';
import { ThemeColor } from 'classes/style/ThemeColor';
import { StyleTheme } from 'classes/style/StyleTheme';
import { COMPONENT_SUBTYPE } from 'classes/enums/component-subtypes';
import { ComponentStylePair } from 'classes/style/ComponentStylePair';

type TextAlignProperty = 'center' | 'justify' | 'left' | 'right' | 'start' | 'end' | 'match-parent' | 'inherit' | 'initial' | 'unset';

const mobileDivisor = 0.7;

const returnBasicColorRanges = () => {
    let colorRanges = [] as StyleColorOption[];
    colorRanges.push(new StyleColorOption("black", "#000000"));
    colorRanges.push(new StyleColorOption("white", "#ffffff"));
    colorRanges.push(new StyleColorOption("gray", "#d1d5db"));
    colorRanges.push(new StyleColorOption("red", "#ef4444"));
    colorRanges.push(new StyleColorOption("yellow", "#f59e0b"));
    colorRanges.push(new StyleColorOption("green", "#10b981"));
    colorRanges.push(new StyleColorOption("blue", "#3b82f6"));
    colorRanges.push(new StyleColorOption("indigo", "#6366f1"));
    colorRanges.push(new StyleColorOption("purple", "#8b5cf6"));
    colorRanges.push(new StyleColorOption("pink", "#ec4899"));
    return colorRanges;
}

//New format that might make returnStyleItemOptions obsolete
const returnAllStyleOptions = () => {
    let allStyleOptions: { [key: string]: StyleItemOption } = {};
    allStyleOptions["fontFamily"] = new StyleItemOption("fontFamily", ['Arial', 'Verdana', 'Tahoma', 'Trebuchet', 'Times New Roman', 'Georgia', 'Garamond', 'Courier New', 'Brush Script MT', '"Fira code", "Fira Mono", monospace'], "Arial", "select");
    allStyleOptions["fontSize"] = new StyleItemOption("fontSize", ['8', '100', '2'], "12", "number");
    allStyleOptions["color"] = new StyleItemOption("color", [], "#000000", "colorpicker");
    allStyleOptions["background"] = new StyleItemOption("background", [], "#ffffff", "colorpicker");
    allStyleOptions["bold"] = new StyleItemOption("bold", ["none", "bold"], "none", "click");
    allStyleOptions["italics"] = new StyleItemOption("italics", ["none", "italic"], "none", "click");
    allStyleOptions["underline"] = new StyleItemOption("underline", ["none", "underline"], "none", "click");
    allStyleOptions["alignment"] = new StyleItemOption("alignment", ["left", "center", "justify", "right"], "none", "clickthrough");
    allStyleOptions["margin"] = new StyleItemOption("margin", ["0", "64", "2"], "04040404", "4dslider");
    allStyleOptions["padding"] = new StyleItemOption("padding", ["0", "64", "2"], "04040404", "4dslider");
    allStyleOptions["border"] = new StyleItemOption("border", ["0", "1", "2", "3", "4"], "0", "slider");
    allStyleOptions["borderColor"] = new StyleItemOption("borderColor", [], "#000000", "colorpicker");
    allStyleOptions["borderType"] = new StyleItemOption("borderType", ["solid", "dotted", "dashed", "double", "groove", "ridge", "inset", "outset", "none"], "solid", "select");
    allStyleOptions["width"] = new StyleItemOption("width", ["fit", "auto", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], "fit", "slider");
    allStyleOptions["height"] = new StyleItemOption("height", ["fit", "1", "2", "3", "4", "5", "6"], "fit", "slider");
    allStyleOptions["listBulletType"] = new StyleItemOption("listStyleType", ["none", "disc", "circle", "square"], "none", "select");
    allStyleOptions["listType"] = new StyleItemOption("listType", ["unordered", "ordered"], "unordered", "select");
    allStyleOptions["listNumberType"] = new StyleItemOption("listNumberType", ["decimal", "lower-alpha", "upper-alpha", "lower-roman", "upper-roman"], "decimal", "select");
    allStyleOptions["dropShadow"] = new StyleItemOption("dropShadow", ["none", "sm", "md", "lg", "xl"], "none", "select");
    return allStyleOptions;
}

//Only certain style options are available for each component type
//This will now include the default values
const returnStyleOptionsForComponentType = (type: COMPONENT_TYPE, subtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) => {
    let allStyleOptions = returnAllStyleOptions();
    let styleOptions = [] as StyleItemOption[];
    switch (type) {
        case COMPONENT_TYPE.title:
            styleOptions.push(allStyleOptions["fontFamily"]); //Arial
            styleOptions.push(allStyleOptions["fontSize"].setDefault('48')); //32
            styleOptions.push(allStyleOptions["color"].setDefault('col-p1'));
            styleOptions.push(allStyleOptions["background"]);
            styleOptions.push(allStyleOptions["bold"].setDefault('bold'));
            styleOptions.push(allStyleOptions["italics"]);
            styleOptions.push(allStyleOptions["underline"].setDefault('underline'));
            styleOptions.push(allStyleOptions["alignment"].setDefault('center'));
            styleOptions.push(allStyleOptions["margin"].setDefault('08080808'));

            break;
        case COMPONENT_TYPE.heading:
            styleOptions.push(allStyleOptions["fontFamily"]); //Arial
            if (subtype === COMPONENT_SUBTYPE.type1 || subtype === COMPONENT_SUBTYPE.none) {
                styleOptions.push(allStyleOptions["fontSize"].setDefault('32')); //24
            }
            if (subtype === COMPONENT_SUBTYPE.type2) {
                styleOptions.push(allStyleOptions["fontSize"].setDefault('29')); //18
            }
            if (subtype === COMPONENT_SUBTYPE.type3) {
                styleOptions.push(allStyleOptions["fontSize"].setDefault('26')); //14
            }
            if (subtype === COMPONENT_SUBTYPE.type4) {
                styleOptions.push(allStyleOptions["fontSize"].setDefault('23')); //12
            }
            if (subtype === COMPONENT_SUBTYPE.type5) {
                styleOptions.push(allStyleOptions["fontSize"].setDefault('20')); //10
            }
            styleOptions.push(allStyleOptions["color"].setDefault('col-p2'));
            styleOptions.push(allStyleOptions["background"]);
            styleOptions.push(allStyleOptions["bold"]);
            styleOptions.push(allStyleOptions["italics"].setDefault('italic'));
            styleOptions.push(allStyleOptions["underline"]);
            styleOptions.push(allStyleOptions["alignment"]);
            styleOptions.push(allStyleOptions["margin"].setDefault('06060606'));


            break;
        case COMPONENT_TYPE.text:
            styleOptions.push(allStyleOptions["fontFamily"]);
            styleOptions.push(allStyleOptions["fontSize"].setDefault('18'));
            styleOptions.push(allStyleOptions["color"].setDefault('col-p2'));
            styleOptions.push(allStyleOptions["background"]);
            // styleOptions.push(allStyleOptions["bold"]);
            // styleOptions.push(allStyleOptions["italics"]);
            // styleOptions.push(allStyleOptions["underline"]);
            styleOptions.push(allStyleOptions["alignment"]);
            styleOptions.push(allStyleOptions["margin"]);
            //styleOptions.push(allStyleOptions["padding"]);   
            break;
        case COMPONENT_TYPE.image:
            styleOptions.push(allStyleOptions["width"].setDefault('auto'));
            styleOptions.push(allStyleOptions["height"]);
            styleOptions.push(allStyleOptions["margin"]);
            styleOptions.push(allStyleOptions["border"]);
            styleOptions.push(allStyleOptions["dropShadow"]);
            break;
        case COMPONENT_TYPE.list:
            styleOptions.push(allStyleOptions["fontFamily"]);
            styleOptions.push(allStyleOptions["fontSize"].setDefault('18'));
            styleOptions.push(allStyleOptions["color"].setDefault('col-p2'));
            styleOptions.push(allStyleOptions["background"]);
            styleOptions.push(allStyleOptions["alignment"]);
            styleOptions.push(allStyleOptions["margin"]);
            // styleOptions.push(allStyleOptions["padding"]);
            styleOptions.push(allStyleOptions["listBulletType"]);
            styleOptions.push(allStyleOptions["listType"]);
            styleOptions.push(allStyleOptions["listNumberType"]);
            break;
        case COMPONENT_TYPE.codeblock:
            styleOptions.push(allStyleOptions["fontFamily"].setDefault('"Fira code", "Fira Mono", monospace'));
            styleOptions.push(allStyleOptions["fontSize"].setDefault('18'));
            styleOptions.push(allStyleOptions["color"]);
            styleOptions.push(allStyleOptions["background"].setDefault('#f3f4f6'));
            styleOptions.push(allStyleOptions["margin"]);
            break;
        default:
            break;
    }
    return styleOptions;
}

//New format that might make returnDefaultStyle obsolete
const returnDefaultsForComponentType = (type: COMPONENT_TYPE, subtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) => {
    let styleOptions = returnStyleOptionsForComponentType(type, subtype);
    let style: StyleSetting = {};

    for (let i = 0; i < styleOptions.length; i++) {
        style[styleOptions[i].name] = styleOptions[i].defaultValue;
    }
    return style;
}

//Default styles on creation of a new component
//TODO: Change when themes are implemented
const setDefaultStyle = (type: COMPONENT_TYPE) => {
    let style: StyleSetting = {};
    if (type === COMPONENT_TYPE.title) {

        style['fontFamily'] = 'Arial';
        style['fontSize'] = '24';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'bold';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.heading) {
        style['fontFamily'] = 'Arial';
        style['fontSize'] = '16';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'bold';
        style['underline'] = 'underline';
        style['italics'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.text) {
        style['fontFamily'] = 'Arial';
        style['fontSize'] = '18';
        style['background'] = 'none';
        style['color'] = '#000000';
        style['bold'] = 'none';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'left';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.empty) {
        style['fontSize'] = '12';
        style['color'] = '#000000';
        style['background'] = 'lightgray';
        style['bold'] = 'none';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'left';
        style['margin'] = '0';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.image) {
        style['fontSize'] = '10';
        style['color'] = '#000000';
        style['background'] = 'none';
        style['bold'] = 'none';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'center';
        style['margin'] = '0';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = '11/12';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.list) {
        style['fontFamily'] = 'Arial';
        style['fontSize'] = '18';
        style['background'] = 'none';
        style['color'] = '#000000';
        style['bold'] = 'none';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'left';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }
    if (type === COMPONENT_TYPE.codeblock) {
        style['fontFamily'] = 'Arial';
        style['fontSize'] = '18';
        style['background'] = 'none';
        style['color'] = '#000000';
        style['bold'] = 'none';
        style['underline'] = 'none';
        style['italics'] = 'none';
        style['alignment'] = 'left';
        style['margin'] = '3';
        style['padding'] = '0';
        style['border'] = '0';
        style['width'] = 'fit';
        style['height'] = 'fit';
    }

    return style;
}

const parseThemeColor = (className: string, themeColors: ThemeColor[]) => {
    //find the theme color by className, then return the value
    //if not found return black
    let themeColor = '#000000';
    themeColors.forEach((theme) => {
        if (theme.className === className) {
            themeColor = theme.value;
        }
    });
    return themeColor;
}

//Primary function to parse style settings into a string
const parseStyle = (style: StyleSetting, breakpoint: string, themeColors: ThemeColor[]) => {
    let mobile = (breakpoint === 'sm' || breakpoint === 'md') ? true : false;

    let styleString = { transition: 'color: 2s', fontFamily: '', fontSize: '', color: '', background: '', fontWeight: '', textDecoration: '', fontStyle: '', margin: '', padding: '', borderWidth: '', borderColor: '', width: '', height: '', textAlign: '' as TextAlignProperty, listStyleType: '' };

    if (style === undefined || style === null) {
        console.error("Style is undefined or null");
        return styleString;
    }

    for (const [key, value] of Object.entries(style)) {
        if (key === 'fontFamily') {
            styleString.fontFamily = value;
        }
        if (key === 'fontSize') {
            if (!mobile) {
                styleString.fontSize = value + 'px';
            } else {
                styleString.fontSize = (parseInt(value) * mobileDivisor) + 'px';
            }
        }
        if (key === 'color') {
            if (value.substring(0, 3) === 'col') {
                styleString.color = parseThemeColor(value, themeColors);
            } else {
                styleString.color = value;
            }
        }
        if (key === 'background' && value != 'none') {
            //styleString.background = value;
            if (value.substring(0, 3) === 'col') {
                styleString.background = parseThemeColor(value, themeColors);
            } else {
                styleString.background = value;
            }
        }
        if (key === 'bold' && value === 'bold') {
            styleString.fontWeight = 'bold';
        }
        if (key === 'underline' && value != 'none') {
            styleString.textDecoration = value;
        }
        if (key === 'italics' && value === 'italic') {
            styleString.fontStyle = 'italic';
        }
        if (key === 'alignment') {
            styleString.textAlign = value as TextAlignProperty;
        }
        if (key === 'margin') {
            styleString.margin = value + 'px';
            //margin is a string of 8 characters, each representing a side starting from top and going clockwise
            styleString.margin = value.substring(0, 2) + 'px ' + value.substring(2, 4) + 'px ' + value.substring(4, 6) + 'px ' + value.substring(6, 8) + 'px';

        }
        if (key === 'padding') {
            //styleString.padding = value + 'px';
            styleString.padding = value.substring(0, 2) + 'px ' + value.substring(2, 4) + 'px ' + value.substring(4, 6) + 'px ' + value.substring(6, 8) + 'px';
        }
        if (key === 'border') {
            styleString.borderWidth = value + 'px';
        }
        if (key === 'borderColor') {
            //styleString.borderColor = value;
            if (value.substring(0, 3) === 'col') {
                styleString.borderColor = parseThemeColor(value, themeColors);
            } else {
                styleString.borderColor = value;
            }
        }
        if (key === 'width') {
            if (value === 'fit') {
                styleString.width = '100%';
            } else if (value === 'auto') {
                styleString.width = 'auto';
            } else {
                styleString.width = (100 * (parseInt(value) / 12)) + '%';
            }
        }
        if (key === 'height') {
            if (value === 'fit') {
                styleString.height = 'auto';
            } else {
                styleString.height = (100 * (parseInt(value) / 6)) + '%';
            }
        }
    }
    return styleString;
}

const returnDefaultThemeColors = () => {
    return [
        new ThemeColor('Primary 1', 'col-p1', '#44AA00'),
        new ThemeColor('Primary 2', 'col-p2', '#334E58'),
        new ThemeColor('Secondary 1', 'col-s1', '#7C98B3'),
        new ThemeColor('Secondary 2', 'col-s2', '#ACCBE1'),
        new ThemeColor('Tertiary', 'col-t1', '#DCE8EE'),
        new ThemeColor('Background', 'col-bg1', '#FFFFFF'),
    ];
}

const returnDefaultStyleTheme = (name: string = "Default", type: string = "page", websiteId: string = "") => {
    let newStyleTheme = new StyleTheme(type, websiteId);
    newStyleTheme.name = name;
    newStyleTheme.data.themeColors = returnDefaultThemeColors();

    newStyleTheme.data.componentPairs = [
        new ComponentStylePair('Title', returnDefaultsForComponentType(COMPONENT_TYPE.title), COMPONENT_TYPE.title),
        new ComponentStylePair('Heading 1', returnDefaultsForComponentType(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type1), COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type1),
        new ComponentStylePair('Heading 2', returnDefaultsForComponentType(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type2), COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type2),
        new ComponentStylePair('Heading 3', returnDefaultsForComponentType(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type3), COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type3),
        new ComponentStylePair('Heading 4', returnDefaultsForComponentType(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type4), COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type4),
        new ComponentStylePair('Heading 5', returnDefaultsForComponentType(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type5), COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type5),
        new ComponentStylePair('Text', returnDefaultsForComponentType(COMPONENT_TYPE.text, COMPONENT_SUBTYPE.rich), COMPONENT_TYPE.text, COMPONENT_SUBTYPE.rich),
        new ComponentStylePair('Image', returnDefaultsForComponentType(COMPONENT_TYPE.image), COMPONENT_TYPE.image),
        new ComponentStylePair('UnorderedList', returnDefaultsForComponentType(COMPONENT_TYPE.list, COMPONENT_SUBTYPE.unordered), COMPONENT_TYPE.list, COMPONENT_SUBTYPE.unordered),
        new ComponentStylePair('OrderedList', returnDefaultsForComponentType(COMPONENT_TYPE.list, COMPONENT_SUBTYPE.ordered), COMPONENT_TYPE.list, COMPONENT_SUBTYPE.ordered),
        new ComponentStylePair('Codeblock', returnDefaultsForComponentType(COMPONENT_TYPE.codeblock), COMPONENT_TYPE.codeblock)
    ];

    return newStyleTheme;
}

const getComponentStyleFromTheme = (styleTheme: StyleTheme, type: COMPONENT_TYPE, subtype: COMPONENT_SUBTYPE = COMPONENT_SUBTYPE.none) => {
    let style = {} as StyleSetting;

    let componentPairs = styleTheme.data.componentPairs;

    for (let i = 0; i < componentPairs.length; i++) {
        if (componentPairs[i].type === type && componentPairs[i].subtype === subtype) {
            return componentPairs[i].styleSetting;
        } else {
            //console.log("Not found in theme: " + type + " " + subtype + " returning default style");
        }
    }
    return style;
}

export default {
    setDefaultStyle,
    parseStyle,
    returnBasicColorRanges,
    returnAllStyleOptions,
    returnStyleOptionsForComponentType,
    returnDefaultsForComponentType,
    parseThemeColor,
    returnDefaultThemeColors,
    returnDefaultStyleTheme,
    getComponentStyleFromTheme
};