import { STATUS } from "../enums/status";
import createGUID from "../../utility/helper-functions/createGUID";

class CodeExerciseSubmission {
    public id: string = "";
    public guidRef: string = createGUID(8);
    public ownerId: string = "";
    public codeExerciseId: string = "";
    public challengeGuidRef: string = ""; //
    public sourceCode: string = "";
    public isSubmitted: boolean = false;
    public dateSubmitted: string = "";

    public status: STATUS = STATUS.unchanged; //client side
}

export { CodeExerciseSubmission };