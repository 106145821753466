//React
import { useEffect, useState } from "react";

//UI
import { Button, Modal } from "flowbite-react";
import Row from "ui_components/helper/Row";

//Services

//Logics
import parseLogic from "logic/parse/parseLogic";

//Components
import GenericObjectEditor from "app_gridslate/constructor/panels/GenericObjectEditor";

//Classes
import {CRUD_ACTION} from "classes/enums/crud-action";
import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";

type Props = {
    objectType: string,
    objects: any[],
    loadedObjectIndex: number,
    crudActions: Function,
    saveLoadLogic: any,
    saveLoadData: any,
    getEditableProperties: Function,
}

const GenericSaveLoadPanel = (props: Props) => {

    const { crudActions, objectType, objects, loadedObjectIndex, saveLoadLogic, saveLoadData, getEditableProperties } = props;

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedObjectIndex, setSelectedObjectIndex] = useState<number>(-1); //Internal to this component

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [editModalType, setEditModalType] = useState<string>("");
    const [tempCurrentObject, setTempCurrentObject] = useState<any>({});

    useEffect(() => {
        if (objects && objects.length > 0 && selectedObjectIndex !== -1) {
            setSelectedObjectIndex(loadedObjectIndex);
        }
    }, [loadedObjectIndex]);

    const handleCreate = () => {
        let newObject = crudActions(CRUD_ACTION.returnNewObject);
        setTempCurrentObject(newObject);
        setShowEditModal(true);
        setEditModalType("create");
    }

    const handleSaveUpdate = async () => {
        let thisSaveLoadData = crudActions(CRUD_ACTION.getUpdatedObject); 
        saveLoadLogic.save(objectType, thisSaveLoadData, crudActions, setIsSaving);
    }

    // const handleSave = async () => {
    //     let thisSaveLoadData = crudActions(CRUD_ACTION.getUpdatedObject); 
    //     saveLoadLogic.save(objectType, thisSaveLoadData, crudActions, setIsSaving);        
    // }

    // const handleUpdate = async () => {
    //    saveLoadLogic.update(objectType, saveLoadData, crudActions, setIsSaving);
    // }

    const handleLoad = async (index: number) => {
        saveLoadLogic.load(objectType, objects[index].id, crudActions, setIsLoading);
    }

    const handleDelete = async () => {
        saveLoadLogic.del(objectType, saveLoadData, crudActions, setIsDeleting);
    }

    const handleEdit = () => {
        setShowEditModal(true); 
        setEditModalType('edit'); 
        setTempCurrentObject({...objects[loadedObjectIndex]});
    }

    // const updateCurrentObject = (value: any) => {
    //     console.log(value);
    //     crudActions("update", value);
    // }

    const isBusy = () => {
        return isSaving || isLoading || isDeleting;
    }

    const renderEditModal = () => {
       
        return (<Modal show={showEditModal} size="md" onClose={() => setShowEditModal(false)} popup>
          <Modal.Header />
          <Modal.Body>
            <GenericObjectEditor
                persistObject={(editableProperties: EditablePropertyMetadata[], editType: string) => {
                    if (editType === "create") {
                        let updatedNewObject = {...tempCurrentObject};
                        updatedNewObject = parseLogic.parseObjectUpdate(updatedNewObject, editableProperties);
                        crudActions(CRUD_ACTION.appendNewObject, updatedNewObject); 
                        setShowEditModal(false);
                    }
                    if (editType === "edit") { crudActions(CRUD_ACTION.updateObjectProperties, editableProperties); setShowEditModal(false); }
                }}
                object={tempCurrentObject}
                type={objectType}
                editableProperties={getEditableProperties()}
                editModalType={editModalType}
            />
          </Modal.Body>
        </Modal>);
      }
   
      const parseSelectedClasses = (object: any, index: number) => {
        let baseClass = 'rounded inline-block p-2 m-2 border-2 border-black w-32 h-16 select-none';
        
        if (index === selectedObjectIndex){
            baseClass += ' border-red-500';
        }
        if (index === loadedObjectIndex){
            baseClass += ' bg-green-200';
        }
        return baseClass;
      }

    const parseButtonDisabledStatus = (type: string = "none") => {
        let disabled = false;
        if (isSaving || isLoading || isDeleting || (type !== "create" && selectedObjectIndex === -1 )){
            disabled = true;
        }

        //only enable editing if object is loaded
        if (type === "edit" && selectedObjectIndex != loadedObjectIndex){
            disabled = true;
        }

        if ((type === "save" || type === "delete") && selectedObjectIndex != loadedObjectIndex){
            disabled = true;
        }

        if (objects[selectedObjectIndex] && objects[selectedObjectIndex].id === "default"){
            disabled = true;
        }
        return disabled;
    }

    return (
        <div className='block'>
            {/* <div>Selected objext index: {selectedObjectIndex}</div>
            <div>Loaded object index: {loadedObjectIndex}</div> */}
            <Row>
            {objects && objects.map((object: any, index: number) => (
                <div
                    key={"load_save-panel-objects" + index}
                    onClick={() => {if (!isBusy()) setSelectedObjectIndex(index)}}
                    onDoubleClick={() => { if (!isBusy()) { 
                        handleLoad(index); 
                        setSelectedObjectIndex(index); }}}
                    className={parseSelectedClasses(object, index)}>
                    {parseLogic.getObjectName(objectType, object)}
                </div>
            ))}
            
            {!objects && <div>Loading...</div>}
            </Row>
            {objects && objects.length === 0 && <div>No objects found</div>}
            <Button className='inline-block m-2' disabled={parseButtonDisabledStatus('save')} onClick={() => handleSaveUpdate()}>{!isSaving?'Save ' + objectType: 'Saving ' + objectType + '...'}</Button>
            <Button className='inline-block m-2' disabled={parseButtonDisabledStatus()} onClick={() => handleLoad(selectedObjectIndex)}>{!isLoading?'Load ' + objectType: 'Loading ' + objectType + '...'}</Button>
            <Button className='inline-block m-2' disabled={parseButtonDisabledStatus('create')} onClick={() => handleCreate()}>Create new</Button>
            {/* <Button className='inline-block m-2' disabled={parseButtonDisabledStatus()} onClick={() => handleClone()}>Create clone</Button> */}
            <Button className='inline-block m-2' disabled={parseButtonDisabledStatus('delete')} onClick={() => handleDelete()}>{!isDeleting?'Delete ' + objectType: 'Deleting ' + objectType + '...'}</Button>
            <Button className='inline-block m-2' disabled={parseButtonDisabledStatus('edit')} onClick={() => handleEdit()}>Edit</Button>
          
            {showEditModal && renderEditModal()}

        </div>
    );
};

export default GenericSaveLoadPanel;