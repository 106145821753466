import { NavbarChild } from './NavbarChild';

class NavbarElement {

    route: string;
    pageId: string;
    pageRef: string;
    title: string;
    children: NavbarChild[];

    constructor(pageId: string, pageRef: string, title: string, route: string, children: NavbarChild[]) {
        this.pageId = pageId;
        this.pageRef = pageRef;
        this.title = title;
        this.route = route;
        this.children = children;
    }
    
}

export { NavbarElement};