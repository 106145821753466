//React

//UI

//Services
import apiService from "services/apiService";
import config from "config";

//Logics

//Components

//Store

//Classes
import { Component } from "classes/components/Component";
import { COMPONENT_SUBTYPE } from "classes/enums/component-subtypes";
import { COMPONENT_TYPE } from "classes/enums/component-types";
class DocXParseResult {
    keys: string[];
    values: string[];

    constructor(keys: string[], values: string[]) {
        this.keys = keys;
        this.values = values;
    }
}

const handleDocXUpload = async (files: any) => {

    let formData = new FormData();
    formData.append('file', files[0]);

    const response = await apiService.upload(config.gridslateUrl + '/cms/UploadDocXFile', formData)
    if (response.success) {
        let parsedPageData = response.payload;
        console.log(parsedPageData);
        let data = createPageFromDocXData(parsedPageData);
        return data;
    }
    else {
        console.log(response.message);
        return { "success": false, "message": response.message, "components": [], "gridArray": [] };
    }
}

const createPageFromDocXData = (data: any) => {
    //CODE duplication here
    //let newPage = new Page(website.id, 'New Page');
    //TODO: error if no themes exist
    //newPage.styleThemeId = currentThemeIndex !== -1 ? allThemes[currentThemeIndex].id : allThemes.length !== 0 ? allThemes[0].id : '';
    let newComponents = [] as Component[];
    let gridArray = [] as string[][];

    for (let y = 0; y < data.keys.length; y++) {
        gridArray.push([]);
        let element = data.keys[y];
        switch (element) {
            //let newComponent;
            case "Title":
                let newTitle = new Component(COMPONENT_TYPE.title);
                newTitle.text = data.values[y];
                newComponents.push(newTitle);
                gridArray[y].push(newTitle.componentRef);
                break;
            case "Paragraph":
                let newComponent = new Component(COMPONENT_TYPE.text);
                newComponent.text = data.values[y];
                newComponents.push(newComponent);
                gridArray[y].push(newComponent.componentRef);
                //console.log(JSON.stringify(newComponent));
                break;
            case "Image":
                //let newImage = new Image();
                let newImage = new Component(COMPONENT_TYPE.image);
                // newImage.data.src = data.values[y];
                // newComponents.push(newImage);
                // gridArray[y].push(newImage.componentRef);
                break;
            case "Heading1":
                let newHeading1 = new Component(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type1);
                //newHeading1.data.headingType = "h1";
                newHeading1.text = data.values[y];
                newComponents.push(newHeading1);
                gridArray[y].push(newHeading1.componentRef);
                break;
            case "Heading2":
                let newHeading2 = new Component(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type2);
                //newHeading2.data.headingType = "h2";
                newHeading2.text = data.values[y];
                newComponents.push(newHeading2);
                gridArray[y].push(newHeading2.componentRef);
                break;
            case "Heading3":
                let newHeading3 = new Component(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type3);
                //newHeading3.data.headingType = "h3";
                newHeading3.text = data.values[y];
                newComponents.push(newHeading3);
                gridArray[y].push(newHeading3.componentRef);
                break;
            case "Heading4":
                let newHeading4 = new Component(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type4);
                //newHeading4.data.headingType = "h4";
                newHeading4.text = data.values[y];
                newComponents.push(newHeading4);
                gridArray[y].push(newHeading4.componentRef);
                break;
            case "Heading5":
                let newHeading5 = new Component(COMPONENT_TYPE.heading, COMPONENT_SUBTYPE.type5);
                //newHeading5.data.headingType = "h5";
                newHeading5.text = data.values[y];
                newComponents.push(newHeading5);
                gridArray[y].push(newHeading5.componentRef);
                break;
            //TODO: more headings once heading metadata is developed
            case "UnorderedList":
                let newUnorderedList = new Component(COMPONENT_TYPE.list, COMPONENT_SUBTYPE.unordered);
                //newUnorderedList.data.listType = "unordered";
                //newUnorderedList.text = data.values[y];
                newUnorderedList.text = data.values[y].substring(1);
                newComponents.push(newUnorderedList);
                gridArray[y].push(newUnorderedList.componentRef);
                break;
            case "OrderedList":
                let newOrderedList = new Component(COMPONENT_TYPE.list, COMPONENT_SUBTYPE.ordered);
                //newOrderedList.data.listType = "ordered";
                //newOrderedList.text = data.values[y];
                newOrderedList.text = data.values[y].substring(1);
                newComponents.push(newOrderedList);
                gridArray[y].push(newOrderedList.componentRef);
                break;
            case "Table":
                let newTable = new Component(COMPONENT_TYPE.table);;
                newTable.text = "Table";
                newTable.data.specialisedData = JSON.parse(data.values[y]);
                newComponents.push(newTable);
                gridArray[y].push(newTable.componentRef);
                break;
            case "Codeblock":
                let newCodeblock = new Component(COMPONENT_TYPE.codeblock);
                newCodeblock.text = data.values[y];
                newComponents.push(newCodeblock);
                gridArray[y].push(newCodeblock.componentRef);
                break;

            default:
        }
    }
    return { "success": true, "components": newComponents, "gridArray": gridArray };

}

export default { createPageFromDocXData, handleDocXUpload }