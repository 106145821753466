// public class StoppingPoint {
//     public string GuidRef { get; set; }
//     public string Name { get; set; }
//     public string ComponentRefLocation { get; set; }
//     public string Type { get; set; }
//     public string StoppingPointRef { get; set; }
//     public Boolean IsRequired { get; set; }
// }
import {CLASS} from "classes/enums/classes";
import createGUID from "utility/helper-functions/createGUID";

class StoppingPoint {
    guidRef: string = createGUID(8);
    name: string = "Stopping Point";
    componentRefLocation: string = "";
    type: CLASS = CLASS.mcq;
    stoppingPointRef: string = "";
    isRequired: boolean = false;
}

export { StoppingPoint };