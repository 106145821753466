import createGUID from 'utility/helper-functions/createGUID';
//import {EditablePropertyMetadata} from 'classes/gridslate/EditablePropertyMetadata';

class Page
{
    id : string = "";
    parentId : string = "";
    parentType : string = "website";
    title : string = "";
    pageRef : string = createGUID(10);
    description : string = "";
    styleThemeId : string = "";
    isPublic: boolean = true;
    ownerId: string = "";
    creationDate: string = "";
    lastUpdateDate: string = "";
    gridArray : string[][] = [];

    isLoaded: boolean = false; //for client side

    constructor(parentId: string = "", title: string = "")
    {
        this.parentId = parentId;
        this.title = title;
    }

}

export { Page};