//React
//import { useNavigate } from "react-router-dom";

//UI

//Services

//Logics

//Components
import Container from "ui_components/helper/Container";

//Classes

//import { Fade } from "react-awesome-reveal";

const Home = () => {

    //const navigate = useNavigate();
   
    return (
        <Container>
            <div>
                {/* <Fade> */}
               {/* <img className='my-12 m-auto shadow-2xl shadow-blue-700 w-11/12 md:w-7/12' src={require('app_gridslate/assets/images/Bubble.jpg')} alt='bubble' onClick={() => navigate('/currentwork')}/> */}
               {/* </Fade> */}

               <a href='/pagechallenge/66a0f0241e0084b02f890ed1'>Test page challenge</a>
            
            </div>
        </Container>
    );
}

export default Home;

