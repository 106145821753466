import {Category} from "classes/general/Category";

class CategoryStatus {
    level0CategoryId: string = "None";
    level1CategoryId: string = "None";
    level2CategoryId: string = "None";
    //level0CategoryId: string = "None";
    //level1CategoryId: string = "None";
    //level2CategoryId: string = "None";
    categories: Category[] = [];
}

export { CategoryStatus};
