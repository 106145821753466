//React
import { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";

//UI
import { Container } from 'ui_components/helper/HelperComponents';

//Services
import crudService from 'services/crudService';
import { toast } from 'react-toastify';
import errorService from 'services/errorService';

//Logics
import styleLogic from 'logic/style/styleLogic';
import parseLogic from 'logic/parse/parseLogic';
import categoryStatusLogic from 'logic/gridslate/categoryStatusLogic';

//Components
import ComponentRendererStatic from 'views/render/ComponentRendererStatic';
import McqViewer from "app_gridslate/constructor/dynamic/McqViewer";
import PageChallengeTimeline from "app_gridslate/constructor/component/PageChallengeTimeline";
import CodeExerciseViewer from './dynamic/CodeExerciseViewer';

//Store
import { useAtom } from 'jotai';
import { mcqsInMemory } from 'atom';

//Classes
import { FilterModel } from 'classes/crud/FilterModel';
import { CLASS } from 'classes/enums/classes';
import { Page } from "classes/gridslate/Page";
import { Mcq } from "classes/mcq/Mcq";
import { Component } from "classes/components/Component";
import { PageChallenge } from "classes/course/PageChallenge";
import { StyleTheme } from 'classes/style/StyleTheme';
import { StoppingPointStatus } from 'classes/course/StoppingPointStatus';
import { IsBusy } from 'classes/general/IsBusy';
import { LoadPageChallengeResponseModel } from 'classes/models/ResponseModels';
import { PageChallengeStatus } from "classes/course/PageChallengeStatus";

//shift + Mousewheel increase fontsize and image size
const PageChallengeViewer = () => {

    const [mcqs, setMcqs] = useAtom(mcqsInMemory);

    const [page, setPage] = useState<Page | null>(null); // The page that is the basis for the challenge 
    const [pageChallenge, setPageChallenge] = useState<PageChallenge>(new PageChallenge()); // The text challenge that is being viewed
    const [components, setComponents] = useState<Component[] | null>(null); // The components of the page

    const [pageChallengeStatus, setPageChallengeStatus] = useState<PageChallengeStatus | null>(null);

    const [stoppingPointStatuses, setStoppingPointStatuses] = useState<StoppingPointStatus[]>([]);

    const [currentStoppingPointComponentRef, setCurrentStoppingPointComponentRef] = useState<string>("first");

    const [isBusy, setIsBusy] = useState(new IsBusy().loading([CLASS.pageChallenge, CLASS.stoppingPointStatus]));

    const [defaultTheme, setDefaultTheme] = useState<StyleTheme>(styleLogic.returnDefaultStyleTheme());

    const { pageChallengeId } = useParams();


    useEffect(() => {
        if (page && pageChallenge && components && stoppingPointStatuses) {
            let currentStoppingPointComponentRef = findNextStoppingPointFromStatuses(stoppingPointStatuses);
            console.log("Current stopping point, from status load: ", currentStoppingPointComponentRef);
            let parsedComponents = parseComponentsForStoppingPointVisibilityFlags(currentStoppingPointComponentRef, pageChallenge, page.gridArray, components);
            setComponents(parsedComponents);
        }

    }, [components, page, pageChallenge, stoppingPointStatuses])

    useEffect(() => {
        //Get text challenge status
        const getPageChallengeStatus = async (pageChallengeId: string) => {
            let filterModel = new FilterModel();
            filterModel.PropertyFilters.push(["PageChallengeId", pageChallengeId]);
            let response = await crudService.get(CLASS.pageChallengeStatus, filterModel);
            if (response.success) {
                setPageChallengeStatus(response.payload as PageChallengeStatus);
            }
            else {
                errorService.handleError(response);
            }
        }

        if (pageChallengeId && !pageChallengeStatus) {
            getPageChallengeStatus(pageChallengeId);
        }
    }, []);

    useEffect(() => {

        const loadPageChallenge = async (pageChallengeId: string) => {
            //Load all mcq categories for the user
            let filterModel = new FilterModel();
            filterModel.PropertyFilters.push(["Id", pageChallengeId]);
            let response = await crudService.load(CLASS.pageChallenge, filterModel);
            if (response.success) {
                let responseModel = response.payload as LoadPageChallengeResponseModel;

                setPage(responseModel.page);
                setPageChallenge(responseModel.pageChallenge);
                //loadAllMcqStoppingPointMcqs(responseModel.pageChallenge);
                loadMcqs(responseModel.pageChallenge);
                let parsedComponents = parseLogic.parseComponentsFromLoad(responseModel.components);
                setComponents(parsedComponents);
                setIsBusy(isBusy.loaded(CLASS.pageChallenge));

                //Now fetch stopping point statuses
                //loadStoppingPointStatuses(pageChallengeId);
            }
            else {
                errorService.handleError(response.payload);
            }
        }

        const loadStoppingPointStatuses = async (pageChallengeId: string) => {
            let filterModel = new FilterModel();
            filterModel.OnlyOwner = true;
            filterModel.PropertyFilters.push(["PageChallengeId", pageChallengeId]);
            let response = await crudService.get(CLASS.stoppingPointStatus, filterModel);
            if (response.success) {
                setStoppingPointStatuses(response.payload as StoppingPointStatus[]);
                setIsBusy(isBusy.loaded(CLASS.stoppingPointStatus));
            }
            else {
                errorService.handleError(response);
            }
        }

        if (pageChallengeId) {
            loadPageChallenge(pageChallengeId);
            loadStoppingPointStatuses(pageChallengeId);
        }

    }, []);

    const findNextStoppingPointFromStatuses = (stoppingPointStatuses: StoppingPointStatus[]) => {

        for (let x = 0; x < pageChallenge.mcqStoppingPoints.length; x++) {
            let stoppingPoint = pageChallenge.mcqStoppingPoints[x];
            let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);
            //console.log("Text challenge stopping point ref: ", stoppingPoint.componentRefLocation);
            //console.log("Stopping point status ref: ", stoppingPointStatus?.stoppingPointGuidRef);

            //Assuming that if stopping point status is undefined, then it has not been completed
            if (stoppingPointStatus === undefined) {
                return stoppingPoint.componentRefLocation;
            }
        }
        //If all stopping points have been completed, then return the "complete"
        if (stoppingPointStatuses.length === pageChallenge.mcqStoppingPoints.length) {
            return "complete";
        } else {
            //If no stopping points have been completed, then return the first
            return "first";
        }
    }

    const loadMcqs = async (pageChallenge: PageChallenge) => {
        let highestLevel = categoryStatusLogic.findHighestCategoryId(pageChallenge);
        let filterModel = new FilterModel();
        if (highestLevel === 0) {
            filterModel.PropertyFilters.push(["Level0CategoryId", pageChallenge.level0CategoryId]);
        } else if (highestLevel === 1) {
            filterModel.PropertyFilters.push(["Level1CategoryId", pageChallenge.level1CategoryId]);
         } else if (highestLevel === 2) {
            filterModel.PropertyFilters.push(["Level2CategoryId", pageChallenge.level2CategoryId]);
         }

        let response = await crudService.get(CLASS.mcq, filterModel);
        if (response.success) {
           setMcqs(response.payload as Mcq[]);
        }
        else {
            errorService.handleError(response);
        }

    }

    //Sets render flags up until a given componentRef, and returns the components for setting
    const parseComponentsForStoppingPointVisibilityFlags = (stoppingPointComponentRef: string, pageChallenge: PageChallenge, gridArray: string[][], components: Component[]) => {
        let stoppingPointReached = false;

        //If first then set to first stopping point
        // if (stoppingPointComponentRef === "first" && pageChallenge.mcqStoppingPoints.length > 0) {
        //     stoppingPointComponentRef = pageChallenge.mcqStoppingPoints[0].componentRefLocation;
        // }
        //TODO: first componentRef is not necessarily the first stopping point, if deleted and added etc
        if (stoppingPointComponentRef === "first" && pageChallenge.stoppingPoints.length > 0) {
            stoppingPointComponentRef = pageChallenge.stoppingPoints[0].componentRefLocation;
        }


        //Iterate through gridArray
        gridArray.forEach((row, rowIndex) => {
            row.forEach((componentRef, colIndex) => {
                let component = components.find((component) => component.componentRef === componentRef);
                if (component !== undefined) {
                    component.render = "yes";
                    if (!stoppingPointReached && component.componentRef === stoppingPointComponentRef) {
                        stoppingPointReached = true;
                        component.render = "stoppingpoint";
                    } else if (stoppingPointReached) {
                        component.render = "no";
                    }
                }
            });
        });
        setCurrentStoppingPointComponentRef(stoppingPointComponentRef);
        return components;
    }

    const checkIfStoppingPoint = (componentRef: string) => {
        for (let i = 0; i < pageChallenge.stoppingPoints.length; i++) {
            if (pageChallenge.stoppingPoints[i].componentRefLocation === componentRef) {
                 //check if parent component is being rendered
                 let parentComponent = components?.find((component) => component.componentRef === componentRef);
                 if (parentComponent !== undefined) {
                     if (parentComponent.render === "no") {
                         return false;
                     }
                 }
                 return true;
            }
        }
        return false;
    }

    // const checkAndRenderStoppingPoint = (componentRef: string) => {
    //     if (checkIfMcqStoppingPoint(componentRef)) {
    //         return renderMcqStoppingPoint(componentRef);
    //     }
    //     if (checkIfCodeExerciseStoppingPoint(componentRef)) {
    //         return renderCodeExerciseStoppingPoint(componentRef);
    //     }
    //     return <></>;
    // }

    // const checkIfMcqStoppingPoint = (componentRef: string) => {

    //     for (let i = 0; i < pageChallenge.mcqStoppingPoints.length; i++) {
    //         if (pageChallenge.mcqStoppingPoints[i].componentRefLocation === componentRef) {
    //             //check if parent component is being rendered
    //             let parentComponent = components?.find((component) => component.componentRef === componentRef);
    //             if (parentComponent !== undefined) {
    //                 if (parentComponent.render === "no") {
    //                     return false;
    //                 }
    //             }
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    const renderStoppingPoint = (componentRef: string) => {
        
        let stoppingPoint = pageChallenge.stoppingPoints.find(x => x.componentRefLocation === componentRef);
        
        if (stoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }
        
        if (stoppingPoint.type === CLASS.mcq) {
            return renderMcqStoppingPoint(componentRef);
        }
        if (stoppingPoint.type === CLASS.codeExercise) {
            //console.log("Rendering code exercise stopping point");
            return renderCodeExerciseStoppingPoint(componentRef);
        }
    }

    const checkIfCodeExerciseStoppingPoint = (componentRef: string) => {
        for (let i = 0; i < pageChallenge.codeExerciseStoppingPoints.length; i++) {
            if (pageChallenge.codeExerciseStoppingPoints[i].componentRefLocation === componentRef) {
                //check if parent component is being rendered
                let parentComponent = components?.find((component) => component.componentRef === componentRef);
                if (parentComponent !== undefined) {
                    if (parentComponent.render === "no") {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }

    const submitStoppingPointStatus = async () => {
        let stoppingPointStatus = new StoppingPointStatus();

        //TODO: this needs to reflect actual stopping point guid, not componentRef
        stoppingPointStatus.stoppingPointGuidRef = currentStoppingPointComponentRef;

        stoppingPointStatus.pageChallengeId = pageChallenge.id;
        stoppingPointStatus.dateCompleted = Date.now().toString();
        stoppingPointStatus.type = "Mcq";
        stoppingPointStatus.isCompleted = true;

        let response = await crudService.create(CLASS.stoppingPointStatus, stoppingPointStatus);
        if (response.success) {
            //Not updating currently, only adding new ones that don't exist yet
            toast.success("Stopping point submitted successfully");
            let tempStoppingPointStatuses = [...stoppingPointStatuses];
            tempStoppingPointStatuses.push(stoppingPointStatus);
            setStoppingPointStatuses(tempStoppingPointStatuses);
            checkForChallengeCompletion();
        }
        else {
            errorService.handleError(response);
        }
    }

    //TODO: change componentRefLocation to stoppingPointGuidRef
    //TODO: create guidRef on MCQStoppingPoint or remove if unnecessary

    const renderMcqStoppingPoint = (componentRef: string) => {
        let thisStoppingPoint = pageChallenge.mcqStoppingPoints.find(x => x.componentRefLocation === componentRef);
        if (thisStoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }
        let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.componentRefLocation);
        if (thisStoppingPointStatus !== undefined && thisStoppingPointStatus.isCompleted) {
            return (
                //TODO: add reopen button
                <div className="w-full h-16 bg-green-200 border-4 border-dashed m-4 text-lg text-center">
                    Stopping Point Complete!
                </div>
            )
        } else {
            return (
                <McqViewer
                    level0CategoryId={thisStoppingPoint.level0CategoryId}
                    level1CategoryId={thisStoppingPoint.level1CategoryId}
                    level2CategoryId={thisStoppingPoint.level2CategoryId}
                    questionsSubmittable={true}
                    stoppingPointStatus={thisStoppingPointStatus}
                    submitStoppingPointStatus={submitStoppingPointStatus}
                    mcqStoppingPoint={thisStoppingPoint}
                />
            )
        }
    }

    const renderCodeExerciseStoppingPoint = (componentRef: string) => {
        let thisStoppingPoint = pageChallenge.codeExerciseStoppingPoints.find(x => x.componentRefLocation === componentRef);
        if (thisStoppingPoint === undefined) {
            console.error("Expected stopping point not found for componentRef: " + componentRef);
            return <></>;
        }
        //let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.componentRefLocation);
        //TODO: fix this on renderMcqStoppingPoint
        let thisStoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === thisStoppingPoint?.guidRef);
        if (thisStoppingPointStatus !== undefined && thisStoppingPointStatus.isCompleted) {
            return (
                //TODO: add reopen button
                <div className="w-full h-16 bg-green-200 border-4 border-dashed m-4 text-lg text-center">
                    Stopping Point Complete!
                </div>
            )
        } else {
            return (
                <CodeExerciseViewer
                    // level0CategoryId={thisStoppingPoint.level0CategoryId}
                    // level1CategoryId={thisStoppingPoint.level1CategoryId}
                    // level2CategoryId={thisStoppingPoint.level2CategoryId}
                    questionsSubmittable={true}
                    stoppingPointStatus={thisStoppingPointStatus}
                    codeExerciseStoppingPoint={thisStoppingPoint}
                    submitStoppingPointStatus={submitStoppingPointStatus}
                    challengeGuidRef={pageChallenge.guidRef}
                    //mcqStoppingPoint={thisStoppingPoint}
                />
            )
        }

    }

    const submitPageChallengeStatus = async () => {
        let pageChallengeStatus = new PageChallengeStatus();
        pageChallengeStatus.pageChallengeId = pageChallenge.id;
        pageChallengeStatus.dateCompleted = Date.now().toString();
        pageChallengeStatus.isCompleted = true;

        let response = await crudService.create(CLASS.pageChallengeStatus, pageChallengeStatus);
        if (response.success) {
            toast.success("Challenge successfully completed!");
            setPageChallengeStatus(pageChallengeStatus);
        }
        else {
            errorService.handleError(response);
        }
    }


    const checkForChallengeCompletion = () => {
        let complete = true;
        for (let i = 0; i < pageChallenge.mcqStoppingPoints.length; i++) {
            let stoppingPoint = pageChallenge.mcqStoppingPoints[i];
            let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);
            if (stoppingPointStatus === undefined || !stoppingPointStatus.isCompleted) {
                complete = false;
            }
        }
        if (complete) {
            submitPageChallengeStatus();
        }
    }

    return (
        <Container>
            <div className="mt-6">
                {pageChallenge && <PageChallengeTimeline pageChallenge={pageChallenge} stoppingPointStatuses={stoppingPointStatuses} />}
            </div>
            {/* {JSON.stringify(mcqs)} */}
            {!isBusy.isLoading && components && page && page.gridArray.map((row, rowIndex) => {
                return (
                    <div key={"row" + rowIndex}>
                        {row.map((col, colIndex) => {
                            return (
                                <div className={'flex-1'} key={"columns-" + colIndex}>
                                    {/* {JSON.stringify(components.find((component) => component.componentRef === col) as Component)} */}
                                    {components.find((component) => component.componentRef === col) &&
                                        components.find((component) => component.componentRef === col)?.render === "yes" &&
                                        <ComponentRendererStatic
                                            component={components.find((component) => component.componentRef === col) as Component}
                                            rowIndex={rowIndex}
                                            colIndex={colIndex}
                                            setComponentRichText={() => { }}
                                            setComponentText={() => { }}
                                            handleDrop={() => { }}
                                            setImageAttribute={() => { }}
                                            styleTheme={defaultTheme}
                                            editingEnabled={false}
                                        />}
                                        {checkIfStoppingPoint(col) && renderStoppingPoint(col)}
                                    {/* {checkIfMcqStoppingPoint(col) && renderMcqStoppingPoint(col)} */}
                                    {/* {checkIfCodeExerciseStoppingPoint(col) && renderCodeExerciseStoppingPoint(col)} */}
                                </div>
                            )
                        })}
                    </div>
                )
            })}

            {isBusy.isLoading &&
                <div className="text-center">
                    Loading page...
                </div>}
            {/* {isError &&
                <div className="text-center">
                    An error has occured! Please refresh the page.
                    {JSON.stringify(isError)}
                </div>} */}
            {/* <CodeExercise /> */}
            <div className="mt-96"></div>

        </Container>
    )
}

export default PageChallengeViewer;