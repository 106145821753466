
//React

//UI

//Services

//Logics

//Components

//Store

//Classes
import { ResponseObject } from "classes/services/ResponseObject";
class requestHeaders {
    authorization: string;
    refresh: string;

    constructor() {
        this.authorization = localStorage.getItem("accessToken") || "";
        this.refresh = localStorage.getItem("refreshToken") || "";
    }
}

//Try new Get
const get = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
        response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            }
        });
    }
    catch (e) {
        //console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client GET Error";
        return responseObj;
    }

    let dataResponse = null;

    //See solution in POST below

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }

    //console.log("getting here: ", responseObj);
    return responseObj;
}

const getS3 = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
        response = await fetch(url, {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
        })
    }
    catch (e) {
        //console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client GET Error";
        return responseObj;
    }

    let dataResponse = null;

    //See solution in POST below

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        //responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse;
        responseObj.success = true;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }
    return responseObj;
}

//Original Get
// const get = async (url: string): Promise<ResponseObject> => {
//     const headers = new requestHeaders();
//     let responseObj = new ResponseObject();

//     try {
//         let response = await fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': headers.authorization,
//                 'Refresh': headers.refresh
//             }
//         });

//         let dataResponse = null;

//         if (response.ok) {
//             dataResponse = await response.json();
//             responseObj.statusCode = response.status;
//             responseObj.message = dataResponse.message;
//             responseObj.payload = dataResponse.payload;
//             responseObj.success = dataResponse.success;
//         } else {
//             responseObj.success = false;
//             responseObj.statusCode = response.status;
//             responseObj.message = JSON.stringify(response);
//             responseObj.errorSource = "Server problem";
//         }



//     }
//     catch (e) {
//         console.log(e);
//         responseObj.success = false;
//         responseObj.message = JSON.stringify(e);
//         //responseObj.statusCode = e
//         responseObj.errorSource = "Client GET Error";
//     }

//     return responseObj;
// }

// const get = async (url: string): Promise<ResponseObject> => {
//     const headers = new requestHeaders();
//     let responseObj = new ResponseObject();


//     await fetch(url, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': headers.authorization,
//                 'Refresh': headers.refresh
//             }
//         })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error(response.status.toString());
//             }
//             return response;
//         })
//         .catch(error => {
//             console.log(error);
//             responseObj.success = false;
//             responseObj.message = JSON.stringify(error);
//             responseObj.errorSource = "Client GET Error";
//         });

//     return responseObj;
// }

// fetch("some-url")
//     .then(function(response)
//      {
//       if(response.status!==200)
//        {
//           throw new Error(response.status)
//        }
//      })
//     .catch(function(error)
//     {
//       ///if status code 401...
//     });

const post = async (url: string, data: object): Promise<ResponseObject> => {
    const headers = new requestHeaders();

    let responseObj = new ResponseObject();

    try {
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            },
            body: JSON.stringify(data)
        });


        //const dataResponse = await response.json();
        const string = await response.text();
        //const dataResponse = string === "" ? {} : JSON.parse(string);
        if (string === "") {
            responseObj.statusCode = response.status;
            responseObj.success = false;
            responseObj.message = response.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
            responseObj.errorSource = "Server problem";
            return responseObj;
        }
        const dataResponse = JSON.parse(string);
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    }
    catch (e) {
        console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client POST Error";
    }

    return responseObj;
}
const upload = async (url: string, data: FormData): Promise<ResponseObject> => {
    const headers = new requestHeaders();

    let responseObj = new ResponseObject();
    //'Content-Type': 'multipart/form-data',
    try {
        let response = await fetch(url, {
            method: 'POST',
            headers: {

                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            },
            body: data
        });


        //const dataResponse = await response.json();
        const string = await response.text();
        //const dataResponse = string === "" ? {} : JSON.parse(string);
        if (string === "") {
            responseObj.statusCode = response.status;
            responseObj.success = false;
            responseObj.message = response.status === 403 ? "You don't have permission to do that!" : "Empty response from server";
            responseObj.errorSource = "Server problem";
            return responseObj;
        }
        const dataResponse = JSON.parse(string);
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    }
    catch (e) {
        console.log(e);
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client POST Error";
    }

    return responseObj;
}

//S3 - will not return a GenericResponse, so no double payload
const put = async (url: string, data: any): Promise<ResponseObject> => {

    let responseObj = new ResponseObject();

    //const headers = new requestHeaders();
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/jpeg/png',
        },
        body: data
    });

    let dataResponse;

    try {
        dataResponse = await response.json();
    }
    catch (e) {
        console.log(e);
        dataResponse = null;
    }

    //If response is not empty then set payload to response.json() else set it to null
    //const payload = response.status !== 204 ? response.json() : null;
    console.log(response);
    //let payload = null;

    // let responseObj: httpResponse = {
    //     payload: dataResponse ? dataResponse.payload: null,
    //     errorCaught: false,
    //     statusCode: response.status,
    //     message: ""
    // }
    responseObj.statusCode = response.status;
    responseObj.payload = dataResponse ? dataResponse.payload : null;

    return responseObj;
}

//Try new Get
const del = async (url: string): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();
    let response;
    try {
        response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': headers.authorization,
                'Refresh': headers.refresh
            }
        });
    }
    catch (e) {
        responseObj.success = false;
        responseObj.message = JSON.stringify(e);
        responseObj.errorSource = "Client DELETE Error";
        return responseObj;
    }

    let dataResponse = null;

    if (response.ok) {
        dataResponse = await response.json();
        responseObj.statusCode = response.status;
        responseObj.message = dataResponse.message;
        responseObj.payload = dataResponse.payload;
        responseObj.success = dataResponse.success;
    } else {
        responseObj.success = false;
        responseObj.statusCode = response.status;
        responseObj.message = JSON.stringify(response);
        responseObj.errorSource = "Server problem";
    }

    return responseObj;
}

const googleUserDetails = async (url: string, data: object): Promise<ResponseObject> => {
    const headers = new requestHeaders();
    let responseObj = new ResponseObject();

    return responseObj;
}

export default {
    get,
    getS3,
    post,
    put,
    del,
    googleUserDetails,
    upload
}