import { COMPONENT_TYPE } from 'classes/enums/component-types';
import {STATUS } from 'classes/enums/status';
import { Component } from 'classes/components/Component';
import { SelectedComponentData } from 'classes/components/SelectedComponentData';

type Props = {
    selectedComponent: SelectedComponentData;
    components: Component[];
    gridArray: string[][];
    persistGridArrayAndComponents: Function;
    componentType?: COMPONENT_TYPE;
}

const deleteComponent = (props : Props) => {
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    if (componentIndex == -1) {
        return;
    }

    //if there is only one column in the row, remove the entire row
    if (newGridArray[selectedComponent.rowIndex].length === 1) {
        newGridArray.splice(selectedComponent.rowIndex, 1);
    } else {
        //remove the column
        newGridArray[selectedComponent.rowIndex].splice(selectedComponent.colIndex, 1);
    }
    //if the component is new, remove it from the components array
    //otherwise, mark it as deleted
    if (newComponents[componentIndex].status === STATUS.new) {
        newComponents.splice(componentIndex, 1);
    } else {
        newComponents[componentIndex].status = STATUS.deleted;
    }

    persistGridArrayAndComponents(newGridArray, newComponents);
}

//This is only used for empty components at present! Consider refactor
//const addComponent = (props : Props, type : COMPONENT_TYPE) => {
const insertCol = (props : Props) => {
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    if (componentIndex == -1) {
        return;
    }

    //TODO: consider making every Component unique
    let newComponent = new Component(COMPONENT_TYPE.empty);
    newComponent.status = STATUS.new;
    newComponents.push(newComponent);
    //Insert the new component after the position of the selected component
    newGridArray[selectedComponent.rowIndex].splice(selectedComponent.colIndex + 1, 0, newComponent.componentRef);
    //newGridArray[selectedComponent.rowIndex].push(newComponent.componentRef);
    persistGridArrayAndComponents(newGridArray, newComponents);
}

const insertRow = (props : Props) => {
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    if (componentIndex == -1) {
        return;
    }

    let newComponent = new Component(COMPONENT_TYPE.empty);
    newGridArray.splice(selectedComponent.rowIndex, 0, [newComponent.componentRef]);
    newComponents.push(newComponent);
    persistGridArrayAndComponents(newGridArray, newComponents);
}

const addRow = (props : Props) => {
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    // let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    // if (componentIndex == -1) {
    //     return;
    // }
    
    let newComponent = new Component(COMPONENT_TYPE.empty);
    newGridArray.push([newComponent.componentRef]);
    newComponents.push(newComponent);
    persistGridArrayAndComponents(newGridArray, newComponents);
}

const cloneComponent = (props : Props) => {
    
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    if (componentIndex == -1) {
        console.log('Component not found');
        return;
    }

    //clone component
    // let oldComponent;
    // let newComponent;

    // if (newComponents[componentIndex].type === COMPONENT_TYPE.image) {
    //     //oldComponent = newComponents[componentIndex] as Image;
    //     oldComponent = newComponents[componentIndex];
    //     newComponent = new Image();
    //     //newComponent.src = oldComponent.src;
    //     newComponent.data = oldComponent.data;
    
    // } else {
    //     oldComponent = newComponents[componentIndex];
    //     newComponent = new Component(newComponents[componentIndex].type);
    // }
        
    // newComponent.text = oldComponent.text;
    // newComponent.data.style = oldComponent.data.style;
    // newComponent.data.width = oldComponent.data.width;
    // newComponent.data = oldComponent.data;
    // newComponent.status = STATUS.new;
    
    // //Insert the new component after the position of the selected component, but only as a new row not a column
    // newGridArray.splice(selectedComponent.rowIndex + 1, 0, [newComponent.componentRef]);
 
    // newComponents.push(newComponent);

    //console.log('newComponent', newComponent);
    //console.log('newGrid', newGridArray);

    persistGridArrayAndComponents(newGridArray, newComponents);
}

const handleDoubleClickAddComponent = (props : Props) => {
   
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents, componentType } = props;
    if (!componentType) {
        return;
    }
    //console.log('handleDoubleClickAddComponent', componentType, props);
    //console.log(selectedComponent)
    let newGridArray = [...gridArray];
    let newComponents = [...components];

    let newComponent = new Component(componentType);

    //if no selected component, add row and insert component
    if (!selectedComponent || selectedComponent.componentRef === '') {  
        newGridArray.push([newComponent.componentRef]);
    }
    //if selected component is empty
    if (selectedComponent && selectedComponent.component && selectedComponent.component.type === COMPONENT_TYPE.empty) {
        newGridArray[selectedComponent.rowIndex].splice(selectedComponent.colIndex, 1, newComponent.componentRef);
        //remove empty component
        let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
        newComponents.splice(componentIndex, 1);
    }
    //if selected component is not empty, add new row and insert component
    if (selectedComponent && selectedComponent.component && selectedComponent.component.type !== COMPONENT_TYPE.empty) {
        newGridArray.splice(selectedComponent.rowIndex + 1, 0, [newComponent.componentRef]);
        //console.log("reached insert double click");
    }
    //console.log(newComponent);
    newComponents.push(newComponent);
    persistGridArrayAndComponents(newGridArray, newComponents);
}
    

const handleWidthChange = (props : Props, changeType : string) => {
    const { selectedComponent, components, gridArray, persistGridArrayAndComponents } = props;
    let newGridArray = [...gridArray];
    let newComponents = [...components];
    let componentIndex = newComponents.findIndex((component) => component.componentRef === selectedComponent.componentRef);
    if (componentIndex == -1) {
        return;
    }

    if (changeType === '+') {
        newComponents[componentIndex].data.width++;
    } else {
        newComponents[componentIndex].data.width--;
    }
    newComponents[componentIndex].status = (newComponents[componentIndex].status === STATUS.new) ? newComponents[componentIndex].status : STATUS.updated;
    persistGridArrayAndComponents(newGridArray, newComponents);
}


export default {deleteComponent, insertCol, insertRow, cloneComponent, addRow, handleWidthChange, handleDoubleClickAddComponent};