//React
import { useEffect, useState } from 'react';

//UI
import { Timeline } from "flowbite-react";
import { HiCalendar } from "react-icons/hi";

//Services

//Logics

//Components

//Classes
import {StoppingPointStatus} from 'classes/course/StoppingPointStatus';
import {PageChallenge} from "classes/course/PageChallenge";

type Props = {
    pageChallenge: PageChallenge;
    stoppingPointStatuses: StoppingPointStatus[];
}

const PageChallengeTimeline = (props: Props) => {

    const { pageChallenge, stoppingPointStatuses } = props;

    class TimeLineObject {
        stoppingPointGuidRef: string;
        isCompleted: boolean;
        name: string;
        dateCompleted: string;

        constructor(stoppingPointGuidRef: string, isCompleted: boolean, name: string, dateCompleted: string) {
            this.stoppingPointGuidRef = stoppingPointGuidRef;
            this.isCompleted = isCompleted;
            this.name = name;
            this.dateCompleted = dateCompleted;
        }
    }

    const [timeLineObjects, setTimeLineObjects] = useState<TimeLineObject[]>([]);

    //TODO: Needs to be ordered based on components order

    useEffect(() => {
        let tempTimeLineObjects: TimeLineObject[] = [];
        for (let i = 0; i < pageChallenge.stoppingPoints.length; i++) {
            let stoppingPoint = pageChallenge.stoppingPoints[i];
            let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);
            let isCompleted = stoppingPointStatus ? stoppingPointStatus.isCompleted : false;
            let name = stoppingPoint.name;
            let dateCompleted = stoppingPointStatus ? stoppingPointStatus.dateCompleted : "";
            let timeLineObject = new TimeLineObject(stoppingPoint.componentRefLocation, isCompleted, name, dateCompleted);
            tempTimeLineObjects.push(timeLineObject);            
        }
        // for (let i = 0; i < pageChallenge.mcqStoppingPoints.length; i++) {
        //     let stoppingPoint = pageChallenge.mcqStoppingPoints[i];
        //     let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPoint.componentRefLocation);
        //     let isCompleted = stoppingPointStatus ? stoppingPointStatus.isCompleted : false;
        //     let name = stoppingPoint.name;
        //     let dateCompleted = stoppingPointStatus ? stoppingPointStatus.dateCompleted : "";
        //     let timeLineObject = new TimeLineObject(stoppingPoint.componentRefLocation, isCompleted, name, dateCompleted);
        //     tempTimeLineObjects.push(timeLineObject);            
        // }
        setTimeLineObjects(tempTimeLineObjects);
    }, [stoppingPointStatuses, pageChallenge]);

    const convertUnixToDate = (unixString: string) => {
        let unix = parseInt(unixString);
        if (unix === 0 || isNaN(unix)) {
            return "";
        }
        let date = new Date(unix);
        return date.toLocaleDateString();
    }

    // const currentStoppingPoint = (stoppingPointGuidRef: string) => {
    //     let stoppingPointStatus = stoppingPointStatuses.find(x => x.stoppingPointGuidRef === stoppingPointGuidRef);
    //     return stoppingPointStatus
    // }

    return (
        <Timeline horizontal>
            {timeLineObjects.map((timelineObject, index) => {
                return (
                    <Timeline.Item key={"timeline-" + index}>
                        <Timeline.Point icon={HiCalendar} />
                        <Timeline.Content>
                            <Timeline.Time>{timelineObject.isCompleted?"Complete":"Incomplete"}</Timeline.Time>
                            <Timeline.Title>{timelineObject.name}</Timeline.Title>
                            <Timeline.Body>
                                Completed: {convertUnixToDate(timelineObject.dateCompleted)}
                            </Timeline.Body>
                        </Timeline.Content>
                    </Timeline.Item>

                )
            })}
        </Timeline>
    )
}

export default PageChallengeTimeline;