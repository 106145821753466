import {EditablePropertyMetadata} from "classes/gridslate/EditablePropertyMetadata";
//import {EditablePropertySelectItem} from "classes/gridslate/EditablePropertySelectItem";
import {StyleTheme} from "classes/style/StyleTheme";

// id: string = "";
// type: string;
// stringifiedData: string = "";
// name: string = "default";
// data: StyleThemeData = new StyleThemeData();
// websiteId: string;
// ownerId: string = "";
// creationDate: string = "";
// lastUpdateDate: string = "";

const styleThemeProperties = (thisObject: StyleTheme) => {
    let editableProperties = [] as EditablePropertyMetadata[];
    editableProperties.push({ propertyName: 'name', propertyType: 'string', displayName: "Theme name", value: thisObject? thisObject.name : "new"});

    return editableProperties;
}

export default styleThemeProperties;