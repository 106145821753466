import {CategoryStatus} from "classes/general/CategoryStatus";
import {Category} from "classes/general/Category";

// const updateStatus = (categoryStatus: CategoryStatus, action: string, value: any) => {
//     let tempStatus = {...categoryStatus};
//     switch (action) {
//         case "subjectId":
//             tempStatus.level0CategoryId = value;
//             tempStatus.level1CategoryId = "None";
//             tempStatus.level2CategoryId = "None";
//             break;
//         case "categoryId":
//             tempStatus.level1CategoryId = value;
//             tempStatus.level2CategoryId = "None";
//             break;
//         case "subcategoryId":
//             tempStatus.level2CategoryId = value;
//             break;
//         case "categories":
//             tempStatus.categories = value;
//             break;
//         default:
//             break;
//     }
//     return tempStatus;
// }

//Helper function to find the parent category of a category
const findCategoryParent = (categories: Category[], id: string) => {
    let category = categories.find(x => x.id === id);
    if (category === undefined) {
        console.log("Category not found on findCategoryParent: ", id)
        return "None";
    }
    return category.parentId;
}

const setCategoryStatusFromId = (categoryStatus: CategoryStatus, id: string) => {
    let tempStatus = {...categoryStatus};
    let category = categoryStatus.categories.find(x => x.id === id);
    if (category === undefined) {
        console.log("Category not found on setCategoryStatusFromId: ", id)
        return tempStatus;
    }

    if (category.level === 0) {
        tempStatus.level0CategoryId = id;
        tempStatus.level1CategoryId = "None";
        tempStatus.level2CategoryId = "None";
    } else if (category.level === 1) {
        tempStatus.level0CategoryId = category.parentId;
        tempStatus.level1CategoryId = id;
        tempStatus.level2CategoryId = "None";
    } else if (category.level === 2) {
        tempStatus.level2CategoryId = id;
        tempStatus.level1CategoryId = category.parentId;
        tempStatus.level0CategoryId = findCategoryParent(tempStatus.categories, category.parentId);
    }
    return tempStatus;
}

// const setCategoryStatusFromId = (categoryStatus: CategoryStatus, id: string) => {
//     let tempStatus = {...categoryStatus};
//     let category = categoryStatus.categories.find(x => x.id === id);
//     if (category === undefined) {
//         console.log("Category not found on setCategoryStatusFromId: ", id)
//         return tempStatus;
//     }

//     if (category.level === 0) {
//         tempStatus.level0CategoryId = id;
//         tempStatus.level1CategoryId = "None";
//         tempStatus.level2CategoryId = "None";
//     } else if (category.level === 1) {
//         tempStatus.level0CategoryId = category.parentId;
//         tempStatus.level1CategoryId = id;
//         tempStatus.level2CategoryId = "None";
//     } else if (category.level === 2) {
//         let parentId = category.parentId;
//         //Should be unnecessary
//         if (category === undefined || category.parentId === undefined) {            
//             parentId = "None";
//             return tempStatus;
//         }
//         let subjectParentCategory = categoryStatus.categories.find(x => x.id === parentId);
//         if (subjectParentCategory === undefined) {
//             console.log("Parent Category not found on setCategoryStatusFromId: ", category.parentId)
//             return tempStatus;
//         }
//         tempStatus.level0CategoryId = subjectParentCategory.id;
//         tempStatus.level1CategoryId = category.parentId;
//         tempStatus.level2CategoryId = id;
//     }
    
//     return tempStatus;
// }

const returnClonedCategoryStatus = (categoryStatus: CategoryStatus) => {
    let newCategoryStatus = new CategoryStatus();
    newCategoryStatus.level0CategoryId = categoryStatus.level0CategoryId;
    newCategoryStatus.level1CategoryId = categoryStatus.level1CategoryId;
    newCategoryStatus.level2CategoryId = categoryStatus.level2CategoryId;
    newCategoryStatus.categories = categoryStatus.categories;
    console.log(newCategoryStatus)
    return newCategoryStatus;
}

const findHighestCategoryId = (object: any) => {
    if (object.level2CategoryId !== "None") {
        return object.level2CategoryId;
    } else if (object.level1CategoryId !== "None") {
        return object.level1CategoryId;
    } else if (object.level0CategoryId !== "None"){
        return object.level0CategoryId;
    }
    return "None";
}
//TODO: Reconcile this with findHighestCategoryId
const findParentCategoryId = (categoryStatus: CategoryStatus) => {
    let parentCategoryId = "";
    if (categoryStatus.level2CategoryId !== "None") {
        parentCategoryId = categoryStatus.level2CategoryId;
    } else if (categoryStatus.level1CategoryId !== "None") {
        parentCategoryId = categoryStatus.level1CategoryId;
    } else if (categoryStatus.level0CategoryId !== "None") {
        parentCategoryId = categoryStatus.level0CategoryId;
    }
    return parentCategoryId;
}

const getLevelFromCategoryId = (categoryStatus: CategoryStatus, categoryId: string) => {
}

const findLevelFromCategoryStatus = (categoryStatus: CategoryStatus) => {
    if (!categoryStatus) {
        return -1;
    }
    if (categoryStatus.level2CategoryId !== "None") {
        return 2;
    } else if (categoryStatus.level1CategoryId !== "None") {
        return 1;
    } else if (categoryStatus.level0CategoryId !== "None") {
        return 0;
    }
    return -1;
}



const setCategoryStatusFromObject = (categoryStatus: CategoryStatus, object: any) => {
    if (object === undefined || object.level0CategoryId === undefined || object.level1CategoryId === undefined || object.level2CategoryId === undefined) {
        console.error("setCategoryStatusFromObject: Invalid object");
        console.log(object);
        return categoryStatus;
    }
    let tempStatus = {...categoryStatus};
    tempStatus.level0CategoryId = object.level0CategoryId;
    tempStatus.level1CategoryId = object.level1CategoryId;
    tempStatus.level2CategoryId = object.level2CategoryId;
    return tempStatus;
}

export default {
    //updateStatus,
    setCategoryStatusFromId,
    returnClonedCategoryStatus,
    findParentCategoryId,
    findHighestCategoryId,
    setCategoryStatusFromObject,
    findLevelFromCategoryStatus
}